import { Box, Grid, Typography } from '@mui/material';
import id from 'date-fns/esm/locale/id/index.js';
import React, { useContext } from 'react';
import PieChart, { PieChartData } from '../../../components/chart/PieChart';
import LoadingCircular from '../../../components/LoadingCircular';
import font from '../../../config/Fonts';
import { LIST_COLOR_CHART } from '../../../constants';
import { LanguageContext } from '../../../contexts/useLanguage';
import { formatNumber } from '../../../helpers/FormatCurrency';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { RepeatVisit } from '../../../models/RepeatVisits';
import { ReportType } from '../../../models/ReportType';
import { GridBorderRadius } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import ColorBox from './ColorBox';
import { FilterContext } from '../../../contexts/FilterContext';

type Props = {
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    repeatVisits?: RepeatVisit[];
};

export default function RepeatVisits(props: Props) {
    const { t } = useContext(LanguageContext);
    const { isExtraLargeXL, isMobileSM, isSMOnly } = useMedia();
    const { loadingRepeatVisit } = useContext(FilterContext);

    const { chartShowType: type } = props;
    const listData = () => {
        let list: PieChartData[] = [];
        if (props.repeatVisits) {
            props.repeatVisits.map((item) => {
                list.push({
                    label:
                        item.visitTime == 7
                            ? `> 6 ${t('dashboard:text.times')}`
                            : item.visitTime == 1
                            ? item.visitTime + ` ${t('dashboard:text.time')}`
                            : item.visitTime + ` ${t('dashboard:text.times')}`,
                    value: item.vehicleCount,
                });
            });
            return list;
        }
        return list;
    };
    return props.repeatVisits && props.repeatVisits.length > 0 ? (
        <GridBorderRadius container item md={12} gap={2}>
            <Grid container item xs={12}>
                <ChartTitleContent
                    key={'45'}
                    title={t('dashboard:title.repeatVisits')}
                    desc={t('dashboard:text.numberOfReturnsByTheSameVehicle')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    chartShowType={props.chartShowType || ChartShowType.dashboard}
                    reportType={props.reportType || undefined}
                />
            </Grid>

            {loadingRepeatVisit ? (
                <LoadingCircular minHeight={'250px'} />
            ) : (
                <Grid
                    item
                    xs={12}
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: isMobileSM ? 1 : 2,
                    }}
                >
                    <Grid
                        sx={{ height: '250px' }}
                        item
                        md={type == ChartShowType.report ? 4 : 6}
                        lg={type == ChartShowType.report ? 6 : 12}
                        sm={6}
                        xs={12}
                    >
                        <PieChart data={listData()} />
                    </Grid>

                    <Grid
                        item
                        container
                        md={type == ChartShowType.report ? 6 : 6}
                        lg={type == ChartShowType.report ? 6 : 12}
                        sm={6}
                        xs={12}
                        padding={isMobileSM || isExtraLargeXL ? '16px 0 0 0' : '16px 16px 0 16px'}
                        direction={'row'}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                    >
                        {isSMOnly ? (
                            <>
                                <Grid
                                    item
                                    container
                                    md={5}
                                    sm={12}
                                    xs={6}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    textAlign={'left'}
                                    paddingLeft={6}
                                    gap={1}
                                >
                                    {props.repeatVisits.map((item, index) => {
                                        if (index <= 3) {
                                            return (
                                                <Typography
                                                    key={index}
                                                    variant="body1"
                                                    fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                    component={'div'}
                                                >
                                                    <ColorBox color={LIST_COLOR_CHART[index]} />

                                                    {`${item.visitTime} ${
                                                        index == 0
                                                            ? t('dashboard:text.time')
                                                            : t('dashboard:text.times')
                                                    } (${formatNumber.format(
                                                        item.percentage
                                                    )}%) - ${formatNumber.format(item.vehicleCount)} `}
                                                </Typography>
                                            );
                                        } else {
                                            return (
                                                <Typography
                                                    key={index}
                                                    variant="body1"
                                                    fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                    component={'div'}
                                                >
                                                    <ColorBox color={LIST_COLOR_CHART[index]} />

                                                    {`${index == 6 ? '> 6' : item.visitTime} ${
                                                        index == 0
                                                            ? t('dashboard:text.time')
                                                            : t('dashboard:text.times')
                                                    } (${formatNumber.format(
                                                        item.percentage
                                                    )}%) - ${formatNumber.format(item.vehicleCount)} `}
                                                </Typography>
                                            );
                                        }
                                    })}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid
                                    item
                                    container
                                    md={5}
                                    sm={12}
                                    xs={6}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    textAlign={'left'}
                                    paddingLeft={{ xs: 0, sm: 0, md: 0 }}
                                    gap={1}
                                >
                                    {props.repeatVisits.map((item, index) => {
                                        if (index <= 3) {
                                            return (
                                                <Typography
                                                    key={index}
                                                    variant="body1"
                                                    fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                    component={'div'}
                                                >
                                                    <ColorBox color={LIST_COLOR_CHART[index]} />

                                                    {`${item.visitTime} ${
                                                        index == 0
                                                            ? t('dashboard:text.time')
                                                            : t('dashboard:text.times')
                                                    } (${formatNumber.format(
                                                        item.percentage
                                                    )}%) - ${formatNumber.format(item.vehicleCount)} `}
                                                </Typography>
                                            );
                                        }
                                    })}
                                </Grid>

                                <Grid
                                    item
                                    container
                                    md={5}
                                    sm={12}
                                    xs={6}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    textAlign={'left'}
                                    paddingLeft={{ xs: 0, sm: 0, md: 0 }}
                                    gap={1}
                                >
                                    {props.repeatVisits.map((item, index) => {
                                        if (index > 3) {
                                            return (
                                                <Typography
                                                    key={index}
                                                    variant="body1"
                                                    fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}
                                                    component={'div'}
                                                >
                                                    <ColorBox color={LIST_COLOR_CHART[index]} />

                                                    {`${index == 6 ? '> 6' : item.visitTime} ${
                                                        index == 0
                                                            ? t('dashboard:text.time')
                                                            : t('dashboard:text.times')
                                                    } (${formatNumber.format(
                                                        item.percentage
                                                    )}%) - ${formatNumber.format(item.vehicleCount)} `}
                                                </Typography>
                                            );
                                        }
                                    })}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </GridBorderRadius>
    ) : (
        <LoadingCircular />
    );
}
