import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridCard } from '../../../../components/GridCard';
import color from '../../../../config/Colors';
import IconButton from '@mui/material/IconButton';
import LocationIcon from '../../components/svg/LocationIcon';
import EmployeeIcon from '../../components/svg/EmployeeIcon';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { formatDate } from '../../../../helpers/moment';
import { CompanyWithDetail } from '../../../../models/Company';

type Props = {
    companyWithDetail: CompanyWithDetail;
};

export default function CompanyItem({ companyWithDetail }: Props) {
    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <GridCard item container xs={12}>
            <Stack sx={{ width: '100%' }} alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
                <Box>
                    <Typography
                        sx={{
                            '&:hover': {
                                color: color.priText,
                                textDecorationLine: 'underline',
                                transition: '0.3s',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => navigate(`${companyWithDetail.id}`)}
                        variant="h5"
                    >
                        {companyWithDetail.displayName}
                    </Typography>
                </Box>
                <Box>
                    <Stack
                        sx={{
                            '& .iconLocation ': {
                                fill: color.grey600,
                            },
                            '&  .employeeIcon': {
                                stroke: color.grey600,
                            },
                        }}
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Stack
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                background: color.grey200,
                                borderRadius: '50%',
                                border: '6px solid',
                                borderColor: color.grey100,
                            }}
                        >
                            <Tooltip
                                sx={{
                                    '&:hover': {
                                        '& .iconLocation': {
                                            fill: 'orange',
                                            transition: '0.3s',
                                        },
                                    },
                                }}
                                title={t('companies:text.locations')}
                                placement="top"
                            >
                                <IconButton
                                    onClick={() => navigate(`${companyWithDetail.id}`)}
                                    aria-label="fingerprint"
                                    color="success"
                                >
                                    <LocationIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                background: color.grey200,
                                borderRadius: '50%',
                                border: '6px solid',
                                borderColor: color.grey100,
                            }}
                        >
                            <Tooltip
                                sx={{
                                    '&:hover': {
                                        '& .employeeIcon': {
                                            stroke: color.secondary,
                                            transition: '0.3s',
                                        },
                                    },
                                }}
                                title={t('companies:text.employees')}
                                placement="top"
                            >
                                <IconButton
                                    onClick={() => navigate(`${companyWithDetail.id}/employees`)}
                                    aria-label="fingerprint"
                                    color="success"
                                >
                                    <EmployeeIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
            <Typography color={color.grey600} variant="body1">
                {t('companies:text.createdAt')}: {formatDate.getLocalDate(companyWithDetail.created)}
            </Typography>
            <Stack mt={1} direction={'row'} sx={{ width: '100%' }}>
                <Typography
                    sx={{ borderRight: `1px solid ${color.grey600}`, paddingRight: '5px', marginRight: '5px' }}
                    variant="body1"
                >
                    {t('companies:text.locations')}: {companyWithDetail.totalLocations}
                </Typography>
                <Typography sx={{ paddingRight: '5px', marginRight: '5px' }} variant="body1">
                    {t('companies:text.employees')}: {companyWithDetail.totalEmployees}
                </Typography>
                {/* <Typography variant="h6">
                    {t('companies:text.tenants')} : {companyWithDetail.totalTenants}
                </Typography> */}
            </Stack>
        </GridCard>
    );
}
