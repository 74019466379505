import React, { useContext, useEffect, useState } from 'react';
import { locationController } from '../controllers';
import { FilterLocation, Pagination } from '../models/BaseFilter';
import { LocationWithDetail } from '../models/Location';
import { CompaniesContext } from './useCompanies';

interface State {
    locationsPagination: Pagination<LocationWithDetail>;
    filter: FilterLocation;
    isLoading: boolean;
}

export default function useLocations() {
    const { companyId } = useContext(CompaniesContext);
    const [state, setState] = useState<State>({
        locationsPagination: {} as any,
        filter: {
            page: 1,
            pageSize: 1000,
            filter: {
                companyId: companyId || 0,
                regionId: 0,
            },
            search: {
                fields: ['name', 'address1', 'address2', 'address3', 'postcode'],
                value: '',
            },
        },
        isLoading: true,
    });

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            filter: { ...prev.filter, filter: { ...prev.filter.filter, companyId: companyId! } },
        }));
    }, [companyId]);

    useEffect(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        if (state.filter.filter.companyId && state.filter.filter.companyId != 0) {
            locationController.filter(state.filter).then((res) => {
                setState((prev) => ({ ...prev, locationsPagination: res, isLoading: false }));
            });
        }
    }, [state.filter]);

    return { ...state, setState };
}

export const LocationsContext = React.createContext<ReturnType<typeof useLocations>>({} as any);
