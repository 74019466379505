/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { LoadingButton } from '@mui/lab';
import { Button, Container, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import ExportIcon from '../../assets/images/ExportIcon';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import Footer from '../dashboardLayout/components/Footer';
import MyTab from './components/MyTab';
import useReport, { ExportType, ReportContext } from './hooks/useReport';

export default function ReportPage() {
    const reportContext = useReport();
    const { FilterComponents, onSelectReportType, selectReport, open, handleClick, anchorEl, handleClose, state } =
        reportContext;

    const { t } = useContext(LanguageContext);

    return (
        <ReportContext.Provider value={reportContext}>
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                <Stack flexDirection={{ sm: 'column', md: 'row' }}>
                    <Grid item mr={'auto'}>
                        <Typography
                            textAlign={'left'}
                            variant="h3"
                            mr={{ xs: 0, sm: 1, md: 1, lg: 1, xl: 1 }}
                            fontWeight={400}
                        >
                            {t('reportPage:title.insights')}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        mt={{ xs: 2, sm: 1, md: 0, lg: 0, xl: 0 }}
                    >
                        <Grid item>{selectReport.RenderSelect}</Grid>
                        <Grid item>
                            <LoadingButton
                                variant="contained"
                                loading={state.isExportLoading}
                                loadingPosition="start"
                                startIcon={<></>}
                                endIcon={<ExportIcon />}
                                sx={{
                                    px: 4,
                                    padding: '9.5px 20px',
                                    minWidth: '160px',
                                    '& .svg-icon-fill': {
                                        fill: color.containedButtonString,
                                    },
                                    '&:hover': {
                                        '& .svg-icon-fill': {
                                            fill: color.white,
                                            transition: '0.2s',
                                        },
                                    },
                                }}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                {t('reportPage:button.export')}
                            </LoadingButton>

                            {/* <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                sx={{ minWidth: '150px' }}
                                type="submit"
                            >
                                {t('action:save')}
                            </LoadingButton> */}
                        </Grid>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{
                                width: '100%',
                                '& .MuiMenuItem-root': {
                                    lineHeight: '1.5rem',
                                    minHeight: '0',
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => onSelectReportType(ExportType.excel)} sx={{ minWidth: '160px' }}>
                                <Typography variant="body1" margin={'auto'}>
                                    {t('reportPage:text.exportExcel')}
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onSelectReportType(ExportType.pdf)} sx={{ minWidth: '160px' }}>
                                <Typography variant="body1" margin={'auto'}>
                                    {t('reportPage:text.exportPDF')}
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Stack>

                <Grid item xs={12} mt={2}>
                    {/* <DashboardFilter /> */}
                    {FilterComponents}
                </Grid>

                <Grid item xs={12} mt={2}>
                    <MyTab />
                </Grid>
            </Container>
            <Footer />
        </ReportContext.Provider>
    );
}
