import React, { useContext, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import England from '../assets/images/flag/England';
import Germany from '../assets/images/flag/Germany';
import { Stack, Typography } from '@mui/material';
import { Locales } from '../translations/config';
import { LanguageContext } from '../contexts/useLanguage';
import color from '../config/Colors';
import { appConfig } from '../config/AppConfig';

type Props = {
    isShowLabel: boolean;
    selectSx?: SelectProps;
};

export default function LanguageSelect({ isShowLabel, selectSx }: Props) {
    const languageContext = useContext(LanguageContext);
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        languageContext.onChangeLanguage(event.target.value as string);
    };
    const listLocales: Locales[] =
        appConfig.flagFeatures.isEnableMultiLanguage == true
            ? [
                  {
                      id: 1,
                      value: 'en',
                      flag: <England width={33} height={24} />,
                      name: languageContext.t('language:English'),
                  },
                  {
                      id: 2,
                      value: 'ge',
                      flag: <Germany width={33} height={24} />,
                      name: languageContext.t('language:German'),
                  },
              ]
            : [
                  {
                      id: 1,
                      value: 'en',
                      flag: <England width={33} height={24} />,
                      name: languageContext.t('language:English'),
                  },
              ];

    return (
        <div>
            <FormControl variant="standard" sx={{ m: 1, min: isShowLabel ? 120 : 45 }}>
                <Select
                    {...selectSx}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={languageContext.default}
                    onChange={handleChange}
                    MenuProps={{
                        style: { zIndex: 999999 },
                        PaperProps: {
                            sx: {
                                boxShadow: 'none',
                                border: 1,
                                borderStyle: 'solid',
                                borderColor: color.grey300,
                                mt: isShowLabel ? '5px' : '15px',
                            },
                        },
                        MenuListProps: {
                            sx: {
                                padding: '5px',
                                '& .MuiMenuItem-root': {
                                    fontWeight: 300,
                                    minHeight: 0,

                                    borderRadius: '5px',
                                    '&:hover': {
                                        backgroundColor: color.grey100,
                                    },
                                    '&.Mui-selected': {
                                        fontWeight: 500,
                                        color: color.darkPrimary,
                                        backgroundColor: color.lightPrimary,
                                    },

                                    // [breakpoints.down('sm')]: {
                                    //     padding:0
                                    // },
                                },
                            },
                        },
                    }}
                    sx={{
                        border: 'none',
                        textAlign: 'center',
                        '&.MuiInput-underline:before': {
                            border: 'none',
                        },
                        '&.MuiInputBase-root:hover:before': {
                            border: 'none',
                        },
                        '&.MuiInputBase-root:after': {
                            border: 'none',
                        },
                        '&.MuiSelect-root:after': {
                            border: 'none',
                        },
                        '& .MuiSelect-select': {
                            padding: '8px 5px 8px 5px',

                            '&:focus': {
                                background: 'none',
                            },
                        },
                        // '& .MuiPopover-paper': {
                        //     mt: '10px',
                        // },
                    }}
                >
                    {listLocales.map((item) => {
                        return (
                            <MenuItem value={item.value} key={item.id}>
                                <Stack flexDirection={'row'}>
                                    {item.flag}
                                    <Typography variant="body1" component={'span'} sx={{ mx: 1 }} minWidth={70}>
                                        {item.name}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
