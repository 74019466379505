import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import color from '../../config/Colors';

export const GridWithGray100 = styled(Grid)({
    background: color.grey100,
    padding: "16px"
});

export const GridBorderRadius = styled(Grid)({
    background: color.white,
    padding: "16px",
    borderRadius: "8px",
    border: `1px solid ${color.grey200} !important`
});

export const TypographyCenter = styled(Typography)({
    textAlign: "center"
});

export const TypographyRight = styled(Typography)({
    textAlign: "right"
});