import React, { useContext, useEffect, useState } from 'react';
import { companiesController, employeeController } from '../controllers';
import { FilterBasic, FilterEmployee, FilterLocation, FilterRequest, Pagination } from '../models/BaseFilter';
import { Employee } from '../models/Employee';
import { CompaniesContext } from './useCompanies';

interface State {
    employeePagination: Pagination<Employee>;
    filter: FilterEmployee;
    isLoading: boolean;
    setRefreshData: number;
}

export default function useEmployee() {
    const { companyId } = useContext(CompaniesContext);
    const [state, setState] = useState<State>({
        employeePagination: {} as any,
        filter: {
            page: 1,
            pageSize: 6,
            filter: {
                companyId: companyId || 0,
            },
            search: {
                fields: [''],
                value: '',
            },
        },
        isLoading: true,
        setRefreshData: 0,
    });

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            filter: { ...prev.filter, filter: { ...prev.filter.filter, companyId: companyId! } },
        }));
    }, [companyId]);

    useEffect(() => {
        if (state.setRefreshData != 0) {
            setState((prev) => ({ ...prev, isLoading: true }));
            if (state.filter.filter.companyId && state.filter.filter.companyId != 0) {
                employeeController.filter(state.filter).then((res) => {
                    setState((prev) => ({ ...prev, employeePagination: res, isLoading: false, setRefreshData: 0 }));
                });
            }
        }
    }, [state.setRefreshData]);

    useEffect(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        if (state.filter.filter.companyId && state.filter.filter.companyId != 0) {
            employeeController.filter(state.filter).then((res) => {
                setState((prev) => ({ ...prev, employeePagination: res, isLoading: false }));
            });
        }
    }, [state.filter]);

    return { ...state, setState };
}

export const EmployeeContext = React.createContext<ReturnType<typeof useEmployee>>({} as any);
