import { LoadingButton } from '@mui/lab';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import MyModal from '../../../components/modal/MyModal';
import { LanguageContext } from '../../../contexts/useLanguage';
import { RawUploadPermitStatus } from '../../../models/permits/ExcelRawPermit';
import Footer from '../../dashboardLayout/components/Footer';
import UpsertPermitFilter from '../addEditPermit/components/UpsertPermitFilter';
import useUpsertPermit, { UpsertPermitContext } from '../addEditPermit/hooks/useUpsertPermit';
import useUploadPermit from './hooks/useUploadPermits';

export default function UploadPermit() {
    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();
    const { getStepComponent, handleNextStep, progressPercent, upsertPermitData, step, isLoading, listVRNChecking } =
        useUploadPermit();
    const validCount = listVRNChecking.reduce(
        (total, item) => (item.status == RawUploadPermitStatus.Valid ? (total += 1) : total),
        0
    );

    return (
        <UpsertPermitContext.Provider value={upsertPermitData}>
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                <Typography variant="h3" fontWeight={400}>
                    {t('permits:title.uploadPermits')}
                </Typography>
                <Stack my={2}>
                    <MyBreadcrumbs />
                </Stack>
                <Grid container>
                    <Grid item xs={12} mt={2}>
                        <UpsertPermitFilter />
                    </Grid>
                </Grid>
                {getStepComponent()}
                <Grid>
                    <Grid item container flexDirection={'row'} justifyContent="space-between" mt={2}>
                        <Button
                            variant="cancel"
                            sx={{ minWidth: '150px' }}
                            onClick={() => {
                                navigate(`/permits`);
                            }}
                        >
                            {t('action:cancel')}
                        </Button>
                        <LoadingButton
                            loading={isLoading}
                            startIcon={<></>}
                            loadingPosition="start"
                            variant="contained"
                            disabled={step === 1 ? progressPercent !== 100 : validCount === 0}
                            sx={{ minWidth: '150px' }}
                            type="submit"
                            onClick={handleNextStep}
                        >
                            {step === 2 ? `${t('action:save')} (${validCount})` : t('action:next')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </UpsertPermitContext.Provider>
    );
}
