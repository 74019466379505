import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LanguageContext } from '../contexts/useLanguage';

type Props = {
    label: string;
    value: any;
};

export default function AutoCompletedDisable(props: Props) {
    const { t } = React.useContext(LanguageContext);

    const options = [
        t('permits:input.selectCompany'),
        t('permits:input.selectLocation'),
        t('permits:input.selectTenant'),
        t('permits:input.SelectZone'),
    ];

    return (
        <Autocomplete
            disabled
            value={props.value}
            id="combo-box-demo"
            options={options}
            fullWidth
            renderInput={(params) => <TextField {...params} label={props.label} />}
        />
    );
}
