import { BaseHttpController } from 'ale-base-model'
import { AxiosInstance } from 'axios';
import { SelectItem } from '../components/MySelect';
import { FilterTenant, TenantsPagination } from '../models/BaseFilter';
import { GetPermitFilterInfo } from '../models/permits/GetPermitFilterInfo';
import { TenantWithAllocate } from '../models/tenant/Tenant';

export class ShopController extends BaseHttpController<TenantWithAllocate> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Shop', client);
    }

    async filter(filter: FilterTenant): Promise<TenantsPagination> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} })
        return res.data
    }

    async delete(id: string) {
        const res = await this.client.delete(`${this.serviceURL}/${this.basePath}/${id}`)
        return res.data
    }

    async getAllocatedZones() {
        const res = await this.client.get(`${this.serviceURL}/${this.basePath}/getAllocatedZones`)
        return res.data
    }
}

