import { Button, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { DrawerContext } from '../../dashboardLayout/useDrawer';
import { DEFAULT_TAB_INDEX } from '../../reports/hooks/useReport';
import { GridBorderRadius } from '../styled';

type Props = {
    title: string;
    desc: string;
    buttonLabel: string;
    onCLick?: () => void;
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    actionTextInfo?: string;
};

export default function ChartTitleContent(props: Props) {
    const { isMobileSM } = useMedia();
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);
    const navigate = useNavigate();
    const handleToReport = () => {
        if (props.reportType) {
            navigate(`/reports/${props.reportType}/${DEFAULT_TAB_INDEX}`);
            setActiveItem(3);
        }
    };
    return (
        <Grid container item xs={12}>
            <Grid item xs={8}>
                <Typography variant="h5">{props.title}</Typography>
                <Typography color={color.priText} variant="body2">
                    {props.desc}
                </Typography>
                <Typography sx={{ mt: 0.5 }} color={color.textPrimary} variant="body2">
                    {props.actionTextInfo}
                </Typography>
            </Grid>
            <Grid item xs={4} textAlign={'right'}>
                {props.chartShowType == ChartShowType.report ? (
                    ''
                ) : (
                    <Button
                        variant="text"
                        size="small"
                        sx={{ fontSize: { xs: '12px', md: '14px' } }}
                        onClick={handleToReport}
                    >
                        {isMobileSM ? t('dashboard:button.insight') : props.buttonLabel}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}
