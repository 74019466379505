/* eslint-disable no-useless-concat */
import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import color from '../../../../../../config/Colors';
import { capitalizeFirstLetter } from '../../../../../../helpers/UppercaseFirstLetter';
import { Permit } from '../../../../../../models/permits/Permit';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';

type Props = {
    permit: Permit;
};
export default function UpsertPermitItemTitleEdit(props: Props) {
    const { t, clientInfo, permits } = useContext(UpsertPermitContext);

    const getStringTimeAndDate = (date: Date) => {
        return { time: moment(date).format('hh:mm'), date: moment(date).format('DD/MM/YY') };
    };

    return (
        <Grid
            container
            display={'flex'}
            padding={'15px 15px'}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={'space-between'}
        >
            <Grid item>
                <Typography variant="body1" fontWeight={500} color={color.success}>
                    {t('permits:title.permitsInformation')}
                </Typography>
            </Grid>
            {clientInfo && permits.length > 0 ? (
                <Grid item>
                    {' '}
                    <Typography component="div">
                        <Typography color={color.grey600} fontWeight={500} component="span">
                            {t('permits:text.CreatedBy')}
                            {` `}
                        </Typography>
                        <Typography color={color.grey600} fontWeight={700} component="span">
                            {`${
                                capitalizeFirstLetter(clientInfo.firstName!) +
                                ' ' +
                                capitalizeFirstLetter(clientInfo.lastName!)
                            }`}
                            {` `}
                        </Typography>
                        <Typography color={color.grey600} fontWeight={500} component="span">
                            {t('permits:text.atOn', { time: `${getStringTimeAndDate(permits[0].created!).time}` })}
                            {' ' + `${getStringTimeAndDate(permits[0].created!).date}`}
                        </Typography>
                    </Typography>
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
}
