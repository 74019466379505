import { Box, Container, Typography, TextField, Grid, Button } from '@mui/material';
import React, { useContext } from 'react';
import MyBreadcrumbs from '../../../../components/BreadCrumbs';
import { LanguageContext } from '../../../../contexts/useLanguage';
import useFormEmp from './useFormEmp';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import LabelWithRequired from '../../../../components/LabelWithRequired';
import { CompaniesContext } from '../../../../hooks/useCompanies';

export default function FormEmployee() {
    const { t } = useContext(LanguageContext);
    const { companyId } = useContext(CompaniesContext);
    const { state, RenderDialog, setEmp, onAddEmp, emp, control, errors, zoneSelects, isActiveButton, isLoading } =
        useFormEmp();

    const navigate = useNavigate();
    return (
        <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, mb: 3 }}>
            {RenderDialog}
            <Box>
                <Typography variant="h3" fontWeight={400}>
                    {state.isEdit ? t('companies:title.editEmployee') : t('companies:title.addEmployee')}
                </Typography>{' '}
            </Box>
            <Box mt={2}>
                <MyBreadcrumbs />
            </Box>
            <Box mb={3} mt={3}>
                <Typography variant="h4">{t('companies:title.personalInformation')}</Typography>{' '}
            </Box>
            <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                item
                lg={12}
                xs={12}
                md={12}
                rowSpacing={3}
                sx={{
                    '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        top: '35px',
                        left: 0,
                        fontSize: '12px',
                    },
                }}
                width={'100%'}
            >
                <Grid item md={5.95} xs={12} lg={5.95}>
                    <Controller
                        control={control}
                        name={'firstName'}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="errorText"
                                error={!!errors.firstName?.message}
                                label={<LabelWithRequired label={t('companies:text.firstName')} />}
                                placeholder={t('input:enterFirstName')}
                                autoComplete={'new-first-name'}
                                helperText={errors.firstName?.message as any}
                                value={emp.firstName}
                                onChange={(e) => {
                                    setEmp({
                                        ...emp,
                                        firstName: e.target.value,
                                    });
                                    field.onChange(e);
                                }}
                                inputProps={{
                                    maxLength: 200,
                                }}
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={5.95} lg={5.95}>
                    <Controller
                        control={control}
                        name={'lastName'}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="errorText"
                                error={!!errors.lastName?.message}
                                label={<LabelWithRequired label={t('companies:text.lastName')} />}
                                placeholder={t('input:enterLastName')}
                                autoComplete={'new-last-name'}
                                helperText={errors.lastName?.message as any}
                                value={emp.lastName}
                                onChange={(e) => {
                                    setEmp({
                                        ...emp,
                                        lastName: e.target.value,
                                    });
                                    field.onChange(e);
                                }}
                                inputProps={{
                                    maxLength: 200,
                                }}
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
                {/* email */}
                <Grid item xs={12} md={5.95} lg={5.95}>
                    <Controller
                        control={control}
                        name={'email'}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="errorText"
                                error={!!errors.email?.message}
                                label={<LabelWithRequired label={t('companies:text.email')} />}
                                placeholder={t('input:enterEmail')}
                                autoComplete={'new-email'}
                                helperText={errors.email?.message as any}
                                disabled={state.isEdit ? true : false}
                                value={emp.email}
                                onChange={(e) => {
                                    setEmp({
                                        ...emp,
                                        email: e.target.value,
                                    });
                                    field.onChange(e);
                                }}
                                inputProps={{
                                    maxLength: 320,
                                }}
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
                {/* phone */}

                <Grid item xs={12} md={5.95} lg={5.95}>
                    <TextField
                        className="errorText"
                        label={t('accountPage:text.phoneNumber')}
                        placeholder={t('input:enterPhoneNumber')}
                        value={emp.phoneNumber}
                        type={'number'}
                        autoComplete={'new-phone'}
                        onChange={(e) => {
                            setEmp({
                                ...emp,
                                phoneNumber: e.target.value,
                            });
                        }}
                        inputProps={{
                            maxLength: 11,
                        }}
                        // onKeyDown={checkPhoneNumber}
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
            <Box mb={3} mt={3}>
                <Typography variant="h4">{t('companies:title.allocatedLocations')}</Typography>{' '}
            </Box>
            <Grid item xs={12}>
                {zoneSelects.RenderSelect}
            </Grid>
            <Grid item mt={3} justifyContent={'space-between'} alignItems={'center'} container xs={12} lg={12}>
                <Grid item lg={2} xs={3}>
                    <Button
                        sx={{ minWidth: { lg: '200px', md: '180px', sm: '150px' } }}
                        variant="cancel"
                        onClick={() => navigate(`/companies/${companyId}/employees`)}
                    >
                        {t('action:cancel')}
                    </Button>
                </Grid>
                <Grid direction={'row'} justifyContent={'right'} item container lg={4} xs={5.95}>
                    {/* <Grid item xs={6} lg={6}>
                        {state.isEdit ? (
                            <Button fullWidth variant="errorOutlined" onClick={deleteEmp}>
                                {t('action:delete')}
                            </Button>
                        ) : (
                            <Box></Box>
                        )}
                    </Grid> */}

                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        disabled={isActiveButton}
                        onClick={onAddEmp}
                        startIcon={<></>}
                        variant="contained"
                        sx={{ minWidth: { lg: '200px', md: '180px', sm: '150px' } }}
                        type="submit"
                    >
                        {t('action:save')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Container>
    );
}
