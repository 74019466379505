import * as React from 'react';
import { Autocomplete, Box, Checkbox, TextField, Typography } from '@mui/material';
import UncheckedIcon from '../assets/images/UncheckedIcon';
import CheckedIcon from '../assets/images/CheckedIcon';
import color from '../config/Colors';
import { LanguageContext } from '../contexts/useLanguage';

const icon = <UncheckedIcon fontSize="small" />;
const checkedIcon = <CheckedIcon fontSize="small" />;

type Props = {
    listData: any[];
    label: string | JSX.Element;
    value: any[];
    setFilter: (option: any[]) => void;
    isDisable?: boolean;
};

export default function useMultiSelectWithCheckBox(props: Props) {
    const { t } = React.useContext(LanguageContext);
    const handleInput = (e: any, value: any[]) => {
        let listId: any[] = [];
        value.map((item) => {
            listId.push(item.value);
        });
        props.setFilter(listId);
    };

    const RenderSelect = (
        <Box
            sx={{
                '& ul': {
                    padding: '5px !important',
                    borderBottom: `1px solid ${color.grey100}`,
                },
                '& svg': {
                    width: '15px !important',
                    height: '15px !important',
                },
                '& .MuiButtonBase-root': {
                    backgroundColor: color.grey200,
                    maxWidth: '120px !important',
                },
            }}
        >
            <Autocomplete
                multiple
                id="checkboxes-tags-demo1"
                options={props.listData}
                sx={{
                    '& .MuiAutocomplete-input': {
                        padding: '6.5px 4px 6.5px 6px !important',
                    },
                    '& .MuiOutlinedInput-root': {
                        padding: '2px 10px !important',
                    },
                    '& .MuiOutlinedInput-root > span.MuiAutocomplete-tagSizeSmall': {
                        px: '5px',
                        background: color.grey200,
                        borderRadius: '5px',
                        fontSize: '12px',
                    },
                }}
                disabled={props.isDisable || false}
                componentsProps={{
                    popper: {
                        sx: {
                            zIndex: 99999,
                            '& .MuiAutocomplete-listbox': {
                                padding: '5px',
                            },
                            '& li': {
                                minHeight: 'auto',
                            },
                        },
                    },
                }}
                limitTags={2}
                disableCloseOnSelect
                noOptionsText={t('dashboard:input.noOptions')}
                // open
                size="small"
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => handleInput(event, value)}
                value={props.value || null}
                renderOption={(props1, option, { selected }) => {
                    return (
                        <li
                            {...props1}
                            style={{
                                borderRadius: '4px',
                                marginBottom: '2px',
                                paddingRight: '10px',
                            }}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, padding: 0 }}
                                checked={selected}
                            />
                            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {option.label}
                            </Typography>
                        </li>
                    );
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            key={1}
                            label={props.label}
                            placeholder={t('dashboard:input.selectMore')}
                            sx={{}}
                        />
                    );
                }}
            />
        </Box>
    );

    return { RenderSelect };
}
