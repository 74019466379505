/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { shopController } from '../controllers';
import { FilterTenant, TenantsPagination } from '../models/BaseFilter';
import { CompaniesContext } from './useCompanies';

interface StateTenant {
    tenantPagination: TenantsPagination;
    filter: FilterTenant;
    isLoadingDataTenant: boolean;
    isRefreshDataTenant: boolean;
}

export default function useTenants() {
    const { idLocationSelect } = useContext(CompaniesContext);
    const [stateTenant, setStateTenant] = useState<StateTenant>({
        tenantPagination: {} as any,
        filter: {
            page: 1,
            pageSize: 6,
            search: {
                fields: [''],
                value: '',
            },
            filter: {
                locationId: idLocationSelect,
            },
        },
        isLoadingDataTenant: true,
        isRefreshDataTenant: false,
    });

    useEffect(() => {
        if (idLocationSelect !== 0) {
            setStateTenant((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter, locationId: idLocationSelect } },
            }));
        }
    }, [idLocationSelect]);

    useEffect(() => {
        if (stateTenant.filter.filter.locationId !== 0) {
            setStateTenant((prev) => ({ ...prev, isLoadingDataTenant: true }));
            shopController
                .filter(stateTenant.filter)
                .then((res) => {
                    setStateTenant((prev) => ({
                        ...prev,
                        tenantPagination: res,
                        isLoadingDataTenant: false,
                        isRefreshDataTenant: false,
                    }));
                })
                .catch((res) => {
                    setStateTenant((prev) => ({
                        ...prev,
                        isLoadingDataTenant: false,
                    }));
                });
        }
    }, [stateTenant.filter]);

    useEffect(() => {
        if (stateTenant.isRefreshDataTenant === true) {
            shopController
                .filter(stateTenant.filter)
                .then((res) => {
                    setStateTenant((prev) => ({
                        ...prev,
                        tenantPagination: res,
                        isLoadingDataTenant: false,
                        isRefreshDataTenant: false,
                    }));
                })
                .catch((res) => {
                    setStateTenant((prev) => ({
                        ...prev,
                        isLoadingDataTenant: false,
                    }));
                });
        }
    }, [stateTenant.isRefreshDataTenant]);

    const refreshDataTenant = () => {
        setStateTenant((prev) => ({ ...prev, isRefreshDataTenant: true }));
    };

    return { ...stateTenant, setStateTenant, refreshDataTenant };
}

export const TenantContext = React.createContext<ReturnType<typeof useTenants>>({} as any);
