/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Typography, TextField, Grid, Checkbox } from '@mui/material';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import Zone from '../../../../models/Zone';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { formatNumber } from '../../../../helpers/FormatCurrency';
import { TenantWithAllocate, AllocatedPermit } from '../../../../models/tenant/Tenant';
import { CompaniesContext } from '../../../../hooks/useCompanies';
import CheckBoxIcon from '../../../../assets/images/CheckBoxIcon';
import CheckedIcon from '../../../../assets/images/CheckedIcon';
import { ItemChecked, ZoneValid } from './Tab/ModalAddTenant';
import _ from 'lodash';
import { LocationsContext } from '../../../../hooks/useLocations';
import { TenantContext } from '../../../../hooks/useTenants';

type Props = {
    zone: Zone;
    handleChange: (zoneWithMaxPermits: AllocatedPermit) => void;
    valids: ZoneValid[];
    zoneAndPermit: AllocatedPermit;
    tenant: TenantWithAllocate;
    isEdit: boolean;
    index: number;
    setValids: React.Dispatch<React.SetStateAction<ZoneValid[]>>;
    totalAllocated: number;
    checks: ItemChecked[];
    setChecks: React.Dispatch<React.SetStateAction<ItemChecked[]>>;
};

export default function ZoneItemModal(props: Props) {
    const { locationSelect, zonesSelect } = useContext(CompaniesContext);
    const { tenantPagination } = useContext(TenantContext);
    const remaining = props.zone.maxPermits - props.zone.allocated;
    //get lasted allocated for calculating max permits
    const getLastTenantZoneInfo = () => {
        const list = tenantPagination.data;
        const tenantIndex = list.findIndex((item) => item.clientId == props.tenant.clientId);
        let info = {
            remaining: remaining,
            maxZonePermit: props.zone.maxPermits,
            used: 0,
            allocatedAmount: 0,
        };

        if (tenantIndex >= 0) {
            const currentZone = list[tenantIndex].allocatedPermits?.find((tenant) => tenant.zone?.id == props.zone.id);
            if (currentZone) {
                info = {
                    remaining: remaining + currentZone.allocatedAmount,
                    maxZonePermit: props.zone.maxPermits,
                    used: currentZone.usedPermit!,
                    allocatedAmount: currentZone.allocatedAmount,
                };
            }
        }
        return info;
    };

    const getUsed = () => {
        if (getLastTenantZoneInfo().used == 0) {
            return 1;
        } else {
            return getLastTenantZoneInfo().used;
        }
    };

    //get default checked

    const getChecked = () => {
        //if edit and allocated=0 disable textfield
        if (getLastTenantZoneInfo().maxZonePermit === 0 || getLastTenantZoneInfo().remaining === 0) {
            return false;
        }
        if (props.isEdit === true) {
            if (props.zoneAndPermit.allocatedAmount === 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };
    const { t } = useContext(LanguageContext);
    // const [checked, setChecked] = useState<boolean>(() => true);

    const schema = Yup.object({
        isChecked: Yup.boolean(),
        allocated: Yup.number().when('isChecked', {
            is: true,
            then: Yup.number()
                .integer()
                .typeError(t(`validation:thisFieldIsRequired`))
                .required(t('validation:thisFieldIsRequired'))
                .min(getUsed(), t(`companies:tenants.maxPermitsCanNotBeLessThan`, { number: getUsed() }))
                .max(
                    getLastTenantZoneInfo().remaining,
                    t(`validation:thisNumberMustBeLessThan`, {
                        number: formatNumber.format(getLastTenantZoneInfo().remaining),
                    })
                ),
        }),
    });
    const removeNumber0Leading = (number: Number) => parseInt(String(number).replace(/^[0]+/g, ''));

    const {
        control,
        formState: { errors, isDirty, isValid },
        setValue,
        getValues,
        getFieldState,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            isChecked: getChecked(),
            allocated: removeNumber0Leading(props.zoneAndPermit?.allocatedAmount),
        },
        resolver: yupResolver(schema),
    });

    // console.log(getValues());

    useEffect(() => {
        if (props.isEdit) {
            setValue('isChecked', getChecked(), { shouldValidate: true });
        }
    }, [props.zoneAndPermit.allocatedAmount, props.isEdit]);

    const handleChange = (allocated: number) => {
        const zoneWithMaxPermits: AllocatedPermit = {
            zoneId: props.zone.id,
            allocatedAmount: allocated,
            shopClientId: props.tenant.clientId,
            shopLocationId: locationSelect?.id,
        };
        props.handleChange(zoneWithMaxPermits);
    };

    useEffect(() => {
        const listValid = _.cloneDeep(props.valids);
        const index = listValid.findIndex((item) => item.id === props.zone.id);
        if (index >= 0) {
            listValid[index] = { ...listValid[index], isValid: isValid };
            props.setValids(listValid);
        }
    }, [isValid]);

    const countItemPermit = () => {
        let count = 0;
        props.checks.map((item) => {
            if (item.checked == true) {
                count += 1;
            }
        });
        return count;
    };

    const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === false) {
            if (props.isEdit) {
                if (getLastTenantZoneInfo().used === 0 && countItemPermit() > 1) {
                    handleSetZero();
                }
            } else {
                if (countItemPermit() > 1) {
                    handleSetZero();
                }
            }
        } else {
            setValue('isChecked', true, { shouldValidate: true });
            setValue('allocated', removeNumber0Leading(0), {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
            });
            handleChange(0);
        }
    };

    const handleSetZero = () => {
        setValue('isChecked', false, { shouldValidate: true });
        setValue('allocated', 0, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
        handleChange(0);
    };

    useEffect(() => {
        const listChecked = _.cloneDeep(props.checks);
        const index = listChecked.findIndex((item) => item.id === props.zone.id);
        if (index >= 0) {
            listChecked[index] = { ...listChecked[index], checked: getValues('isChecked') };
            props.setChecks(listChecked);
        }
    }, [getValues('isChecked')]);

    useEffect(() => {
        if (getLastTenantZoneInfo().remaining <= 0) {
            const listValid = _.cloneDeep(props.valids);
            const index = listValid.findIndex((item) => item.id === props.zone.id);
            if (index >= 0) {
                listValid[index] = { ...listValid[index], isValid: true };
                props.setValids(listValid);
            }
        }
    }, []);

    // console.log(props.zone.id + ': ', getValues('isChecked'));

    return (
        <Grid
            item
            container
            display={'flex'}
            gap={{ xs: 2, sm: 0 }}
            flexDirection={'row'}
            justifyContent={'space-between'}
            mt={{ xs: 2, sm: 2 }}
        >
            <Grid item container xs={12} sm={5.9} display="flex" flexDirection={'row'} wrap={'nowrap'}>
                <Grid item display="flex" flexDirection={'column'} justifyContent="center">
                    <Checkbox
                        size="small"
                        icon={<CheckBoxIcon style={{ width: '18px', height: '18px' }} />}
                        checkedIcon={<CheckedIcon style={{ width: '18px', height: '18px' }} />}
                        disabled={getLastTenantZoneInfo().maxZonePermit == 0 || getLastTenantZoneInfo().remaining == 0}
                        checked={getValues('isChecked')}
                        onChange={handleChangeChecked}
                    />
                </Grid>
                <Grid item sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <Typography variant="body1">{props.zone.name}</Typography>
                    <Typography variant="body2" color={color.success}>
                        {t('companies:tenants.remainingPermits')}: {remaining + getLastTenantZoneInfo().allocatedAmount}
                        /{props.zone.maxPermits}
                    </Typography>
                    {props.isEdit ? (
                        <Typography variant="body2" color={color.secondary}>
                            {t('action:used')}: {getLastTenantZoneInfo().used}/{getLastTenantZoneInfo().allocatedAmount}
                        </Typography>
                    ) : (
                        ''
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={5.9}>
                <Controller
                    control={control}
                    name={'allocated'}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            disabled={!getValues('isChecked')}
                            error={!!errors.allocated?.message}
                            helperText={errors.allocated?.message as any}
                            fullWidth
                            type={'number'}
                            // onInput={(e: any) => {
                            //     e.target.value =
                            //         Math.max(0, parseInt(e.target.value) || getUsed())
                            //             .toString()
                            //             .slice(0, 9) || getUsed();
                            // }}
                            InputProps={{ inputProps: { min: 0 } }}
                            label={t('companies:tenants.maxNoOfPermits')}
                            placeholder={t('companies:tenants.enterMaxNoOfPermits')}
                            // defaultValue={1}
                            onChange={(e) => {
                                const val = removeNumber0Leading(Number(e.target.value));
                                if (props.isEdit) {
                                    if (Number(val) > 0) {
                                        handleChange(Number(val));
                                        field.onChange(Number(val));
                                    } else {
                                        handleChange(1);
                                        field.onChange(1);
                                    }
                                } else {
                                    handleChange(Number(val));
                                    field.onChange(Number(val));
                                }
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
