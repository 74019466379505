import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { UpsertPermitItemContext } from '../hooks/useUpsertPermitItem';
import UpsertPermitItemDateRange from './UpsertPermitItemDateRange';
import UpsertPermitItemTimeDuration from './UpsertPermitItemTimeDuration';

export default function UpsertPermitItemRecurTime() {
    const { t, permit, formik } = useContext(UpsertPermitItemContext);
    return (
        <Grid container item direction={'row'} mt={2}>
            <Grid item xs={12}>
                <UpsertPermitItemTimeDuration />
            </Grid>
            <Grid xs={12} item mt={formik.touched?.toHour && !!formik.errors?.toHour ? 1 : 0}>
                <UpsertPermitItemDateRange />
            </Grid>
        </Grid>
    );
}
