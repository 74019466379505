import moment from 'moment';

class FormatDate {
    getLocalDate<T>(date: T) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }
    getLocalHour<T>(date: T) {
        return moment(date).format('HH:mm:ss');
    }
    getLocalDay<T>(date: T) {
        return moment(date).format('DD/MM/YYYY');
    }
    getLocalDay2<T>(date: T) {
        return moment(date).format('DD/MM');
    }
    getLocalDay3<T>(date: T) {
        return moment(date).format('DD');
    }
}

export const formatDate = new FormatDate();
