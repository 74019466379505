import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { UpsertPermitItemContext } from '../UpsertPermitItem/hooks/useUpsertPermitItem';

export default function MonthRecurring() {
    const { t, permit, getRecurEveryNumber, setRecurEveryNumber } = useContext(UpsertPermitItemContext);
    const { language } = useContext(LanguageContext);

    const getIndicator = (num: Number) => {
        return num === 1 || num === 21 || num === 31
            ? 'st'
            : num === 2 || num === 22
            ? 'nd'
            : num === 3 || num === 23
            ? 'rd'
            : 'th';
    };

    const isValidDateOfAllMonth = () => {
        if (getRecurEveryNumber() == 29 || getRecurEveryNumber() == 30 || getRecurEveryNumber() == 31) {
            return false;
        }
        return true;
    };

    return (
        <Stack
            flex={1}
            direction={'column'}
            padding={'16px 8px'}
            sx={{ background: color.grey100, borderRadius: '5px' }}
            alignItems="flex-start"
        >
            <Stack direction={'row'} alignItems="center">
                <Typography variant="body1" component={'span'} mr={2}>
                    {t('permits:text.onThe')}
                </Typography>
                <TextField
                    placeholder={''}
                    sx={{ minWidth: '120px', mr: 2 }}
                    type="number"
                    size="small"
                    inputProps={{ min: 1, max: 31 }}
                    value={permit.permitRecurring[0].recurringEvery}
                    onChange={(e) => {
                        const value = Number(e.target!.value);
                        const val = value < 1 ? 1 : value > 31 ? 31 : value;
                        setRecurEveryNumber(val);
                    }}
                />
                <Typography variant="body1" component={'span'}>
                    {language == 'en' ? getIndicator(getRecurEveryNumber() || 1) : ''} {t('permits:text.ofEveryMonth')}
                </Typography>
            </Stack>

            {isValidDateOfAllMonth() == false && (
                <Box mt={3}>
                    <Typography fontSize={10} color={color.textPrimary}>
                        ({t('permits:text.pleaseNoteThatTheScheduledPermitWillNotRecurForTheMonths')})
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}
