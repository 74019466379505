import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import React, { ChangeEvent, useContext } from 'react';
import { HiUpload } from 'react-icons/hi';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { UpsertFilter, UpsertFilterData } from '../../addEditPermit/hooks/useUpsertPermit';
import LinearWithValueLabel from './LinearProgressWithLabel';

type Props = {
    uploadInputRef: React.MutableRefObject<HTMLInputElement | null>;
    handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
    progressPercent: number;
    fileName: string;
    handleCancelUpload: () => void;
    filter: UpsertFilter;
    file: File | null;
    filterData: UpsertFilterData;
    isLoading: boolean;
};

export default function UploadContent({
    uploadInputRef,
    handleFileUpload,
    progressPercent,
    fileName,
    handleCancelUpload,
    filter,
    file,
    filterData,
    isLoading,
}: Props) {
    const { t } = useContext(LanguageContext);
    return (
        <React.Fragment>
            {progressPercent !== 0 ? (
                <LinearWithValueLabel
                    progressPercent={progressPercent}
                    fileName={fileName}
                    handleCancelUpload={handleCancelUpload}
                />
            ) : (
                <Typography variant="h4" color={color.grey600} textAlign="center">
                    {t('permits:upload.pleaseSelectZoneAndUpload')}
                </Typography>
            )}
            <>
                <input
                    ref={uploadInputRef}
                    type="file"
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
                <LoadingButton
                    variant="contained"
                    loading={false}
                    loadingPosition="start"
                    startIcon={<></>}
                    sx={{ width: '200px' }}
                    endIcon={<HiUpload style={{ fontSize: '14px' }} />}
                    disabled={
                        (progressPercent !== 0 && progressPercent !== 100) ||
                        filter.idZones.length <= 0 ||
                        isLoading == true
                    }
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                >
                    {progressPercent === 100 ? t('permits:upload.replaceFile') : t('permits:upload.uploadFile')}
                </LoadingButton>
            </>
        </React.Fragment>
    );
}
