import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { SelectItem } from '../components/MySelect';
import { Account } from '../models/Account';
import { FilterRequest, Pagination } from '../models/BaseFilter';
import { Company, CompanyWithDetail } from '../models/Company';
import { EditPermit } from '../models/tenant/EditPermit';
import { Filter } from '../models/Filter';

export class CompaniesController extends BaseHttpController<Account> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Company', client);
    }

    async getMyCompany(): Promise<SelectItem[]> {
        const res = await this.doGet({ path: '', config: {} });
        const listData: SelectItem[] = [];
        res.data.map((item: { id: any; displayName: any }) =>
            listData.push({
                value: item.id,
                label: item.displayName,
            })
        );
        return listData;
    }

    async getMyCompanyHaveTenant(): Promise<SelectItem[]> {
        const res = await this.doGet({ path: 'tenant', config: {} });
        const listData: SelectItem[] = [];
        res.data.map((item: { id: any; displayName: any }) =>
            listData.push({
                value: item.id,
                label: item.displayName,
            })
        );
        return listData;
    }

    async GetCompanyDetail(filter: Filter) {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/GetRegionsAndLocations`, filter);
        const listRegion: SelectItem[] = [];
        const listLocation: SelectItem[] = [];

        res.data.regions.map((item: { id: any; name: any }) =>
            listRegion.push({
                value: item.id,
                label: item.name,
            })
        );

        res.data.locations.map((item: { id: any; name: any }) =>
            listLocation.push({
                value: item.id,
                label: item.name,
            })
        );

        return { regions: listRegion, locations: listLocation };
    }

    async GetCompanyDetailHaveTenant(filter: Filter) {
        const res = await this.client.post(`${this.serviceURL}/${this.basePath}/GetRegionsAndLocationTenant`, filter);
        const listRegion: SelectItem[] = [];
        const listLocation: SelectItem[] = [];

        res.data.regions.map((item: { id: any; name: any }) =>
            listRegion.push({
                value: item.id,
                label: item.name,
            })
        );

        res.data.locations.map((item: { id: any; name: any }) =>
            listLocation.push({
                value: item.id,
                label: item.name,
            })
        );

        return { regions: listRegion, locations: listLocation };
    }

    async filter(filter: FilterRequest<Company>): Promise<Pagination<CompanyWithDetail>> {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} });
        return res.data;
    }

    async editPermits(editPermit: EditPermit) {
        const res = await this.client.post(`${this.serviceURL}/editPermits`, editPermit);
        return res.data;
    }

    async getCompaniesInfo(idCompany: number) {
        const res = await this.client.get(`${this.serviceURL}/company/${idCompany}`);
        return res.data;
    }
}
