import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import LoadingCircular from '../../../components/LoadingCircular';
import { CompaniesContext } from '../../../hooks/useCompanies';
import MyLocationTab from './components/MyLocationTabs';
import useLocationDetail, { LocationDetailContext } from './useLocationDetail';
import Footer from '../../dashboardLayout/components/Footer';
import useScrollToTop from '../../../hooks/useScrollToTop';

export enum LocationTab {
    GeneralInformation = 'general-information',
    Zones = 'zones',
    Tenant = 'tenants',
}

export default function LocationLayout() {
    const { getLocationDetail, locationSelect } = useContext(CompaniesContext);
    const locationDetailContext = useLocationDetail();
    const { locationId } = useParams();
    useScrollToTop();
    useEffect(() => {
        if (locationId) {
            getLocationDetail(Number(locationId));
            // navigate(`/`);
        }
    }, [locationId]);

    return (
        <LocationDetailContext.Provider value={locationDetailContext}>
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                {locationSelect ? (
                    <Grid item container xs={12}>
                        <Grid item>
                            <Typography variant="h3" fontWeight={400}>
                                {locationSelect.name}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Box mt={2}>
                                <MyBreadcrumbs />
                            </Box>
                        </Grid>

                        <MyLocationTab />
                    </Grid>
                ) : (
                    <LoadingCircular />
                )}
            </Container>
            <Footer />
        </LocationDetailContext.Provider>
    );
}
