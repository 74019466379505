import { Box, Container, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useContext, useState } from 'react';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import LoadingCircular from '../../../components/LoadingCircular';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { LocationsContext } from '../../../hooks/useLocations';
import { RegionContext } from '../../../hooks/useRegions';
import useScrollToTop from '../../../hooks/useScrollToTop';
import useSelection from '../../../hooks/useSelection';
import Footer from '../../dashboardLayout/components/Footer';
import IconSearch from '../components/svg/IconSearch';
import MapViewIcon from '../components/svg/MapViewIcon';
import MenuIcon from '../components/svg/MenuIcon';
import LocationItem from './components/LocationItem';
import ViewMap from './components/ViewMap';
import _ from 'lodash';

export default function Location() {
    const { t } = useContext(LanguageContext);
    const [view, setView] = useState(0);
    const handleChange = (event: any) => {
        setView(event.target.value as number);
    };
    useScrollToTop();

    const { locationsPagination, setState, isLoading, filter } = useContext(LocationsContext);
    const { regions } = useContext(RegionContext);
    const regionSelect = useSelection({
        list: regions,
        label: 'Region',
        setFilter: (val) =>
            setState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter, regionId: val } },
            })),
    });

    const onChangeSearch = (e: any) => {
        setState((prev) => ({
            ...prev,
            filter: { ...prev.filter, search: { ...prev.filter.search!, value: e.target.value }, page: 1 },
        }));
    };

    return (
        <>
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                <Typography variant="h3" fontWeight={400}>
                    {t('companies:title.locations')}
                </Typography>

                <Box mt={2}>
                    <MyBreadcrumbs />
                </Box>

                <Grid
                    mt={1}
                    item
                    container
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <Grid item xs={5.5} sm={2.5} md={2} order={{ xs: 1, sm: 1 }}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={view}
                                onChange={(e) => handleChange(e)}
                                sx={{
                                    background: color.lightPrimary,
                                    borderRadius: '3px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                        outline: 'none',
                                    },
                                }}
                            >
                                <MenuItem value={0}>
                                    <Grid
                                        container
                                        direction={'row'}
                                        justifyContent={'flex-start'}
                                        alignItems={'center'}
                                        spacing={1}
                                    >
                                        <Grid item xs={3} textAlign={'right'} sx={{ height: '24px' }}>
                                            <MenuIcon />
                                        </Grid>
                                        <Grid item xs={9} color={color.priText}>
                                            {t('companies:title.listView')}
                                        </Grid>
                                    </Grid>
                                </MenuItem>

                                <MenuItem value={1}>
                                    <Grid
                                        container
                                        direction={'row'}
                                        justifyContent={'flex-start'}
                                        alignItems={'center'}
                                        spacing={1}
                                    >
                                        <Grid item xs={3} textAlign={'right'} sx={{ height: '24px' }}>
                                            <MapViewIcon />
                                        </Grid>
                                        <Grid item xs={9} color={color.priText}>
                                            {t('companies:title.mapView')}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6.5} md={7.5} order={{ xs: 3, sm: 2 }}>
                        <TextField
                            fullWidth
                            placeholder={t('companies:input.SearchByNameAddressPostcode')}
                            onChange={_.debounce((value) => onChangeSearch(value), 500)}
                            inputProps={{
                                maxLength: 75,
                            }}
                            defaultValue={filter.search?.value}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <IconSearch />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={6.5} sm={3} md={2.5} order={{ xs: 2, sm: 3 }}>
                        {regionSelect.RenderSelect}
                    </Grid>
                </Grid>

                {view == 0 ? (
                    <Grid item>
                        {isLoading ? (
                            <LoadingCircular />
                        ) : locationsPagination.data && locationsPagination.data.length > 0 ? (
                            // <LoadingCircular />
                            <Grid mt={4} item container gap={'15px'}>
                                {locationsPagination.data &&
                                    locationsPagination.data.length > 0 &&
                                    locationsPagination.data.map((item, index) => {
                                        return <LocationItem key={index} locationWithDetail={item} />;
                                    })}
                            </Grid>
                        ) : (
                            <Grid
                                mt={4}
                                item
                                container
                                alignItems={'center'}
                                justifyContent={'center'}
                                sx={{
                                    background: color.grey100,
                                    borderRadius: '10px',
                                    padding: '10px 0',
                                    height: 200,
                                }}
                            >
                                <Typography fontSize={20} fontWeight={500} color={'#85858A'}>
                                    {t('companies:text.noDataToDisplay')}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid item mt={4} borderRadius={'10px'} overflow={'hidden'}>
                        {locationsPagination.data && locationsPagination.data.length > 0 ? (
                            <ViewMap isShowLocationPopOver={true} locations={locationsPagination.data} />
                        ) : (
                            <Grid
                                mt={0}
                                item
                                container
                                alignItems={'center'}
                                justifyContent={'center'}
                                sx={{
                                    background: color.grey100,
                                    borderRadius: '10px',
                                    padding: '10px 0',
                                    height: 200,
                                }}
                            >
                                <Typography fontSize={20} fontWeight={500} color={'#85858A'}>
                                    {t('companies:text.noDataToDisplay')}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Container>
            <Footer />
        </>
    );
}
