import { Chip, MenuItem, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ChipDeleteIcon from '../../../assets/images/ChipDeleteIcon';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { RecurringType } from '../../../models/permits/PermitRecurring';
import { AdvancedName, TenantSearch } from '../hooks/usePermitsFilter';
import AdvancedFilter from './AdvancedFilter';

type Props = {
    value: RecurringType | null;
    handleRemove: (advancedName: AdvancedName) => void;
    setStatus: (status: RecurringType | null) => void;
};

export default function AdvancedRecurType(props: Props) {
    const { t } = useContext(LanguageContext);
    const [name, setName] = useState<RecurringType | null>(props.value);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onClickSetName = (item: RecurringType | null) => {
        setName(item);
        setAnchorEl(null);
        props.setStatus(item);
    };

    const getName = (recurType: RecurringType | null) => {
        if (recurType == null) {
            return t('permits:text.all');
        } else {
            if (recurType === RecurringType.once) {
                return t('permits:text.oneDayPermit');
            }
            if (recurType === RecurringType.day) {
                return t('permits:text.daily');
            }
            if (recurType === RecurringType.week) {
                return t('permits:text.weekly');
            }
            if (recurType === RecurringType.month) {
                return t('permits:text.monthly');
            }
            if (recurType === RecurringType.indefinite) {
                return t('permits:text.indefinite');
            }
        }
    };

    return (
        <AdvancedFilter
            isShowButton={false}
            openElement={
                <Chip
                    label={t('permits:text.recurringType') + ': ' + getName(name)}
                    variant="outlined"
                    sx={[
                        anchorEl !== null
                            ? {
                                  background: 'black',
                                  '& .svg-icon-stroke': {
                                      stroke: 'white',
                                  },
                                  color: 'white',
                              }
                            : {},
                        { '& svg': { mr: '5px' }, minWidth: '140px' },
                    ]}
                    deleteIcon={<ChipDeleteIcon />}
                    onClick={() => {}}
                    onDelete={() => {
                        props.handleRemove('recurringType');
                    }}
                />
            }
            MenuItems={[
                null,
                RecurringType.once,
                RecurringType.day,
                RecurringType.week,
                RecurringType.month,
                RecurringType.indefinite,
            ].map((item, index) => {
                return <MenuItemTenant handleClick={onClickSetName} name={item} key={index} getName={getName} />;
            })}
            anchorEl={anchorEl}
            setAnchor={setAnchorEl}
        />
    );
}

type PropsChild = {
    name: RecurringType | null;
    handleClick: (item: RecurringType | null) => void;
    getName: (recurType: RecurringType | null) => string | undefined;
};

function MenuItemTenant(props: PropsChild) {
    const { t } = useContext(LanguageContext);
    return (
        <MenuItem
            // disableRipple={true}
            sx={{
                padding: '5px 5px',
                borderBottom: `1px solid ${color.grey200}`,
                borderRadius: '5px',
                '&:hover': {
                    background: color.primary,
                },
                '& .MuiTouchRipple-child': {
                    backgroundColor: color.primary,
                },
                flexDirection: 'column',
            }}
            onClick={() => props.handleClick(props.name)}
        >
            <Typography variant="body1" component={'h6'} width={'100%'} textTransform="capitalize">
                {props.getName(props.name)}
            </Typography>
        </MenuItem>
    );
}
