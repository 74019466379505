import { Navigate, Route, Routes } from 'react-router-dom';
import Notify from './components/ToastMessage';
import UseLanguage, { LanguageContext } from './contexts/useLanguage';
import UseAuth, { AuthContext } from './hooks/useAuth';
import Dashboard from './pages/dashboard';
import ChangePassForm from './pages/login/components/ChangePassForm';
import ForgotForm from './pages/login/components/ForgotForm';
import LoginForm from './pages/login/components/LoginForm';
import LoginLayout from './pages/login/LoginLayout';
import PrivateRouter from './routes/PrivateRouter';
import PublicRouter from './routes/PublicRouter';
import CompanyLayout from './pages/companyLayout/CompanyLayout';
import CompanyPage from './pages/companyLayout/Companies';
import Location from './pages/companyLayout/locations';
import EmployeesPage from './pages/companyLayout/employees';
import LocationDetail from './pages/companyLayout/locationDetail';
import FormEmployee from './pages/companyLayout/employees/components/FormEmployee';
import AccountPage from './pages/account';
import SupportLayout from './pages/supportLayout';
import EmployeeLayout from './pages/companyLayout/employees/EmployeeLayout';
import CompanyDetailLayout from './pages/companyLayout/id';
import LocationLayout from './pages/companyLayout/locations/LocationLayout';
import LoadingCircular from './components/LoadingCircular';
import ReportDashboard from './pages/reports/reportDashboard';
import ReportPage from './pages/reports';
import UpsertPermit from './pages/permitsLayout/addEditPermit';
import ListPermit from './pages/permitsLayout';
import FilterPermitProvider from './pages/permitsLayout/PermitProvider';
import UpLoadPermit from './pages/permitsLayout/uploadPermits';

function App() {
    const authData = UseAuth();
    const language = UseLanguage();
    return (
        <LanguageContext.Provider value={language}>
            <AuthContext.Provider value={authData}>
                <Notify />
                {authData.isLoading ? (
                    <LoadingCircular />
                ) : (
                    <Routes>
                        {authData.isAuth === false ? (
                            <Route element={<PublicRouter />}>
                                <Route path="/" element={<LoginLayout />}>
                                    <Route index element={<LoginForm />} />
                                    <Route path="forgot" element={<ForgotForm />} />
                                    <Route path="change-password/:token" element={<ChangePassForm />} />
                                </Route>
                            </Route>
                        ) : (
                            <Route path="/" element={<PrivateRouter />}>
                                <Route
                                    index
                                    element={
                                        <Navigate to={authData.account?.label == 'Tenant' ? 'permits' : 'dashboard'} />
                                    }
                                />
                                {!authData.isBronzeRole() ? (
                                    <>
                                        <Route path="dashboard" element={<Dashboard />} />
                                        <Route path="companies" element={<CompanyLayout />}>
                                            <Route index element={<CompanyPage />} />
                                            <Route path=":idCompany" element={<CompanyDetailLayout />}>
                                                <Route path="" element={<LocationLayout />}>
                                                    <Route index element={<Location />} />
                                                    <Route
                                                        path="locations/:locationId/:locationTab"
                                                        element={<LocationDetail />}
                                                    />
                                                </Route>

                                                <Route path="employees" element={<EmployeeLayout />}>
                                                    <Route index element={<EmployeesPage />} />
                                                    <Route path="create" element={<FormEmployee />} />
                                                    <Route path="update/:idEmp" element={<FormEmployee />} />
                                                </Route>
                                            </Route>
                                        </Route>
                                        <Route path="reports">
                                            <Route index element={<ReportDashboard />} />
                                            <Route path=":type/:index" element={<ReportPage />} />
                                        </Route>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <Route path="user" element={<AccountPage />} />
                                <Route path="support" element={<SupportLayout />} />
                                <Route path="permits" element={<FilterPermitProvider />}>
                                    <Route index element={<ListPermit />} />
                                    <Route path="add-permits" element={<UpsertPermit />} />
                                    {/* only show upload permit for Account manager*/}
                                    <Route
                                        path="upload-permits"
                                        element={
                                            authData.account?.label == 'Account manager' ? (
                                                <UpLoadPermit />
                                            ) : (
                                                <Navigate to="/" />
                                            )
                                        }
                                    />

                                    <Route path="edit-permit/:idPermit" element={<UpsertPermit />} />
                                </Route>
                            </Route>
                        )}
                        {/* <Route path="change-password/:token" element={<ChangePassForm />} /> */}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                )}
            </AuthContext.Provider>
        </LanguageContext.Provider>
    );
}

export default App;
