import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosClient } from '../controllers';
import { LocalStore } from '../helpers/localStorage';
import '../i18n';

interface State {
    default: string;
}

const IHUB_LANGUAGE = 'iHubLanguage';

export default function UseLanguage() {
    const [state, setState] = useState<State>({ default: LocalStore.getValue(IHUB_LANGUAGE) || 'en' });
    const { t, i18n } = useTranslation();
    //set language to header
    const language = LocalStore.getValue('iHubLanguage') || 'en';
    axiosClient.defaults.headers['Accept-Language'] = language !== 'en' ? 'de' : 'en';

    const onChangeLanguage = (value: string) => {
        LocalStore.setValue(IHUB_LANGUAGE, value);
        window.location.reload();
    };

    useEffect(() => {
        i18n.changeLanguage(state.default);
    }, []);

    return { ...state, ...i18n, t, onChangeLanguage, language };
}

export const LanguageContext = React.createContext<ReturnType<typeof UseLanguage>>({} as any);
