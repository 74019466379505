import { RecurringType } from "./PermitRecurring";

export interface ExcelRawPermit {
    zoneId: number;
    vrn: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    recurringType: RecurringType | undefined | null;
    permitType: string | undefined;
    startDate: undefined | string;
    startTime: undefined | string;
    endTime: string | undefined;
    bayNumber: number | undefined;
    comment: string | undefined;
    status?: RawUploadPermitStatus;
    reason?: string | undefined,
    tenantId?: number | undefined,
    recurringEvery: number | null;

}

export enum RawUploadPermitStatus {
    Valid = 0,
    InValid = 1,
    Existed = 2
}