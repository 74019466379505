import saveAs from 'file-saver';
import _ from 'lodash';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { anprDashboardDownload, pcnDashboardDownloadController } from '../../../controllers';
import { ConvertToGMT0Time } from '../../../helpers';
import { BlobFile } from '../../../helpers/BlobFileHepler';
import { Filter } from '../../../models/Filter';
import { ReportContext } from './useReport';

export default function useExportMedia() {
    const { t } = useContext(LanguageContext);
    const { filter, setState } = useContext(ReportContext);
    const fileType = (isPdf: boolean, fileName: string) => {
        return isPdf ? `${fileName}.pdf` : `${fileName}.xlsx`;
    };
    const convertFilterDate = () => {
        const cloneFilter = _.cloneDeep(filter);
        return { ...cloneFilter, from: ConvertToGMT0Time(cloneFilter.from!), to: ConvertToGMT0Time(cloneFilter.to!) };
    };
    // Export excel
    const exportExcelDailyVisits = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.dailyVisitsReport'));
        await anprDashboardDownload
            .DownloadDailyVisits({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadAverageLengthOfVisits = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.averageLengthOfVisitsReport'));
        await anprDashboardDownload
            .DownloadAverageLengthOfVisits({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadCapacityAnalysis = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.CapacityAnalysisReport'));
        await anprDashboardDownload
            .DownloadCapacityAnalysis({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadRepeatVisit = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.repeatVisitsReport'));
        await anprDashboardDownload
            .DownloadRepeatVisit({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    // More export for UKPC
    const exportExcelDownloadGroupCancelledPCNByActor = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.cancellationsReport'));
        await pcnDashboardDownloadController
            .DownloadGroupCancelledPCNByActor({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGroupPCNByStatus = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.pncReport'));
        await pcnDashboardDownloadController
            .DownloadGroupPCNByStatus({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGroupPCNByReason = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.pcnByReasonsReport'));
        await pcnDashboardDownloadController
            .DownloadGroupPCNByReason({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGroupPCNByZone = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('reportPage:title.pcnByZonesReport'));
        await pcnDashboardDownloadController
            .DownloadGroupPCNByZone({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadEVDailyVisits = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('dashboard:text.dailyEVVisits'));
        await anprDashboardDownload
            .DownloadGroupEVDailyVisits({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadEVAverageLength = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('dashboard:text.averageLengthOfEVVisits'));
        await anprDashboardDownload
            .DownloadGroupEVAverageLength({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    const exportExcelDownloadGrossRevenue = async (isPdf: boolean) => {
        const nameFile = fileType(isPdf, t('dashboard:title.grossRevenue'));
        await anprDashboardDownload
            .DownloadGrossRevenue({ filter: convertFilterDate(), isPdf })
            .then((res) => {
                BlobFile(res, nameFile);
            })
            .finally(() => {
                setState((prev) => ({ ...prev, isExportLoading: false }));
            });
    };
    return {
        exportExcelDailyVisits,
        exportExcelDownloadAverageLengthOfVisits,
        exportExcelDownloadCapacityAnalysis,
        exportExcelDownloadRepeatVisit,
        exportExcelDownloadGroupCancelledPCNByActor,
        exportExcelDownloadGroupPCNByStatus,
        exportExcelDownloadGroupPCNByReason,
        exportExcelDownloadGroupPCNByZone,
        exportExcelDownloadEVDailyVisits,
        exportExcelDownloadEVAverageLength,
        exportExcelDownloadGrossRevenue,
    };
}
