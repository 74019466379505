import React, { useContext } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import useMedia from './useMedia';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { RangeType } from 'rsuite/esm/DateRangePicker';
import { Box } from '@mui/system';
import color from '../config/Colors';
import font from '../config/Fonts';
import { LanguageContext } from '../contexts/useLanguage';

type Props = {
    startDate: Date;
    endDate: Date;
    onChangeTime: (e: any) => void;
};

export default function useDatePicker({ startDate, endDate, onChangeTime }: Props) {
    const { isMobileSM } = useMedia();
    const { t } = useContext(LanguageContext);
    const { afterToday } = DateRangePicker;
    const handleChangeTime = (any: any) => {
        if (any) {
            onChangeTime(any);
        }
    };
    const Ranges: RangeType[] = [
        {
            label: t('dashboard:title.yesterday'),
            value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
        },
        {
            label: t('dashboard:text.last') + '7' + t('dashboard:text.days'),
            value: [startOfDay(subDays(new Date(), 7)), endOfDay(addDays(new Date(), -1))],
        },
        {
            label: t('dashboard:text.last') + '30' + t('dashboard:text.days'),
            value: [startOfDay(subDays(new Date(), 30)), endOfDay(addDays(new Date(), -1))],
        },
    ];

    // from: startOfDay(subDays(new Date(), 7)),
    // to: endOfDay(addDays(new Date(), -1)),

    const RenderDatePicker = (
        <Box
            sx={{
                '& .rs-picker-toggle-value': {
                    color: 'black !important',
                    textAlign: 'center',
                    fontFamily: font,
                },
                '& .rs-btn:focus': {
                    borderColor: `${color.primary} !important`,
                },
                '& .rs-picker-toggle-active': {
                    borderColor: `${color.primary} !important`,
                },
                '& .rs-calendar-table-cell-content': {
                    backgroundColor: `${color.primary} !important`,
                },
                '& .rs-btn-close': {
                    display: 'none',
                },
                '& .rs-picker,.rs-picker-daterange-panel': {
                    fontFamily: font,
                },
            }}
        >
            <DateRangePicker
                style={{ width: '100%' }}
                placement="bottomEnd"
                placeholder="Select date range"
                format="dd-MM-yyyy"
                disabledDate={afterToday!()}
                showOneCalendar={isMobileSM ? true : false}
                onChange={(e: any) => handleChangeTime(e)}
                value={[startDate, endDate]}
                ranges={Ranges}
                defaultValue={[startDate, endDate]}
            />
        </Box>
    );

    return { RenderDatePicker, onChangeTime };
}
