import React, { SVGProps } from 'react';
import color from '../../../../config/Colors';

type Props = {} & SVGProps<SVGSVGElement>;

export default function EVChartIcon(props: Props) {
    return (
        <svg width="100" height="83" viewBox="0 0 100 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="83" rx="5" fill="#FAFAFA" />
            <rect x="28" y="66.875" width="10" height="6" rx="3" transform="rotate(-90 28 66.875)" fill="#DDDDDD" />
            <rect x="38" y="66.875" width="20" height="6" rx="3" transform="rotate(-90 38 66.875)" fill="#3EB6E4" />
            <rect x="48" y="66.875" width="30" height="6" rx="3" transform="rotate(-90 48 66.875)" fill="#3EB6E4" />
            <rect x="58" y="66.875" width="40" height="6" rx="3" transform="rotate(-90 58 66.875)" fill="#DDDDDD" />
            <rect x="68" y="66.875" width="50" height="6" rx="3" transform="rotate(-90 68 66.875)" fill="#DDDDDD" />
            <g clip-path="url(#clip0_3497_12673)">
                <path
                    d="M45.5001 23.7083L44.4167 24.7917"
                    stroke="#3EB6E4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M41.1667 28.0417H42.2501C42.8247 28.0417 43.3758 28.2699 43.7821 28.6763C44.1885 29.0826 44.4167 29.6337 44.4167 30.2083V33.4583C44.4167 33.8893 44.588 34.3026 44.8927 34.6074C45.1974 34.9121 45.6108 35.0833 46.0417 35.0833C46.4727 35.0833 46.886 34.9121 47.1908 34.6074C47.4955 34.3026 47.6667 33.8893 47.6667 33.4583V25.875L44.4167 22.625"
                    stroke="#3EB6E4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M30.3333 37.7917V22.625C30.3333 22.0504 30.5615 21.4993 30.9679 21.0929C31.3742 20.6866 31.9253 20.4583 32.4999 20.4583H38.9999C39.5746 20.4583 40.1257 20.6866 40.532 21.0929C40.9383 21.4993 41.1666 22.0504 41.1666 22.625V37.7917"
                    stroke="#3EB6E4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M35.75 28.5833L34.125 31.2917H37.375L35.75 34"
                    stroke="#3EB6E4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M29.25 37.7917H42.25" stroke="#3EB6E4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M30.3333 24.7917H41.1666" stroke="#3EB6E4" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3497_12673">
                    <rect width="26" height="26" fill="white" transform="translate(26 16.125)" />
                </clipPath>
            </defs>
        </svg>
    );
}
