import { Box, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext, useId, useState } from 'react';
import Popover from '@mui/material/Popover';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { useNavigate } from 'react-router-dom';
import { LocationWithDetail } from '../../../../models/Location';
import { formatDate } from '../../../../helpers/moment';
import { appConfig } from '../../../../config/AppConfig';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import color from '../../../../config/Colors';
import GeneralInfoIcon from '../../../../assets/images/GeneralInfoIcon';
import ZoneIcon from '../../../../assets/images/ZoneIcon';
import AddTenantIcon from '../../../../assets/images/AddTenantIcon';
import { LocationTab } from '../../locationDetail';

type Props = {
    locations: LocationWithDetail[];
    isShowLocationPopOver: boolean;
};

function ViewMapItem(props: Props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: appConfig.gateway.googMapSiteKey,
    });

    const markers = props.locations.map((value, index) => {
        if (value.latitude && value.longitude) {
            return {
                lat: value.latitude,
                lng: value.longitude,
            };
        }
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(
        function callback(map: any) {
            const bounds = new window.google.maps.LatLngBounds();
            markers &&
                markers.map((item, index) => {
                    if (item) {
                        bounds.extend(new google.maps.LatLng(item.lat, item.lng));
                    }
                });
            map.fitBounds(bounds);
            setMap(map);
        },
        [markers]
    );

    const { t } = useContext(LanguageContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [locationSelect, setLocationSelect] = useState<LocationWithDetail>({} as any);

    const handleClick = (event: any, location: LocationWithDetail) => {
        // console.log(event.domEvent.currentTarget);
        event.domEvent.preventDefault();
        // event.stopPropagation();
        setAnchorEl(event.domEvent.currentTarget);
        setLocationSelect(location);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iid = useId();

    const onClickSelectGeneralInfo = () => {
        navigate(`locations/${locationSelect.id}/${LocationTab.GeneralInformation}`);
    };

    const onClickSelectZones = () => {
        navigate(`locations/${locationSelect.id}/${LocationTab.Zones}`);
    };

    const onClickSelectTenants = () => {
        navigate(`locations/${locationSelect.id}/${LocationTab.Tenant}`);
    };

    return (
        <Grid container sx={{ borderRadius: '10px', overflow: 'hidden' }}>
            <Box sx={{ height: { xs: '300px', sm: '300px', md: '450px', lg: '450px' }, width: '100%' }}>
                {props.locations &&
                    props.locations.length > 0 &&
                    (isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%', display: 'block' }}
                            onLoad={onLoad}
                            // onUnmount={onUnmount}
                            options={{
                                streetViewControl: true,
                                mapTypeId: 'roadmap',
                                center: new google.maps.LatLng(
                                    props.locations[0].latitude,
                                    props.locations[0].longitude
                                ),
                                // disableDoubleClickZoom: true,
                                zoom: 18,
                            }}
                            key={iid}
                        >
                            {props.locations &&
                                props.locations.length > 0 &&
                                props.locations.map((location, index) => {
                                    if (location.longitude !== null || location.latitude !== null) {
                                        return (
                                            <React.Fragment key={index}>
                                                <Marker
                                                    icon={{ url: '/assets/images/mapMarker.png' }}
                                                    position={
                                                        new window.google.maps.LatLng(
                                                            location.latitude,
                                                            location.longitude
                                                        )
                                                    }
                                                    onClick={(e) => handleClick(e, location)}
                                                />
                                            </React.Fragment>
                                        );
                                    }
                                })}
                        </GoogleMap>
                    ) : (
                        <Box></Box>
                    ))}

                {props.isShowLocationPopOver && props.isShowLocationPopOver == true && locationSelect && (
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid
                            sx={{ p: '16px 24px', width: 320 }}
                            container
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'flex-start'}
                        >
                            <Stack
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                direction={'row'}
                                width={'100%'}
                            >
                                <Typography variant="body1">
                                    {locationSelect.name && locationSelect.name.length > 20
                                        ? locationSelect.name.slice(0, 20) + '...'
                                        : locationSelect.name}
                                </Typography>

                                <Grid
                                    item
                                    display={'flex'}
                                    justifyContent={'right'}
                                    sx={{
                                        backgroundColor: `${color.white} !important`,
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            backgroundColor: `${color.white} !important`,
                                        }}
                                    >
                                        <Tooltip title={t('companies:title.generalInformation')} placement="top">
                                            <IconButton
                                                onClick={onClickSelectGeneralInfo}
                                                aria-label="fingerprint"
                                                color="success"
                                                sx={{
                                                    backgroundColor: color.grey200,
                                                    '&:hover': {
                                                        backgroundColor: color.grey200,
                                                        '.svg-icon-fill': {
                                                            fill: color.primary,
                                                            transitionDuration: '0.2s',
                                                        },
                                                        '.svg-icon-stroke': {
                                                            stroke: color.primary,
                                                            transitionDuration: '0.2s',
                                                        },
                                                    },

                                                    // outline: `5px solid ${color.grey100}`,
                                                    border: `5px solid ${color.grey100}`,
                                                    width: '40px',
                                                    height: '40px',
                                                }}
                                            >
                                                <GeneralInfoIcon style={{ width: '20px', height: '20px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={t('companies:text.zones')} placement="top">
                                            <IconButton
                                                onClick={onClickSelectZones}
                                                aria-label="fingerprint"
                                                color="success"
                                                sx={{
                                                    backgroundColor: color.grey200,
                                                    '&:hover': {
                                                        backgroundColor: color.grey200,
                                                        '&:hover': {
                                                            backgroundColor: color.grey200,
                                                            '.svg-icon-fill': {
                                                                fill: color.primary,
                                                                transitionDuration: '0.2s',
                                                            },
                                                            '.svg-icon-stroke': {
                                                                stroke: color.primary,
                                                                transitionDuration: '0.2s',
                                                            },
                                                        },
                                                    },
                                                    border: `5px solid ${color.grey100}`,
                                                    width: '40px',
                                                    height: '40px',
                                                }}
                                            >
                                                <ZoneIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={t('companies:text.tenants')} placement="top">
                                            <IconButton
                                                onClick={onClickSelectTenants}
                                                aria-label="fingerprint"
                                                color="success"
                                                sx={{
                                                    backgroundColor: color.grey200,
                                                    '&:hover': {
                                                        backgroundColor: color.grey200,
                                                        '.svg-icon-fill': {
                                                            fill: color.primary,
                                                            transitionDuration: '0.2s',
                                                        },
                                                        '.svg-icon-stroke': {
                                                            stroke: color.primary,
                                                            transitionDuration: '0.2s',
                                                        },
                                                    },
                                                    border: `5px solid ${color.grey100}`,
                                                    width: '40px',
                                                    height: '40px',
                                                }}
                                            >
                                                <AddTenantIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Stack>

                            <Grid item mt={'5px'}>
                                <Typography variant="body1">
                                    {t('companies:text.address')}: {locationSelect.address1} -{' '}
                                    {t('companies:text.postcode')}: {locationSelect.postcode}
                                </Typography>
                            </Grid>

                            <Grid
                                mt={1}
                                item
                                container
                                direction={'row'}
                                justifyContent={'flex-start'}
                                alignItems={'center'}
                            >
                                <Typography variant="body1" width={'100%'}>
                                    {t('companies:text.zones')}: {locationSelect.zones || 0}
                                </Typography>

                                {/* <Box sx={{ borderLeft: '1px solid #85858A', height: 18, m: '0 4px' }} /> */}

                                <Typography variant="body1">
                                    {t('companies:text.siteLiveDate')}:{' '}
                                    {formatDate.getLocalDate(locationSelect.created)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Popover>
                )}
            </Box>
        </Grid>
    );
}

export default React.memo(ViewMapItem);
