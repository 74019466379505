export enum ReportType {
    dailyVisit = 'daily-visits',
    capacityAnalysis = 'capacity-analysis',
    averageLength = 'average-length-of-visits',
    repeatVisits = 'repeat-visits',
    groupByPCNTypes = 'pcn-types',
    groupByPCNCancellations = 'pcn-cancellations',
    PCNByZones = 'pcn-by-zones',
    PCNByReason = 'pcn-by-reason',
    evDailyVisit = 'ev-daily-visits',
    evAverageLength = 'ev-length-of-visits',
    grossRevenue = 'gross-revenue',
    none = 'none'
}
