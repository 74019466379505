import { Box, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../../../../config/Colors';
import { UpsertPermitItemContext } from '../UpsertPermitItem/hooks/useUpsertPermitItem';
import moment from 'moment';
import _ from 'lodash';
import { addDays, isAfter } from 'date-fns';
import useMedia from '../../../../../hooks/useMedia';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function WeekRecurring() {
    const { t, daysOfWeekRecur, permit, setItemState, getRecurEveryNumber, setRecurEveryNumber } =
        useContext(UpsertPermitItemContext);
    const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];
    const isSmallMD = useMediaQuery('(max-width:1000px)');

    return (
        <Grid
            direction={'row'}
            sx={{ background: color.grey100, borderRadius: '5px', padding: '10px !important' }}
            alignItems="center"
            container
            item
            gap={isSmallMD ? 2 : 0}
        >
            <Grid item container xs={12} md={isSmallMD ? 12 : 6} lg={5} direction={'row'} alignItems={'center'}>
                <Typography variant="body1" component={'span'}>
                    {t('permits:text.recurEvery')}
                </Typography>

                <TextField
                    placeholder={''}
                    sx={{
                        minWidth: '50px',
                        mx: 2,
                        '& input': {
                            textAlign: 'center',
                        },
                    }}
                    inputProps={{ min: 1, max: 53 }}
                    value={getRecurEveryNumber()}
                    onChange={(e) => {
                        const value = Number(e.target!.value);
                        const val = value < 1 ? 1 : value > 53 ? 53 : value;
                        setRecurEveryNumber(val);
                    }}
                    size="small"
                    type={'number'}
                />

                <Grid item>
                    <Typography variant="body1" component={'span'} mr={2}>
                        {t('permits:text.week(s) on')}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container xs={12} md={isSmallMD ? 12 : 6} lg={7} direction={'row'} alignItems={'center'}>
                <Grid item>
                    <Stack direction={'row'}>
                        {daysOfWeek.map((item) => {
                            const isActive = daysOfWeekRecur.findIndex((day) => moment(day).weekday() === item) >= 0;
                            return (
                                <Box
                                    key={item}
                                    sx={[
                                        {
                                            width: '32px',
                                            height: '32px',
                                            borderRadius: '50%',
                                            backgroundColor: color.grey400,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            mr: 1,
                                            userSelect: 'none',
                                        },
                                        isActive
                                            ? {
                                                  backgroundColor: color.priText,
                                              }
                                            : {},
                                    ]}
                                    onClick={() => {
                                        if (isActive) {
                                            if (daysOfWeekRecur.length > 1) {
                                                const dayRecur = _.cloneDeep(daysOfWeekRecur).filter(
                                                    (day) => moment(day).weekday() !== item
                                                );
                                                setItemState((prev) => ({
                                                    ...prev,
                                                    daysOfWeekRecur: dayRecur.sort(
                                                        (objA, objB) => objA.getTime() - objB.getTime()
                                                    ),
                                                }));
                                            }
                                        } else {
                                            const arr = _.cloneDeep(daysOfWeekRecur);
                                            //check if day is before today
                                            arr.push(moment().weekday(item).toDate());
                                            setItemState((prev) => ({
                                                ...prev,
                                                daysOfWeekRecur: arr.sort(
                                                    (objA, objB) => objA.getTime() - objB.getTime()
                                                ),
                                            }));
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={'10px'}
                                        color={color.white}
                                        textAlign="center"
                                    >
                                        {moment.weekdaysShort(item)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}
