import React, { useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MyTabPanel } from '../../../components/MyTabPanel';
import color from '../../../config/Colors';
import useReport from '../useTabData';
import useTabData from '../useTabData';
import { LanguageContext } from '../../../contexts/useLanguage';
import { Navigate, useNavigate } from 'react-router-dom';

export default function MyTab() {
    const { setState, getPanel, getTableRows, listTabs, ...state } = useTabData();
    const { t } = useContext(LanguageContext);
    const [indexTab, setIndexTab] = useState<number>(0);
    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const index = listTabs.findIndex((item) => item.index == newValue);
        setState((prev) => ({ ...prev, tabIndex: listTabs[index].label }));
    };

    useEffect(() => {
        const index = listTabs.findIndex((i) => i.label == state.tabIndex);
        index >= 0 ? setIndexTab(index) : navigate('/dashboard', { replace: true });
    });

    const getLabel = (label: string) => {
        if (label == 'graph') {
            return t('reportPage:button.graph');
        } else if (label == 'details') {
            return t('reportPage:button.details');
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs
                    value={listTabs[indexTab].index}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        height: '32px',
                        minHeight: 'auto',
                        '& .MuiTabs-indicator': {
                            background: color.priText,
                        },
                    }}
                >
                    {listTabs.map((item, index) => {
                        const isChosen = item.label === state.tabIndex;

                        return (
                            <Tab
                                key={index}
                                label={getLabel(item.label)}
                                {...a11yProps(index)}
                                icon={item.icon}
                                sx={[
                                    isChosen
                                        ? {
                                              background: color.lightPrimary,
                                              color: `${color.priText} !important`,

                                              '& .svg-icon-fill': {
                                                  fill: color.priText,
                                              },

                                              '& .detail-icon': {
                                                  stroke: color.priText,
                                              },
                                          }
                                        : {},
                                ]}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            {listTabs.map((item, index) => {
                const itemSearch = listTabs.findIndex((i) => i.label == state.tabIndex);
                if (itemSearch >= 0) {
                    return (
                        <MyTabPanel key={index} value={listTabs[itemSearch].index} index={item.index}>
                            {item.panel}
                        </MyTabPanel>
                    );
                } else {
                    return <Navigate key={index} to={'dashboard'} replace={true} />;
                }
            })}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
