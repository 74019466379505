/* eslint-disable eqeqeq */
import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays } from 'date-fns';
import { useContext } from 'react';
import CalendarIcon from '../../../../../../assets/svg/CalendarIcon';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import color from '../../../../../../config/Colors';
import useMedia from '../../../../../../hooks/useMedia';
import { ValidateToType } from '../../../../../../models/permits/Permit';
import { RecurringType } from '../../../../../../models/permits/PermitRecurring';
import { UpsertPermitItemContext } from '../hooks/useUpsertPermitItem';
export const useStylesPicker = makeStyles((theme: Theme) => ({
    inputStyle: {
        '& .Mui-selected': {
            backgroundColor: `${color.primary} !important`,
        },
    },
}));

export default function UpsertPermitItemDateRange() {
    const classes = useStylesPicker();
    const { t, permit, setItemState, errors, touched, getFieldProps, formik, setFieldTouched } =
        useContext(UpsertPermitItemContext);
    const { isMobileSM } = useMedia();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container xs={12} item justifyContent={'space-between'} alignItems="center" spacing={2}>
                <Grid
                    mt={0.5}
                    xs={12}
                    display={permit.permitRecurring[0].recurringType === RecurringType.once ? 'none' : 'block'}
                    item
                    mb={Boolean(touched.validateTo && errors.validateTo) ? 1.5 : 0}
                  >
                    {permit.permitRecurring[0].recurringType === RecurringType.once ||
                    permit.permitRecurring[0].recurringType === RecurringType.indefinite ? (
                        ''
                    ) : (
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={formik.values.endStatus}
                            name="radio-buttons-group"
                            onChange={(e: any, val) => {
                                const validTo = val != '0' ? addDays(formik.values.validateFrom!, 1) : null;
                                setFieldTouched('validateTo');
                                formik.setValues((prev) => ({ ...prev, endStatus: Number(val), validateTo: validTo }));
                            }}
                            color={color.success}
                            sx={{ flexDirection: 'row' }}
                        >
                            <Grid
                                item
                                container
                                display={'flex'}
                                direction={'row'}
                                alignItems="center"
                                mr={isMobileSM ? 0 : 2}
                                md={4}
                                sm={12}
                                // mt={{ xs: 1, sm: 0 }}
                            >
                                <Grid xs={3.8} item>
                                    <FormControlLabel
                                        sx={{
                                            '&.MuiFormControlLabel-labelPlacementEnd': {
                                                marginRight: '0px !important',
                                            },
                                            minWidth: '100px',
                                        }}
                                        value={ValidateToType.absoluteAt}
                                        disabled={formik.values.validateFrom === null}
                                        control={<Radio />}
                                        label={<Typography variant="body1">{t('permits:text.endBy')}</Typography>}
                                    />
                                </Grid>
                                <Grid item xs={8.2}>
                                    <DatePicker
                                        label={<LabelWithRequired label={t('permits:text.endDate')} />}
                                        value={formik.values.endStatus != 0 ? null : formik.values.validateTo}
                                        onChange={(newValue) => {
                                            if (newValue != null) {
                                                setFieldTouched('validateTo');
                                                formik.setValues((prev) => ({ ...prev, validateTo: newValue }));
                                            }
                                        }}
                                        disabled={
                                            formik.values.endStatus != ValidateToType.absoluteAt ||
                                            formik.values.validateFrom == null
                                        }
                                        components={{
                                            OpenPickerIcon: CalendarIcon,
                                        }}
                                        inputFormat="dd/MM/yyyy"
                                        minDate={formik.values.validateFrom}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...getFieldProps('validateTo')}
                                                error={Boolean(touched.validateTo && errors.validateTo)}
                                                helperText={touched.validateTo && errors.validateTo}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        caretColor: 'transparent',
                                                    },
                                                    // maxWidth: '180px',
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                fullWidth={true}
                                            />
                                        )}
                                        renderDay={(day, _value, DayComponentProps) => {
                                            return (
                                                <Box className={classes.inputStyle}>
                                                    <PickersDay {...DayComponentProps} />
                                                </Box>
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                display={'flex'}
                                direction={'row'}
                                alignItems="center"
                                mr={isMobileSM ? 0 : 2}
                                item
                                container
                                md={4}
                                sm={12}
                                mt={{ xs: Boolean(touched.validateTo && errors.validateTo) ? 4 : 1, md: 0 }}
                            >
                                <Grid xs={3.8}>
                                    <FormControlLabel
                                        sx={{
                                            '&.MuiFormControlLabel-labelPlacementEnd': {
                                                marginRight: '0px !important',
                                            },
                                        }}
                                        value={ValidateToType.recurringTime}
                                        control={<Radio />}
                                        disabled={formik.values.validateFrom === null}
                                        label={<Typography variant="body1">{t('permits:text.endAfter')}</Typography>}
                                    />
                                </Grid>

                                <Grid item xs={8.2}>
                                    <TextField
                                        disabled={formik.values.endStatus != ValidateToType.recurringTime}
                                        type={'number'}
                                        inputProps={{ min: 1, max: 9999 }}
                                        onChange={(e) => {
                                            const value = Number(e.target!.value);
                                            const val = value < 1 ? 1 : value > 9999 ? 9999 : value;
                                            setItemState((prev) => ({ ...prev, endAfterRecurTime: val }));
                                            formik.setValues((prev) => ({ ...prev, endAfterValue: val }));
                                        }}
                                        value={formik.values.endAfterValue}
                                        label={<LabelWithRequired label={t('permits:text.recurringTime')} />}
                                        placeholder={t('permits:text.enterTime')}
                                        sx={{ minWidth: '120px' }}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid md={2} sm={12} item mt={{ xs: 1, md: 0 }}>
                                <FormControlLabel
                                    disabled={formik.values.validateFrom === null}
                                    value={ValidateToType.infinite}
                                    control={<Radio />}
                                    label={t('permits:text.noEndDate')}
                                />
                            </Grid>
                        </RadioGroup>
                    )}
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
