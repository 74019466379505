/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import color from '../config/Colors';
import { BsFilter } from 'react-icons/bs';
import { LanguageContext } from '../contexts/useLanguage';
import { Filter } from '../models/Filter';
import useAutoComplete from '../components/useAutoComplete';
import useMultiSelectWithCheckBox from '../components/useMultiSelectWithCheckBox';
import 'rsuite/dist/rsuite.min.css';
import useDatePicker from './useDatePicker';
import {
    anprController,
    companiesController,
    locationController,
    regionController,
    statisticPCNController,
    zoneController,
} from '../controllers';
import { SelectItem } from '../components/MySelect';
import { DailyVisitsStatistic } from '../models/DailyVisitsStatistic';
import { CapacityAnalysis } from '../models/CapacityAnalysis';
import { RepeatVisit } from '../models/RepeatVisits';
import { AverageLengthStatistic } from '../models/AverageLengthStatistic';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import useConvertLabel from '../helpers/ConvertDataLabel';
import { StatisticItems } from '../models/StatisticItems';
import { StatisticItem } from '../models/StatisticItems';
import { StatisticItemByZone } from '../models/StatisticItemByZone';
import { ReportType } from '../models/ReportType';
import { appConfig } from '../config/AppConfig';
import { Location } from '../models/Location';
import { Region } from '../models/Region';
import { convertToSelectedItem } from '../helpers/ConvertFilterItem';
import { useLocation } from 'react-router-dom';
import { ConvertToGMT0Time } from '../helpers';
import _ from 'lodash';
import { GrossRevenue } from '../models/grossRevenue/GrossRevenue';
import { GROSS_REVENUE_CROSS_LOCATION_ID } from '../contexts/FilterContext';

export interface StateFilter {
    filter: Filter;

    listCompany: SelectItem[];
    listRegion: Region[];
    listLocation: Location[];
    listZone: SelectItem[];

    dailyVisitsStatistic: DailyVisitsStatistic;
    averageLengthStatistic: AverageLengthStatistic[];
    capacityAnalysis: CapacityAnalysis;
    repeatVisits: RepeatVisit[];
    groupByPNCType: StatisticItems;
    groupByPCNCancellations: StatisticItems;
    groupPCNByZone: StatisticItemByZone[];
    groupPCNByReason: StatisticItem[];
    grossRevenueStatistic: GrossRevenue;
}

export enum FilterEnum {
    companyId = 'companyId',
    regionId = 'regionId',
    locationId = 'locationId',
    zoneIds = 'zoneIds',
    shopId = 'shopId',
    all = 'all',
}

export type FilterShowingPage = 'dashboard' | 'report';

export interface ReportFilterState {
    showingPage: FilterShowingPage;
    report: ReportType;
}

export default function useFilter() {
    const { t } = useContext(LanguageContext);

    const [editingField, setEditingField] = useState<FilterEnum>(FilterEnum.all);
    const [loadingAPIs, setLoadingAPIs] = useState({ zone: true });
    const location = useLocation();
    const [isLoadData, setIsLoadData] = useState<boolean>(false);
    const [loadingRepeatVisit, setLoadingRepeatVisit] = useState(true);

    const defaultFilter: Filter = {
        companyId: 0,
        regionId: 0,
        locationId: 0,
        zoneIds: [],
        from: startOfDay(subDays(new Date(), 7)),
        to: endOfDay(addDays(new Date(), -1)),
    };

    const [state, setState] = useState<StateFilter>({
        filter: defaultFilter,
        listCompany: [],
        listRegion: [],
        listLocation: [],
        listZone: [],
        dailyVisitsStatistic: {} as any,
        averageLengthStatistic: [],
        capacityAnalysis: {} as any,
        repeatVisits: [],
        groupByPNCType: { items: [] },
        groupByPCNCancellations: { items: [] },
        groupPCNByZone: [],
        groupPCNByReason: [],
        grossRevenueStatistic: {} as any,
    });
    const [reportState, setReportState] = useState<ReportFilterState>({
        showingPage: 'dashboard',
        report: ReportType.none,
    });

    const { RenderDatePicker } = useDatePicker({
        startDate: state.filter.from!,
        endDate: state.filter.to!,
        onChangeTime(e) {
            setState((prev) => ({ ...prev, filter: { ...prev.filter, from: startOfDay(e[0]), to: endOfDay(e[1]) } }));
        },
    });
    const { convertAverageLength } = useConvertLabel();

    //get data when is report Page or dashboard
    useEffect(() => {
        if (location.pathname.includes('dashboard') || location.pathname.includes('reports')) {
            setIsLoadData(true);
        } else {
            setIsLoadData(false);
        }
    }, [location]);

    useEffect(() => {
        if (isLoadData === true) {
            companiesController.getMyCompany().then((res) => {
                res.unshift({ value: 0, label: t('dashboard:text.allCompany') });
                setState((prev) => ({ ...prev, listCompany: res }));
            });
        }
    }, [isLoadData]);

    useEffect(() => {
        if (isLoadData) {
            if (editingField === FilterEnum.locationId) return;

            locationController
                .getAll({
                    pageSize: 10000,
                    filter: { companyId: state.filter.companyId || 0, regionId: state.filter.regionId || 0 },
                    search: {
                        fields: [],
                        value: '',
                    },
                })
                .then((res) => {
                    const all: Location = { id: 0, name: t('dashboard:text.allLocation') } as Location;

                    setState((prev) => ({
                        ...prev,
                        listLocation: [all].concat(res.data),
                    }));
                });
        }
    }, [state.filter.companyId, state.filter.regionId, editingField, isLoadData]);

    useEffect(() => {
        if (isLoadData) {
            regionController
                .getAll({
                    pageSize: 10000,
                    filter: { companyId: state.filter.companyId || 0 },
                    search: {
                        fields: [],
                        value: '',
                    },
                })
                .then((res) => {
                    const all: Region = { id: 0, name: t('dashboard:text.allRegions') } as Region;

                    setState((prev) => ({
                        ...prev,
                        listRegion: [all].concat(res.data),
                    }));
                });
        }
    }, [state.filter.companyId, isLoadData]);

    useEffect(() => {
        const init = async () => {
            setLoadingAPIs((p) => ({ ...p, zone: true }));
            if (state.filter.locationId !== 0) {
                const zones = await zoneController.getZones(state.filter.locationId!);
                setState((prev) => ({
                    ...prev,
                    listZone: zones,
                    filter: { ...prev.filter, zoneIds: zones.map((r) => r.value) },
                }));
            }
            setLoadingAPIs((p) => ({ ...p, zone: false }));
        };
        init();
    }, [state.filter.locationId]);

    useEffect(() => {
        if (reportState.showingPage === 'dashboard' && !loadingAPIs.zone && isLoadData) {
            const filterGMT0 = convertFilterDate();
            setLoadingRepeatVisit(true);
            anprController.getDailyVisits(filterGMT0).then((res) => {
                setState((prev) => ({ ...prev, dailyVisitsStatistic: res }));
            });
            anprController.getAverageLengthOfVisits(filterGMT0).then((res) => {
                setState((prev) => ({ ...prev, averageLengthStatistic: convertAverageLength(res) }));
            });
            anprController.getCapacityAnalysis(filterGMT0).then((res) => {
                setState((prev) => ({ ...prev, capacityAnalysis: res }));
            });
            anprController
                .getRepeatVisits(filterGMT0)
                .then((res) => {
                    setState((prev) => ({ ...prev, repeatVisits: res }));
                })
                .finally(() => setLoadingRepeatVisit(false));
            if (appConfig.flagFeatures.isEnableReportPCNs === true) {
                statisticPCNController.groupPCNByStatus(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupByPNCType: res }));
                });
                statisticPCNController.groupCancelledPCNByActor(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupByPCNCancellations: res }));
                });
                statisticPCNController.groupPCNByZone(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupPCNByZone: res.items }));
                });
                statisticPCNController.groupPCNByReason(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupPCNByReason: res.items }));
                });
            }
            if (state.filter.locationId === GROSS_REVENUE_CROSS_LOCATION_ID) {
                anprController.getGrossRevenue(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, grossRevenueStatistic: res }));
                });
            }
        }
    }, [state.filter, loadingAPIs.zone, isLoadData]);

    useEffect(() => {
        if (reportState.showingPage === 'report' && isLoadData) {
            const filterGMT0 = convertFilterDate();
            if (reportState.report === ReportType.averageLength) {
                anprController.getAverageLengthOfVisits(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, averageLengthStatistic: convertAverageLength(res) }));
                });
            } else if (
                reportState.report === ReportType.capacityAnalysis ||
                reportState.report === ReportType.evAverageLength
            ) {
                anprController.getCapacityAnalysis(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, capacityAnalysis: res }));
                });
            } else if (reportState.report === ReportType.repeatVisits) {
                setLoadingRepeatVisit(true);
                anprController
                    .getRepeatVisits(filterGMT0)
                    .then((res) => {
                        setState((prev) => ({ ...prev, repeatVisits: res }));
                    })
                    .finally(() => setLoadingRepeatVisit(false));
            } else if (reportState.report === ReportType.dailyVisit || reportState.report === ReportType.evDailyVisit) {
                anprController.getDailyVisits(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, dailyVisitsStatistic: res }));
                });
            } else if (reportState.report === ReportType.groupByPCNTypes) {
                statisticPCNController.groupPCNByStatus(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupByPNCType: res }));
                });
            } else if (reportState.report === ReportType.groupByPCNCancellations) {
                statisticPCNController.groupCancelledPCNByActor(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupByPCNCancellations: res }));
                });
            } else if (reportState.report === ReportType.PCNByZones) {
                statisticPCNController.groupPCNByZone(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupPCNByZone: res.items }));
                });
            } else if (reportState.report === ReportType.PCNByReason) {
                statisticPCNController.groupPCNByReason(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, groupPCNByReason: res.items }));
                });
            } else if (reportState.report === ReportType.grossRevenue) {
                anprController.getGrossRevenue(filterGMT0).then((res) => {
                    setState((prev) => ({ ...prev, grossRevenueStatistic: res }));
                });
            }
        }
    }, [state.filter, reportState.report, isLoadData]);

    const onChangeFilter = (field: FilterEnum, value: any) => {
        if (field === FilterEnum.companyId) {
            setState((prev) => ({
                ...prev,
                filter: { ...prev.filter, [field]: value, regionId: 0, locationId: 0, zoneIds: [] },
            }));
        } else if (field === FilterEnum.regionId) {
            setState((prev) => ({
                ...prev,
                filter: { ...prev.filter, [field]: value, locationId: 0, zoneIds: [] },
            }));
        } else if (field === FilterEnum.locationId) {
            setLoadingAPIs((prev) => ({ ...prev, zone: true }));
            if (!value && state.filter.companyId) setEditingField(FilterEnum.all);
            else setEditingField(field);

            if (state.filter.companyId !== 0 && value === 0) {
                setState((prev) => ({
                    ...prev,
                    filter: { ...prev.filter, [field]: value, regionId: 0, zoneIds: [] },
                }));
            } else {
                const { regionId = 0, companyId = 0 } = state.listLocation.find((l) => l.id === value)!;
                setState((prev) => ({
                    ...prev,
                    filter: { ...prev.filter, [field]: value, companyId, regionId, zoneIds: [] },
                }));
            }
        } else if (field === FilterEnum.zoneIds) {
            setState((prev) => ({ ...prev, filter: { ...prev.filter, [field]: value } }));
        } else {
            setState((prev) => ({
                ...prev,
                filter: defaultFilter,
            }));
            setEditingField(FilterEnum.all);
        }
    };

    const convertFilterDate = () => {
        const cloneFilter = _.cloneDeep(state.filter);
        return { ...cloneFilter, from: ConvertToGMT0Time(cloneFilter.from!), to: ConvertToGMT0Time(cloneFilter.to!) };
    };

    const companySelect = useAutoComplete({
        listData: state.listCompany,
        label: t('dashboard:input.company'),
        value: state.listCompany.find((item) => item.value === state.filter.companyId),
        setFilter: (val) => {
            onChangeFilter(FilterEnum.companyId, val);
        },
        disabled: editingField === FilterEnum.locationId,
    });

    const _listRegionMapped = state.listRegion.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const regionValue = state.listRegion.find((item) => item.id === state.filter.regionId);

    const regionSelect = useAutoComplete({
        listData: _listRegionMapped,
        label: t('dashboard:input.region'),
        value: regionValue ? convertToSelectedItem(regionValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            onChangeFilter(FilterEnum.regionId, val);
        },
        disabled: !state.filter.companyId || editingField === FilterEnum.locationId,
    });

    const _listLocationMapped = state.listLocation.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const locationValue = state.listLocation.find((item) => item.id === state.filter.locationId);

    const locationSelect = useAutoComplete({
        listData: _listLocationMapped,
        label: t('dashboard:input.location'),
        value: locationValue ? convertToSelectedItem(locationValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            onChangeFilter(FilterEnum.locationId, val);
        },
    });

    const getListZone = (numbers: number[]) => {
        return state.listZone.filter((select) => select.value === numbers.find((num) => num === select.value));
    };

    const zoneSelects = useMultiSelectWithCheckBox({
        listData: state.listZone,
        label: t('dashboard:input.zone'),
        value: getListZone(state.filter.zoneIds!),
        setFilter: (list) => {
            onChangeFilter(FilterEnum.zoneIds, list);
        },
        isDisable: !state.filter.locationId,
    });

    const handleResetFilter = () => {
        onChangeFilter(FilterEnum.all, '');
    };

    const FilterComponents = (
        <Grid
            item
            container
            xs={12}
            p={2}
            sx={{ background: color.grey100 }}
            borderRadius={'10px'}
            justifyContent={'space-between'}
        >
            <Grid
                item
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                <Typography variant="body1" component={'span'} mx={0.5}>
                    {t('dashboard:button.filter')}{' '}
                </Typography>
                <Button variant="cancelSmall" onClick={handleResetFilter}>
                    {t('dashboard:button.resetFilter')}
                </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={3} mt={{ xs: 1, sm: 0, md: 0 }}>
                {RenderDatePicker}
            </Grid>

            <Grid item xs={12} mt={{ xs: 1, sm: 2, md: 2 }}>
                {locationSelect.RenderSelect}
            </Grid>

            <Grid item xs={12} container mt={{ xs: 2, sm: 2, md: 2 }} justifyContent={'space-between'}>
                <Grid item xs={12} sm={12} md={6.9} container justifyContent={'space-between'}>
                    <Grid item xs={12} sm={6} md={6}>
                        {companySelect.RenderSelect}
                    </Grid>

                    <Grid item xs={12} sm={5.9} md={5.9} mt={{ xs: 2, sm: 0, md: 0 }}>
                        {regionSelect.RenderSelect}
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={5} mt={{ xs: 2, sm: 2, md: 0 }}>
                    {zoneSelects.RenderSelect}
                </Grid>
            </Grid>
        </Grid>
    );

    return { ...state, FilterComponents, setReportState, reportState, loadingRepeatVisit };
}
