/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import { SelectItem } from '../../../../components/MySelect';

import { LanguageContext } from '../../../../contexts/useLanguage';
import { Permit, ValidateToType } from '../../../../models/permits/Permit';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { PermitRecurring, PermitRecurringDay, RecurringType } from '../../../../models/permits/PermitRecurring';
import { permitController, zoneController } from '../../../../controllers';
import toast from 'react-hot-toast';
import { DrawerContext } from '../../../dashboardLayout/useDrawer';
import { convertHour } from '../../../../helpers/ConvertHour';
import { PermitFilterContext } from '../../hooks/usePermitsFilter';
import moment from 'moment';
import { ExcelRawPermit, RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import useModal from '../../../../components/modal/useModal';
import { Account } from '../../../../models/Account';

interface State {
    filter: UpsertFilter;
    filterData: UpsertFilterData;
    permits: Permit[];
    isEdit: boolean;
    validations: ItemValid[];
    isValid: boolean;
    isAdd: boolean;
    permitsAddSuccess: Permit[];
    vrnCheckingList: CheckingVRNItem[];
    clientInfo?: Account;
}

export interface UpsertFilter {
    idCompany: number;
    idLocation: number;
    idRegion: number;
    idShop: number;
    idZones: number[];
}

export interface UpsertFilterData {
    listCompany: SelectItem[];
    listRegion: SelectItem[];
    listLocation: SelectItem[];
    listZone: SelectItem[];
    listShop: SelectItem[];
    zonesInfo: { maxAllocated: number; allocatedPermits: number };
}

export interface ItemValid {
    id: number;
    isValid: boolean;
}

export const PERMITS_LIMIT = 10;

export default function useUpsertPermit() {
    const { t } = useContext(LanguageContext);
    const { filter } = useContext(PermitFilterContext);
    const { idPermit } = useParams();
    const defaultFilter: State = {
        filter: {
            idCompany: filter.filter?.companyId!,
            idLocation: filter.filter?.locationId!,
            idRegion: filter.filter?.regionId!,
            idShop: filter.filter?.shopId!,
            idZones: [],
        },
        filterData: {
            listCompany: [],
            listLocation: [],
            listRegion: [],
            listZone: [],
            listShop: [],
            zonesInfo: {} as any,
        },
        permits: [],
        isEdit: idPermit ? true : false,
        validations: [],
        isAdd: false,
        isValid: true,
        permitsAddSuccess: [],
        vrnCheckingList: [],
    };
    const [state, setState] = useState<State>(defaultFilter);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);
    const { setActiveItem } = useContext(DrawerContext);
    const navigate = useNavigate();
    const { reloadPermitsData } = useContext(PermitFilterContext);
    const modalWarning = useModal();

    //add empty permit when change zone id
    useEffect(() => {
        if (state.isEdit === false) {
            if (state.filter.idZones.length === 1) {
                // // console.log('zone change ', state.filter.idZones[0]);
                if (state.filterData?.zonesInfo?.maxAllocated) {
                    handleAddEmptyPermitWhenChangeZone();
                } else {
                    resetAllPermits();
                }
            }
        }
    }, [state.filter.idZones, state.filterData?.zonesInfo]);

    useEffect(() => {
        setActiveItem(5);
    }, []);

    useEffect(() => {
        const list = state.validations.filter((item) => item.isValid === false);
        if (list.length > 0 || state.permits.length == 0) {
            setState((prev) => ({ ...prev, isValid: true }));
        } else {
            setState((prev) => ({ ...prev, isValid: false }));
        }
    }, [state.validations]);

    //add permits
    useEffect(() => {
        getAndSetEditInformation();
    }, []);

    //add permits
    useEffect(() => {
        handleAddNewPermit();
    }, [state.permitsAddSuccess.length]);

    const getNewId = () => {
        const newId = id + 1;
        setId(newId);
        return newId;
    };

    const getAndSetEditInformation = () => {
        if (idPermit) {
            permitController.getPermitInfo(Number(idPermit)).then((res) => {
                const valids = _.cloneDeep(state.validations);
                valids.push({ id: res.permit.id, isValid: true });
                setState((prev) => ({
                    ...prev,
                    permits: [res.permit],
                    validations: valids,
                    filterData: {
                        ...prev.filterData,
                        listCompany: [{ value: res.zoneInfo.company.id, label: res.zoneInfo.company.displayName }],
                        listLocation: [{ value: res.zoneInfo.location.id, label: res.zoneInfo.location.name }],
                        listShop: [
                            {
                                value: res.zoneInfo.tenant.clientId,
                                label: res.zoneInfo.tenant.tenantName,
                            },
                        ],
                        listZone: [{ value: res.zoneInfo.zone.id, label: res.zoneInfo.zone.name }],
                    },
                    clientInfo: res.client ? res.client : undefined,
                }));

                zoneController.permitWithAllocated([res.zoneInfo.zone.id], res.permit.tenantId!).then((info) => {
                    setState((prev) => ({
                        ...prev,
                        filterData: {
                            ...prev.filterData,
                            zonesInfo: { allocatedPermits: info.allocatedPermits, maxAllocated: info.maxAllocated },
                        },
                        filter: {
                            ...prev.filter,
                            idCompany: res.zoneInfo.company.id,
                            idLocation: res.zoneInfo.location.id,
                            idShop: res.zoneInfo.tenant.clientId!,
                            idZones: [res.zoneInfo.zone.id],
                        },
                    }));
                });
            });
        }
    };

    const handleAddNewPermit = () => {
        if (state.permitsAddSuccess.length === state.permits.length && state.permits.length !== 0) {
            setIsLoading(true);
            //set all id to 0 if add new permit
            const permits: Permit[] = [];
            if (state.isEdit === false) {
                state.permitsAddSuccess.map((item) => {
                    item.id = 0;
                    permits.push(item);
                });
            } else {
                state.permitsAddSuccess.map((item) => {
                    permits.push(item);
                });
            }
            //check value permit
            permitController.checkVRNUpsert(handleConvertToCheckPermitItem(permits)).then((res) => {
                setState((p) => ({ ...p, vrnCheckingList: res }));
                const countItemValid = res.reduce(
                    (count, item) => (item.status === RawUploadPermitStatus.Valid ? (count += 1) : 0),
                    0
                );
                //show popup when permit length
                if (permits.length !== countItemValid) {
                    modalWarning.handleOpen();
                    setIsLoading(false);
                } else {
                    //show notification and back to permit
                    handleAddPermit(permits);
                }
            });
        }
    };

    const handleCancelModalConfirm = () => {
        setState((p) => ({ ...p, permitsAddSuccess: [], isAdd: false }));
        modalWarning.handleClose();
    };

    const handleConfirmAddNewPermits = () => {
        const list: number[] = [];
        const listAdd: Permit[] = [];
        state.vrnCheckingList.map((item) => {
            if (item.status == RawUploadPermitStatus.Valid) {
                list.push(item.index - 1);
            }
        });
        if (list.length > 0) {
            list.map((item) => {
                listAdd.push(state.permitsAddSuccess[item]);
            });
        }
        handleAddPermit(listAdd);
    };

    const handleAddPermit = (permits: Permit[]) => {
        setIsLoading(true);
        permitController
            .Upsert(permits)
            .then((res) => {
                const filter = _.cloneDeep(state.filter);
                const filterData = _.cloneDeep(state.filterData);
                //get zone info
                zoneController.permitWithAllocated(filter.idZones, filter.idShop).then((info) => {
                    //show notification and back to permit
                    toast.success(t('notification:successfully'));
                    //clone default filter and keep filter data
                    setState({
                        ...defaultFilter,
                        filter: filter,
                        filterData: { ...filterData, zonesInfo: info },
                        isAdd: false,
                        isValid: false,
                    });
                    // //reload permits data
                    reloadPermitsData();
                    // //set loading
                    setIsLoading(false);
                    //return to permits page
                    navigate(`/permits`);
                });
            })
            .catch((res) => {
                toast.error(res.response.data.value);
                setState((p) => ({ ...p, permitsAddSuccess: [], isAdd: false }));
                setIsLoading(false);
            });
    };

    const handleConvertToCheckPermitItem = (permits: Permit[]) => {
        const arr: CheckingVRNItem[] = [];
        permits.map((permit, index) => {
            const startTimeParse = convertHour.SecondsToHoursMinStringFormat(permit.permitRecurring[0].fromHour);

            const endTimeParse = convertHour.SecondsToHoursMinStringFormat(permit.permitRecurring[0].toHour);
            arr.push({
                zoneId: state.filter.idZones[0],
                index: index + 1,
                firstName: permit.firstName,
                lastName: permit.lastName,
                vrn: permit.vrnNumber,
                recurringType: Number(permit.permitRecurring[0].recurringType),
                permitType: permit.permitType,
                startDate: moment(permit.validateFrom!).format('DD/MM/YYYY'),
                endDate: permit.validateTo ? moment(permit.validateTo!).format('DD/MM/YYYY') : null,
                startTime: startTimeParse ? startTimeParse : undefined,
                endTime: endTimeParse ? endTimeParse : undefined,
                recurringEvery: permit.permitRecurring[0].recurringEvery,
                tenantId: state.filter.idShop,
                bayNumber: 1,
                comment: '',
                weekRecurringDays:
                    permit.permitRecurring[0].recurringType == RecurringType.week
                        ? getWeeksDay(permit.permitRecurring[0].permitRecurringDays)
                        : [],
                id: state.isEdit ? permit.id : 0,
            });
        });

        return arr;
    };

    const getWeeksDay = (arr: PermitRecurringDay[]) => {
        const num: number[] = [];
        arr.map((item) => {
            num.push(moment(item.firstExecuteAt!).weekday());
        });
        return num;
    };

    const handleAddEmptyPermit = () => {
        if (state.filter.idZones.length > 0) {
            const arr = _.cloneDeep(state.permits);
            const valids = _.cloneDeep(state.validations);
            const newId = getNewId();
            arr.push({
                ...defaultPermit,
                tenantId: state.filter.idShop,
                id: newId,
                permitScopes: [{ id: 0, scopeType: 'zone', scopeId: state.filter.idZones[0]! }],
            });
            valids.push({ id: newId, isValid: false });
            setState((prev) => ({ ...prev, permits: arr, validations: valids }));
        }
    };

    const handleAddEmptyPermitWhenChangeZone = () => {
        if (state.filter.idZones.length > 0) {
            //check remaining permit
            if (state.filterData.zonesInfo.maxAllocated > 0) {
                const arr: Permit[] = [];
                const valids: ItemValid[] = [];
                const newId = 1;
                arr.push({
                    ...defaultPermit,
                    tenantId: state.filter.idShop,
                    id: newId,
                    permitScopes: [{ id: 0, scopeType: 'zone', scopeId: state.filter.idZones[0]! }],
                });
                valids.push({ id: newId, isValid: false });
                setTimeout(() => {
                    setState((prev) => ({ ...prev, permits: arr, validations: valids }));
                    setId(1);
                }, 1);
            } else {
                setState((prev) => ({ ...prev, permits: [], validations: [] }));
            }
        }
    };

    const handleDuplicatePermit = (permit: Permit, isValid: boolean) => {
        if (state.permits.length < 10) {
            const newId = getNewId();
            permit.id = newId;
            const arr = _.cloneDeep(state.permits);
            const valids = _.cloneDeep(state.validations);
            valids.push({ id: newId, isValid: false });
            arr.push(permit);
            setState((prev) => ({ ...prev, permits: arr, validations: valids }));
        } else {
            toast.error(t('notification:YouHaveReachedTheMaximumNumberOfPermit'));
        }
    };

    const handleRemovePermit = (id: number) => {
        const arr = state.permits.filter((item) => item.id !== id);
        const validates = state.validations.filter((item) => item.id !== id);
        setState((prev) => ({ ...prev, permits: arr, validations: validates }));
    };

    const resetAllPermits = () => {
        setState((prev) => ({ ...prev, permits: [], validations: [], permitsAddSuccess: [] }));
        setId(0);
    };
    return {
        t,
        setState,
        handleRemovePermit,
        handleAddEmptyPermit,
        handleDuplicatePermit,
        handleConfirmAddNewPermits,
        isLoading,
        modalWarning,
        handleCancelModalConfirm,
        ...state,
    };
}
export const defaultRecur: PermitRecurring = {
    id: 0,
    fromHour: null,
    toHour: null,
    recurringEvery: 1,
    recurringType: 0,
    permitRecurringDays: [],
};

export const defaultPermit: Permit = {
    id: 0,
    firstName: '',
    lastName: '',
    permitType: '',
    vrnNumber: '',
    validateFrom: new Date(),
    validateToType: ValidateToType.infinite,
    validateTo: undefined,
    tenantId: 0,
    permitScopes: [],
    permitRecurring: [defaultRecur],
};

export interface CheckingVRNItem extends ExcelRawPermit {
    index: number;
    endDate: string | null;
    weekRecurringDays: number[];
    id: number;
}

export const UpsertPermitContext = createContext<ReturnType<typeof useUpsertPermit>>({} as any);
