import React from 'react';
import Box from '@mui/material/Box';
import color from '../config/Colors';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function MyTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{
                '& .MuiGrid-root': {
                    background: color.white,
                },
                border: 1,
                borderColor: color.grey300,
                borderRadius: '0 8px 8px 8px',
                overflow: 'hidden',
            }}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}
