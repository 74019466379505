import { Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import color from '../../../../../../config/Colors';
import { Permit } from '../../../../../../models/permits/Permit';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';
import { UpsertPermitItemContext } from '../hooks/useUpsertPermitItem';

type Props = {
    permit: Permit;
};

export default function UpsertPermitItemSuggestion(props: Props) {
    const { t } = useContext(UpsertPermitContext);
    const { setItemState, itemState, formik, values, setValues } = useContext(UpsertPermitItemContext);

    const listSuggest: string[] = [
        t('permits:text.blueBadge'),
        t('permits:text.students'),
        t('permits:text.contractors'),
        t('permits:text.residential'),
        t('permits:text.visitor'),
    ];
    return (
        <Grid item container direction="row" mt={'5px'}>
            <Grid>
                <Typography variant="body2" fontWeight={500} color={color.grey600} component="span">
                    {t('permits:text.suggest')}:
                </Typography>
            </Grid>
            {listSuggest.map((item, index) => {
                const isLast = index === listSuggest.length - 1;
                return (
                    <Grid key={index}>
                        <Typography
                            variant="body2"
                            color={color.grey600}
                            component="span"
                            sx={{
                                '&:hover': {
                                    textDecorationLine: 'underline',
                                    color: color.secondary,
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() =>
                                // setItemState((prev) => ({
                                //     ...prev,
                                //     permit: { ...prev.permit, permitType: item },
                                // }))
                                setValues((prev) => ({ ...prev, permitType: item }))
                            }
                        >
                            {`\u00A0${item}${isLast ? '' : ','}`}
                        </Typography>{' '}
                    </Grid>
                );
            })}
        </Grid>
    );
}
