import { Box } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { FiCheckCircle } from 'react-icons/fi';
import { ImCancelCircle } from 'react-icons/im';
import color from '../config/Colors';

export default function Notify() {
    return (
        <Toaster
            position="bottom-right"
            gutter={1}
            reverseOrder={true}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                className: '',
                duration: 3000,
                style: {
                    display: 'flex',
                    width: '350px',
                    borderRadius: '5px',
                    boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)s',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    zIndex: 99,
                    marginBottom: '20px',
                    padding: '10px',
                    color: '#FFFFFF',
                },
                success: {
                    duration: 3000,
                    style: {
                        padding: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        background: color.success,
                        // border: '1px solid #7AC131',
                        '&': {
                            justifyContent: 'flex-start',
                        },
                    },
                    icon: <FiCheckCircle color="#FFFFFF" fontSize={'30px'} />,
                },
                error: {
                    duration: 3000,
                    style: {
                        background: '#EF1320',
                        border: '1px solid #EF1320',
                        padding: '12px',
                    },
                    icon: (
                        <Box
                            sx={{
                                backgroundColor: '#EF1320',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ImCancelCircle color="#FFFFFF" fontSize={'30px'} />
                        </Box>
                    ),
                },
            }}
        />
    );
}
