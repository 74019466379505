import GetBooleanFromEnv from '../helpers/GetBooleanFromEnv';

interface IConfig {
    gateway: {
        clientPortalAPI: string;
        siteKeyCaptcha: string;
        googMapSiteKey: string;
        apiAzureMedia: string;
    };
    flagFeatures: {
        isEnableReportPCNs: boolean;
        isEnableManageCompany: boolean;
        isEnableManagePermits: boolean;
        isEnableMultiLanguage: boolean;
        isEnableGrossRevenue: boolean
    };
    site: string;
}

export const appConfig: IConfig = {
    gateway: {
        clientPortalAPI: process.env.REACT_APP_CLIENT_PORTAL_API!,
        siteKeyCaptcha: process.env.REACT_APP_SITE_KEY_CAPTCHA!,
        googMapSiteKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
        apiAzureMedia: process.env.REACT_APP_AZURE_MEDIA!,
    },
    flagFeatures: {
        isEnableReportPCNs: GetBooleanFromEnv(process.env.REACT_APP_IS_ENABLE_PCN_REPORT!),
        isEnableManageCompany: GetBooleanFromEnv(process.env.REACT_APP_IS_ENABLE_MANAGE_COMPANY!),
        isEnableManagePermits: GetBooleanFromEnv(process.env.REACT_APP_IS_ENABLE_MANAGE_PERMIT!),
        isEnableMultiLanguage: GetBooleanFromEnv(process.env.REACT_APP_IS_ENABLE_MULTI_LANGUAGE!),
        isEnableGrossRevenue: GetBooleanFromEnv(process.env.REACT_APP_IS_ENABLE_GROSS_REVENUE!)
    },
    site: process.env.REACT_APP_SITE!,
};

export type Site = 'ukpc' | 'intelliPark';

// const localConfig: IConfig = {
//     gateway: {
//         // apiAccount: "http://192.168.1.227:5002/api",
//         // apiAccount: 'https://api-account-dev-intellipark.azurewebsites.net/api',
//         clientPortalAPI: "http://192.168.1.226:7177/api",
//         siteKeyCaptcha: process.env.REACT_APP_SITE_KEY_CAPTCHA!,
//         googMapSiteKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
//         apiAzureMedia: 'http://192.168.1.226:5000/api/Account/Avatar',
//         permits: "http://192.168.1.226:7177/api/Permit/GetPermits"
//     },
// };

// const prodConfig: IConfig = {
//     gateway: {
//         clientPortalAPI: '',
//         siteKeyCaptcha: process.env.REACT_APP_SITE_KEY_CAPTCHA!,
//         googMapSiteKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
//         apiAzureMedia: 'https://intelliparkdev.blob.core.windows.net/api/Account/Avatar',
//         permits: "http://0.0.0.0:7177/api/Permit/GetPermits"
//     },
// };
// const env = process.env.REACT_APP_NODE_ENV;

// console.log(env == 'development' ? devConfig : env == 'production' ? prodConfig : localConfig);
