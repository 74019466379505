import { Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import MyBreadcrumbs from '../../../components/BreadCrumbs';
import MyModalLarge from '../../../components/modal/MyModalLarge';
import { LanguageContext } from '../../../contexts/useLanguage';
import Footer from '../../dashboardLayout/components/Footer';
import ModalCheckVRNUpsert from './components/ModalCheckVRNUpsert';
import UpsertPermitActions from './components/UpsertPermitActions';
import UpsertPermitFilter from './components/UpsertPermitFilter';
import UpsertPermitInfo from './components/UpsertPermitInfo';
import UpsertPermitItems from './components/UpsertPermitItems';
import useUpsertPermit, { UpsertPermitContext } from './hooks/useUpsertPermit';

export default function UpsertPermit() {
    const { t } = useContext(LanguageContext);
    const upsertPermitData = useUpsertPermit();
    const { isEdit, filter, modalWarning, vrnCheckingList, handleConfirmAddNewPermits, handleCancelModalConfirm } =
        upsertPermitData;

    return (
        <UpsertPermitContext.Provider value={upsertPermitData}>
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                <MyModalLarge
                    key={1}
                    open={modalWarning.open}
                    handleOpen={modalWarning.handleOpen}
                    handleClose={handleCancelModalConfirm}
                    modalContent={
                        <ModalCheckVRNUpsert
                            handleClose={handleCancelModalConfirm}
                            vrnCheckingList={vrnCheckingList}
                            handleConFirm={handleConfirmAddNewPermits}
                        />
                    }
                />
                <Typography variant="h3" fontWeight={400}>
                    {isEdit ? t('permits:title.editPermit') : t('permits:button.addPermits')}
                </Typography>
                <Stack my={2}>
                    <MyBreadcrumbs />
                </Stack>
                <Grid container>
                    <Grid item xs={12} mt={2}>
                        <UpsertPermitFilter />
                    </Grid>
                    {/* {filter.idZones.length > 0 ? (
                        <Grid item xs={12} mt={3}>
                            <UpsertPermitInfo />
                        </Grid>
                    ) : (
                        <></>
                    )} */}

                    <Grid item mt={3} container>
                        <UpsertPermitItems />
                    </Grid>
                    <Grid item container>
                        <UpsertPermitActions />
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </UpsertPermitContext.Provider>
    );
}
