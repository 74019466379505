import moment from 'moment';

export function ArrayPagination(items: any[], current_page: number, per_page_items: number) {
    let page = current_page || 1,
        per_page = per_page_items || 10,
        offset = (page - 1) * per_page,
        paginatedItems = items.slice(offset).slice(0, per_page_items),
        total_pages = Math.ceil(items.length / per_page);

    return {
        page: page,
        perPage: per_page,
        total: items.length,
        totalPage: total_pages,
        data: paginatedItems,
    };
}
export function generateRandom(min = 0, max = 100) {
    let difference = max - min;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + min;
    return rand;
}

export function ConvertToGMT0Time(date: Date) {
    const currentGMT = new Date().getTimezoneOffset() / -60;
    return moment(date).add(currentGMT, 'hours').toDate();
}
