import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Account, User } from '../models/Account';
export class UserController extends BaseHttpController<User> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'User', client);
    }

    async getMe(): Promise<Account> {
        const res = await this.doGet({ path: 'GetMe', config: {} });
        // res.data.label = 'Tenant'
        return res.data;
    }
}
// export type AccountLabel = 'Account manager' | 'Managing agent' | 'client' | 'employee' | 'Tenant'
