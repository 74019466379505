export interface ColorConfig {
    primary: string
    secondary: string

    textPrimary: string
    textSecondary: string
    priText: string

    lightPrimary: string
    darkPrimary: string
    lightSecondary: string
    darkSecondary: string

    danger: string
    darkDanger: string

    warning: string
    success: string
    error: string

    appBarColor: string,

    white: '#FFFFFF'
    grey100: '#FAFAFA'
    grey200: '#EEEEEE'
    grey300: '#DDDDDD'
    grey400: '#C5C5C5'
    grey600: '#85858A'

    outlinedButton: string,
    containedButtonBg: string,
    containedButtonString: string,
    pink: '#E81F76',
    secondaryNew: String
}

const color: ColorConfig = {
    primary: process.env.REACT_APP_COLOR_PRIMARY!,
    secondary: process.env.REACT_APP_COLOR_SECONDARY!,

    textPrimary: '#000000',
    textSecondary: '#101010',
    priText: process.env.REACT_APP_COLOR_PRI_TEXT!,

    lightPrimary: process.env.REACT_APP_COLOR_LIGHT_PRIMARY!,
    darkPrimary: process.env.REACT_APP_COLOR_DARK_PRIMARY!,
    lightSecondary: process.env.REACT_APP_COLOR_LIGHT_SECONDARY!,
    darkSecondary: process.env.REACT_APP_COLOR_DARK_SECONDARY!,

    danger: '#E01B00',
    darkDanger: '#BE1700',

    warning: '#3F95E7',
    success: '#009D4F',
    error: '#E9645B',

    appBarColor: process.env.REACT_APP_COLOR_APPBAR!,

    white: '#FFFFFF',
    grey100: '#FAFAFA',
    grey200: '#EEEEEE',
    grey300: '#DDDDDD',
    grey400: '#C5C5C5',
    grey600: '#85858A',

    outlinedButton: process.env.REACT_APP_COLOR_OUTLINED_BUTTON!,
    containedButtonBg: process.env.REACT_APP_COLOR_CONTAINED_BUTTON_BG!,
    containedButtonString: process.env.REACT_APP_COLOR_CONTAINED_BUTTON_TEXT!,
    pink: '#E81F76',
    secondaryNew: '#CC0066'
}


export default color;
