import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../../../config/Colors';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { permitController } from '../../../../controllers';
import { BlobFile } from '../../../../helpers/BlobFileHepler';
import { UpsertFilter, UpsertFilterData } from '../../addEditPermit/hooks/useUpsertPermit';
import { GridUploadBorder } from './UploadBase';

type Props = {
    content?: JSX.Element;
    filterData: UpsertFilterData;
    filter: UpsertFilter;
    isLoadingDownload: boolean;
    setIsLoadingProgress: (val: boolean) => void;
};

export default function UploadBorderStep({
    content,
    filterData,
    filter,
    isLoadingDownload,
    setIsLoadingProgress,
}: Props) {
    const { t } = useContext(LanguageContext);

    const handleDownloadExample = async () => {
        if (isLoadingDownload == false) {
            setIsLoadingProgress(true);
            const nameFile = `${t('permits:upload.sampleSheet')}.xlsx`;
            await permitController
                .DownloadExampleSheet()
                .then((res) => {
                    BlobFile(res, nameFile);
                })
                .finally(() => {
                    setIsLoadingProgress(false);
                });
        }
    };
    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px 0px',
            }}
        >
            <GridUploadBorder container mt={1}>
                {content}
            </GridUploadBorder>
            <Typography variant="body2" component="div" padding={'0 15px'} textAlign="center" mt={2}>
                <span style={{ fontWeight: 500 }}>{t('permits:upload.note')}: </span>
                <span>{t('permits:upload.dataMustBeInSameFormatAsPerTheSample')} </span>
                <Typography component="span" variant="body2" sx={{ color: color.priText }}>
                    {t('permits:upload.downloadSampleFormat')}:{' '}
                </Typography>
                <Typography
                    component="span"
                    variant="body2"
                    sx={{
                        color: color.priText,
                        textDecoration: 'underline',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    onClick={handleDownloadExample}
                >
                    {t('permits:upload.sampleSheet')}.{' '}
                    {isLoadingDownload ? (
                        <CircularProgress size={8} sx={{ margin: 'auto', color: color.priText }} />
                    ) : (
                        <></>
                    )}
                </Typography>
            </Typography>
        </Grid>
    );
}
