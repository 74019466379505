/* eslint-disable eqeqeq */
import { Button, Checkbox, Container, FormControlLabel, Grid, Pagination, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { LanguageContext } from '../../contexts/useLanguage';
import Footer from '../dashboardLayout/components/Footer';
import usePermits, { PermitContext } from './hooks/usePermits';
import CheckBoxIcon from '../../assets/images/CheckBoxIcon';
import CheckedIcon from '../../assets/images/CheckedIcon';
import color from '../../config/Colors';
import LoadingCircular from '../../components/LoadingCircular';
import NoDataToDisplay from '../../components/NoDataToDisplay';
import PermitItem from './components/PermitItem';
import { formatNumber } from '../../helpers/FormatCurrency';
import useMedia from '../../hooks/useMedia';
import { useNavigate } from 'react-router-dom';
import { LocalStore } from '../../helpers/localStorage';
import ConfirmDialogLarge from '../../components/ConfirmDialogLarge';
import useScrollToTop from '../../hooks/useScrollToTop';
import UseAuth from '../../hooks/useAuth';

export default function ListPermit() {
    const { t } = useContext(LanguageContext);
    const permitContext = usePermits();
    const { isMobileSM } = useMedia();
    const navigate = useNavigate();
    const {
        FilterComponents,
        permitPagination,
        isLoading,
        handleChangePagination,
        onCheckedAll,
        isCheckedAll,
        deleteIds,
        stateDialogBulksDelete,
        stateDialogDelete,
        handleOpenConfirmBulksDelete,
        handleDelete,
        handleBulksDelete,
    } = permitContext;
    const { account } = UseAuth();
    const language = LocalStore.getValue('iHubLanguage');

    useScrollToTop();

    return (
        <PermitContext.Provider value={permitContext}>
            <ConfirmDialogLarge
                open={stateDialogDelete.open}
                title={t('action:confirm')}
                content={<div>{t('permits:text.deletingThisPermitWillAlsoDeleteThePermits')}</div>}
                onClose={stateDialogDelete.handleClose}
                onConfirm={handleDelete}
            />
            <ConfirmDialogLarge
                open={stateDialogBulksDelete.open}
                title={t('action:confirm')}
                content={t('permits:text.pleaseNoteAnyPermitsCreatedWithinTheLastHour')}
                onClose={stateDialogBulksDelete.handleClose}
                onConfirm={handleBulksDelete}
            />
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                <Stack flex={1} direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'}>
                    <Typography variant="h3" fontWeight={400}>
                        {t('permits:title.permits')}
                    </Typography>
                    <Stack direction={'row'} justifyContent="space-between" mt={{ xs: 1, sm: 0 }}>
                        <Button
                            variant="outlined"
                            sx={{ minWidth: '145px', display: account?.label == 'Account manager' ? 'block' : 'none' }}
                            onClick={() => {
                                navigate(`upload-permits`);
                            }}
                        >
                            {t('permits:title.uploadPermits')}
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ minWidth: '145px', ml: 1 }}
                            onClick={() => {
                                navigate(`add-permits`);
                            }}
                        >
                            {t('permits:button.addPermits')}
                        </Button>
                    </Stack>
                </Stack>
                <Grid container item gap={{ xs: 2, sm: 2, md: 2 }} mt={2}>
                    <Grid item xs={12}>
                        {FilterComponents}
                    </Grid>
                    <Grid item flexDirection={'row'} xs={12} mt={isMobileSM ? -1 : 2}>
                        <Stack
                            flexDirection={isMobileSM ? 'column-reverse' : 'row'}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Stack
                                flexDirection={'row'}
                                alignItems="center"
                                justifyContent={isMobileSM ? 'space-between' : ''}
                                width={isMobileSM ? '100%' : ''}
                                mt={isMobileSM ? 2 : ''}
                            >
                                <FormControlLabel
                                    sx={{ ml: 0 }}
                                    control={
                                        <Checkbox
                                            sx={{ padding: '0px', mr: '5px' }}
                                            size="small"
                                            icon={<CheckBoxIcon style={{ width: '22px', height: '22px' }} />}
                                            checkedIcon={<CheckedIcon style={{ width: '22px', height: '22px' }} />}
                                            onChange={onCheckedAll}
                                            // defaultChecked={false}
                                            checked={isCheckedAll()}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body1"
                                            sx={{ width: language && language === 'en' ? '70px' : '110px' }}
                                        >
                                            {t('permits:text.selectAll')}
                                        </Typography>
                                    }
                                />
                                <Button
                                    variant="errorOutlined"
                                    size="small"
                                    sx={{ padding: '4px 16px', lineHeight: 'none', minWidth: '120px' }}
                                    onClick={deleteIds.length !== 0 ? handleOpenConfirmBulksDelete : () => {}}
                                >
                                    {t('action:delete')} ({deleteIds.length})
                                </Button>
                            </Stack>
                            <Typography variant="body1" color={color.textPrimary}>
                                {t('permits:text.totalResults')}:{' '}
                                {(permitPagination &&
                                    permitPagination.total &&
                                    formatNumber.format(permitPagination.total)) ||
                                    0}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item container gap={2}>
                        {isLoading ? (
                            <LoadingCircular />
                        ) : permitPagination && permitPagination.data && permitPagination.data.length > 0 ? (
                            <>
                                <Grid item container gap={2}>
                                    {permitPagination.data.map((permit) => {
                                        return <PermitItem permit={permit} key={permit.id} />;
                                    })}

                                    <Grid
                                        item
                                        container
                                        display={'flex'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        xs={12}
                                        sx={{ mt: 2 }}
                                    >
                                        <Pagination
                                            count={permitContext.permitPagination.totalPage}
                                            page={permitContext.permitPagination.page}
                                            onChange={handleChangePagination}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                        )}
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </PermitContext.Provider>
    );
}
