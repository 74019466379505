import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Chip, Menu, MenuItem } from '@mui/material';
import { CalendarPicker } from '@mui/x-date-pickers';
import color from '../../../config/Colors';
import { formatDate } from '../../../helpers/moment';
import ChipDeleteIcon from '../../../assets/images/ChipDeleteIcon';
import { AdvancedName } from '../hooks/usePermitsFilter';
import { t } from 'i18next';

type Props = {
    title: string;
    value: Date | undefined;
    minDate?: Date | undefined;
    setDate: (date: Date) => void;
    handleRemove: (advancedName: AdvancedName) => void;
    advancedName: AdvancedName;
};

export default function AdvancedDate(props: Props) {
    const [date, setDate] = React.useState<Date | undefined>(props.value);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setDate(props.value);
    }, [props.value]);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Chip
                    label={`${props.title}: ${
                        date === undefined ? t('permits:text.select') : formatDate.getLocalDay(date)
                    }`}
                    variant="outlined"
                    onDelete={() => {
                        props.handleRemove(props.advancedName);
                    }}
                    sx={[
                        anchorEl !== null
                            ? {
                                  background: 'black',
                                  '& .svg-icon-stroke': {
                                      stroke: 'white',
                                  },
                                  color: 'white',
                              }
                            : {},
                        { '& svg': { mr: '5px' } },
                    ]}
                    deleteIcon={<ChipDeleteIcon />}
                    onClick={handleClick}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        border: `1px solid ${color.grey300}`,
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                background: 'none',
                            },
                        },
                        mt: 1.5,
                    },
                }}
                MenuListProps={{
                    sx: {
                        padding: 0,
                        transition: 'none !important',
                        '&:focus': {
                            backgroundColor: '#FFF',
                        },
                    },
                }}
                // transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                // anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            >
                <MenuItem sx={{ padding: 0 }} onClick={(event) => event.stopPropagation()} disableRipple>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CalendarPicker
                            date={date}
                            onChange={(newDate) => {
                                handleClose();
                                props.setDate(newDate!);
                                setDate(newDate!);
                            }}
                            minDate={props.advancedName == 'validTo' ? props.minDate : undefined}
                        />
                    </LocalizationProvider>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
