import { Accordion, AccordionDetails, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { UpsertPermitContext } from '../../hooks/useUpsertPermit';
import { Permit } from '../../../../../models/permits/Permit';
import UpsertPermitItemSuggestion from './components/UpsertPermitItemSuggestion';
import UpsertPermitItemTitle from './components/UpsertPermitItemTitle';
import useUpsertPermitItem, { UpsertPermitItemContext } from './hooks/useUpsertPermitItem';
import DayRecurring from '../recurringType/DayRecurring';
import MonthRecurring from '../recurringType/MonthRecurring';
import WeekRecurring from '../recurringType/WeekRecurring';
import UpsertPermitItemRecurTime from './components/UpsertPermitItemRecurTime';
import { FormikProvider } from 'formik';
import { RecurringType } from '../../../../../models/permits/PermitRecurring';
import LabelWithRequired from '../../../../../components/LabelWithRequired';
import UpsertPermitItemTitleEdit from './components/UpsertPermitItemTitleEdit';
import { AuthContext } from '../../../../../hooks/useAuth';
import color from '../../../../../config/Colors';
import PlusIcon from '../svg/PlusIcon';
import UpsertPermitComment from './components/UpsertPermitComment';
import useMedia from '../../../../../hooks/useMedia';

type Props = {
    permit: Permit;
};

export default function UpsertPermitItem(props: Props) {
    const [isEnplaned, setEnplaned] = useState<boolean>(true);
    const { t, isEdit } = useContext(UpsertPermitContext);
    const upsertPermitItemData = useUpsertPermitItem({ permit: props.permit });
    const { permit, recurSelect, errors, touched, getFieldProps, formik, values } = upsertPermitItemData;
    const { isGoldRole, account } = useContext(AuthContext);
    const { isMobileSM } = useMedia();
    return (
        <FormikProvider value={formik}>
            <UpsertPermitItemContext.Provider value={upsertPermitItemData}>
                <Grid item>
                    <Stack flex={1} mt={1}>
                        <MyAccordion disableGutters elevation={0} expanded={isEnplaned}>
                            {isEdit ? (
                                <UpsertPermitItemTitleEdit permit={props.permit} />
                            ) : (
                                <UpsertPermitItemTitle
                                    permit={props.permit}
                                    onCollapse={() => setEnplaned(!isEnplaned)}
                                />
                            )}
                            <AccordionDetails
                            // sx={{
                            //     '&.MuiAccordionDetails-root': {
                            //         padding: '0px !important',
                            //     },
                            // }}
                            >
                                <Grid
                                    container
                                    sx={{
                                        '& .MuiFormHelperText-root': {
                                            position: 'absolute',
                                            top: '35px',
                                            left: '-15px',
                                            fontSize: '12px',
                                        },
                                    }}
                                    // p={2}
                                >
                                    <Grid
                                        item
                                        container
                                        spacing={Boolean(touched.vrnNumber && errors.vrnNumber) ? 3 : 2}
                                    >
                                        <Grid item xs={isMobileSM ? 12 : 4}>
                                            <TextField
                                                label={<LabelWithRequired label={t('permits:text.VRN')} />}
                                                placeholder={t('permits:text.enterVRN')}
                                                disabled={isEdit}
                                                fullWidth
                                                size="small"
                                                {...getFieldProps('vrnNumber')}
                                                error={Boolean(touched.vrnNumber && errors.vrnNumber)}
                                                helperText={touched.vrnNumber && errors.vrnNumber}
                                                onChange={formik.handleChange}
                                                value={formik.values.vrnNumber}
                                                inputProps={{
                                                    maxLength: 20,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={isMobileSM ? 12 : 4}>
                                            <TextField
                                                label={<LabelWithRequired label={t('accountPage:text.firstName')} />}
                                                placeholder={t('input:enterFirstName')}
                                                disabled={isEdit}
                                                fullWidth
                                                {...getFieldProps('firstName')}
                                                error={Boolean(touched.firstName && errors.firstName)}
                                                helperText={touched.firstName && errors.firstName}
                                                onChange={formik.handleChange}
                                                value={formik.values.firstName}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={isMobileSM ? 12 : 4}>
                                            <TextField
                                                label={<LabelWithRequired label={t('accountPage:text.lastName')} />}
                                                placeholder={t('input:enterLastName')}
                                                disabled={isEdit}
                                                fullWidth
                                                {...getFieldProps('lastName')}
                                                error={Boolean(touched.lastName && errors.lastName)}
                                                helperText={touched.lastName && errors.lastName}
                                                onChange={formik.handleChange}
                                                value={formik.values.lastName}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2} mt={1}>
                                        <Grid item xs={account?.label == 'Account manager' ? 8 : 12}>
                                            <TextField
                                                label={<LabelWithRequired label={t('permits:text.permitType')} />}
                                                placeholder={t('input:enterPermitType')}
                                                {...getFieldProps('permitType')}
                                                error={Boolean(touched.permitType && errors.permitType)}
                                                helperText={touched.permitType && errors.permitType}
                                                onChange={formik.handleChange}
                                                value={formik.values.permitType}
                                                inputProps={{
                                                    maxLength: 100,
                                                }}
                                                fullWidth
                                                size="small"
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        top: '55px !important',
                                                    },
                                                }}
                                            />
                                            <UpsertPermitItemSuggestion permit={permit} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sx={{ display: account?.label == 'Account manager' ? 'block' : 'none' }}
                                        >
                                            <TextField
                                                label={t('permits:text.bayNo')}
                                                {...getFieldProps('bayNumber')}
                                                onChange={formik.handleChange}
                                                value={formik.values.bayNumber ? formik.values.bayNumber : ''}
                                                placeholder={t('permits:text.enterBayNumber')}
                                                type={'number'}
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                }}
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        sm={12}
                                        mt={1}
                                        display="flex"
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Grid
                                            item
                                            xs={
                                                permit.permitRecurring[0].recurringType === RecurringType.indefinite ||
                                                permit.permitRecurring[0].recurringType === RecurringType.once
                                                    ? 12
                                                    : 12
                                            }
                                            sm={
                                                permit.permitRecurring[0].recurringType === RecurringType.indefinite ||
                                                permit.permitRecurring[0].recurringType === RecurringType.once
                                                    ? 12
                                                    : 4
                                            }
                                        >
                                            {recurSelect.RenderSelect}
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={8}
                                            display={
                                                permit.permitRecurring[0].recurringType === RecurringType.indefinite ||
                                                permit.permitRecurring[0].recurringType === RecurringType.once
                                                    ? 'none'
                                                    : 'block'
                                            }
                                        >
                                            {permit.permitRecurring[0].recurringType === RecurringType.day ? (
                                                <DayRecurring />
                                            ) : permit.permitRecurring[0].recurringType === RecurringType.month ? (
                                                <MonthRecurring />
                                            ) : permit.permitRecurring[0].recurringType === RecurringType.week ? (
                                                <WeekRecurring />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                    </Grid>
                                    {permit.permitRecurring[0].recurringType !== RecurringType.indefinite ? (
                                        <UpsertPermitItemRecurTime />
                                    ) : (
                                        ''
                                    )}

                                    <UpsertPermitComment />
                                </Grid>
                            </AccordionDetails>
                        </MyAccordion>
                    </Stack>
                </Grid>
            </UpsertPermitItemContext.Provider>
        </FormikProvider>
    );
}

export const MyAccordion = styled(Accordion)({
    '&.MuiPaper-root': {
        border: `1px solid #DDDDDD`,
        boxShadow: `none`,
    },
});
