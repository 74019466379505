import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AdvancedFilterOptions } from '../../../models/permits/FilterPermits';
import { AdvancedName, StateFilter } from '../hooks/usePermitsFilter';
import AdvancedDate from './AdvancedDate';
import AdvancedFilterControl from './AdvancedFilterControl';
import AdvancedPermitType from './AdvancedPermitType';
import AdvancedRecurType from './AdvancedRecurType';
import AdvancedStatus from './AdvancedStatus';

type Props = {
    state: StateFilter;
    setState: React.Dispatch<React.SetStateAction<StateFilter>>;
    handleRemoveAdvancedFilter: (name: AdvancedName) => void;
    setShowingAdvancedFilter: (advancedFilter: AdvancedFilterOptions) => void;
};

export default function AdvancedFilterLarge({
    state,
    setState,
    handleRemoveAdvancedFilter,
    setShowingAdvancedFilter,
}: Props) {
    const { t } = useContext(LanguageContext);
    return (
        <Grid item container display="flex" flexDirection={'row'} spacing={1}>
            {state.advancedFilter.isShowPermitType ? (
                <Grid item>
                    <AdvancedPermitType
                        value={state.filter.filter?.permitType!}
                        permitTypes={state.listPermitType}
                        setValue={(permitType) => {
                            setState((prev) => ({
                                ...prev,
                                filter: {
                                    ...prev.filter,
                                    filter: { ...prev.filter.filter!, permitType: permitType },
                                    page: 1,
                                },
                            }));
                        }}
                        handleRemove={handleRemoveAdvancedFilter}
                    />
                </Grid>
            ) : (
                ''
            )}
            {state.advancedFilter.isShowStatus ? (
                <Grid item>
                    <AdvancedStatus
                        value={state.filter.filter!.status!}
                        handleRemove={handleRemoveAdvancedFilter}
                        setStatus={(status) => {
                            setState((prev) => ({
                                ...prev,
                                filter: { ...prev.filter, filter: { ...prev.filter.filter!, status: status }, page: 1 },
                            }));
                        }}
                    />
                </Grid>
            ) : (
                ''
            )}
            {state.advancedFilter.isShowRecurringType ? (
                <Grid item>
                    <AdvancedRecurType
                        value={state.filter.filter!.recurringType}
                        handleRemove={handleRemoveAdvancedFilter}
                        setStatus={(type) => {
                            setState((prev) => ({
                                ...prev,
                                filter: {
                                    ...prev.filter,
                                    filter: { ...prev.filter.filter!, recurringType: type },
                                    page: 1,
                                },
                            }));
                        }}
                    />
                </Grid>
            ) : (
                ''
            )}
            {state.advancedFilter.isShowFrom ? (
                <Grid item>
                    <AdvancedDate
                        title={t('permits:text.validFrom')}
                        advancedName={'validFrom'}
                        value={state.filter.filter?.validFrom!}
                        setDate={(date) => {
                            setState((prev) => ({
                                ...prev,
                                filter: {
                                    ...prev.filter,
                                    filter: { ...prev.filter.filter!, validFrom: date, validTo: undefined },
                                    page: 1,
                                },
                            }));
                        }}
                        handleRemove={handleRemoveAdvancedFilter}
                    />
                </Grid>
            ) : (
                ''
            )}
            {state.advancedFilter.isShowTo ? (
                <Grid item>
                    <AdvancedDate
                        title={t('permits:text.validTo')}
                        value={state.filter.filter!.validTo!}
                        advancedName={'validTo'}
                        minDate={state.filter.filter?.validFrom!}
                        setDate={(date) => {
                            setState((prev) => ({
                                ...prev,
                                filter: { ...prev.filter, filter: { ...prev.filter.filter!, validTo: date }, page: 1 },
                            }));
                        }}
                        handleRemove={handleRemoveAdvancedFilter}
                    />
                </Grid>
            ) : (
                ''
            )}
            <Grid item>
                <AdvancedFilterControl
                    setShowingAdvancedFilter={setShowingAdvancedFilter}
                    advancedFilterItems={state.advancedFilter}
                />
            </Grid>
        </Grid>
    );
}
