import { Stack, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import color from '../../../../../config/Colors';
import { UpsertPermitItemContext } from '../UpsertPermitItem/hooks/useUpsertPermitItem';

export default function DayRecurring() {
    const { t, permit, getRecurEveryNumber, setRecurEveryNumber } = useContext(UpsertPermitItemContext);
    return (
        <Stack
            flex={1}
            direction={'row'}
            padding={'16px 8px'}
            sx={{ background: color.grey100, borderRadius: '5px' }}
            alignItems="center"
        >
            <Typography variant="body1" component={'span'}>
                {t('permits:text.recurEvery')}
            </Typography>

            <TextField
                label={t('permits:text.noOfDay')}
                placeholder={''}
                sx={{ minWidth: '100px', mx: 2 }}
                type="number"
                size="small"
                inputProps={{ min: 1, max: 31 }}
                value={getRecurEveryNumber()}
                onChange={(e) => {
                    const value = Number(e.target!.value);
                    const val = value < 1 ? 1 : value > 31 ? 31 : value;
                    setRecurEveryNumber(val);
                }}
            />

            <Typography variant="body1" component={'span'}>
                {t('permits:text.days')}
            </Typography>
        </Stack>
    );
}
