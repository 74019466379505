import { Box, Checkbox, FormControlLabel, Grid, Stack, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider, TimePicker, DatePicker, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import CheckedIcon from '../../../../../../assets/images/CheckedIcon';
import UncheckedIcon from '../../../../../../assets/images/UncheckedIcon';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import color from '../../../../../../config/Colors';
import useMedia from '../../../../../../hooks/useMedia';
import { UpsertPermitItemContext } from '../hooks/useUpsertPermitItem';
import { addDays, subDays } from 'date-fns';
import { RecurringType } from '../../../../../../models/permits/PermitRecurring';
import CalendarIcon from '../../../../../../assets/svg/CalendarIcon';
const useStyles = makeStyles((theme: Theme) => ({
    timeStyle: {
        '& .Mui-disabled': {
            color: 'red !important',
        },
    },
    inputStyle: {
        '& .Mui-selected': {
            backgroundColor: `${color.primary} !important`,
        },
    },
    checkBoxc: {
        '.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
    },
}));
const icon = <UncheckedIcon fontSize="small" />;
const checkedIcon = <CheckedIcon fontSize="small" />;
export default function UpsertPermitItemTimeDuration() {
    const classes = useStyles();
    const { t, getFieldProps, formik, touched, permit, values, setFieldTouched, errors, checkedAllDay, handleChange } =
        useContext(UpsertPermitItemContext);
    const startTime = moment(formik.values.fromHour).format('DD/MM/YYYY HH:mm');
    const endTime = moment(formik.values.toHour).format('DD/MM/YYYY HH:mm');
    const totalMinute = moment(endTime, 'DD/MM/YYYY HH:mm').diff(moment(startTime, 'DD/MM/YYYY HH:mm'), 'minutes');
    const hr = moment.duration(totalMinute, 'minutes').hours();
    const min = moment.duration(totalMinute, 'minutes').minutes();
    const duration = `${hr}hr ${min}min`;
    const { isMobileSM, isMediumMD } = useMedia();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
                mb={isMobileSM && formik.touched?.toHour && !!formik.errors?.toHour ? 0 : 2}
                xs={12}
                sm={12}
                md={12}
                item
                container
                spacing={2}
                direction="row"
                alignItems={'center'}
                mr={2}
            >
                <Grid lg={4} md={4} sm={4} xs={checkedAllDay ? 8.5 : 12} item>
                    <DatePicker
                        label={<LabelWithRequired label={t('permits:text.startDate')} />}
                        value={formik.values.validateFrom}
                        onChange={(newValue, _) => {
                            if (newValue != null) {
                                if (permit.permitRecurring[0].recurringType == RecurringType.once) {
                                    formik.setValues((prev) => ({
                                        ...prev,
                                        validateFrom: newValue,
                                        validateTo: addDays(newValue, 1),
                                    }));
                                } else {
                                    formik.setValues((prev) => ({ ...prev, validateFrom: newValue, validateTo: null }));
                                }
                            }
                        }}
                        components={{
                            OpenPickerIcon: CalendarIcon,
                        }}
                        OpenPickerButtonProps={{ className: classes.inputStyle }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...getFieldProps('validateFrom')}
                                error={Boolean(touched.validateFrom && errors.validateFrom)}
                                helperText={touched.validateFrom && errors.validateFrom}
                                fullWidth={true}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        caretColor: 'transparent',
                                    },
                                }}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        )}
                        renderDay={(day, _value, DayComponentProps) => {
                            return (
                                <Box className={classes.inputStyle}>
                                    <PickersDay {...DayComponentProps} />
                                </Box>
                            );
                        }}
                        inputFormat="dd/MM/yyyy"
                        minDate={subDays(new Date(), 2)}
                    />
                </Grid>

                <Grid
                    display={checkedAllDay ? 'none' : 'flex'}
                    md={Boolean(formik.touched.fromHour && formik.errors.fromHour) ? 3 : 2.5}
                    lg={2.5}
                    sm={4}
                    xs={6}
                    mt={{ xs: 1, sm: 0 }}
                    item
                >
                    <Stack
                        spacing={isMobileSM ? 0 : 2}
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                    >
                        <Typography display={isMobileSM ? 'none' : 'block'} variant="body1">
                            {t('reportPage:text.from')}
                        </Typography>
                        <TimePicker
                            label={<LabelWithRequired label={t('permits:text.startTime')} />}
                            value={values.fromHour}
                            onChange={(newVal, textVal) => {
                                setFieldTouched('fromHour');
                                if (newVal != null) {
                                    setFieldTouched('toHour');
                                    formik.setValues((prev) => ({ ...prev, fromHour: newVal, toHour: null }));
                                }
                            }}
                            ampm={false}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...getFieldProps('fromHour')}
                                    error={Boolean(formik.touched.fromHour && formik.errors.fromHour)}
                                    helperText={formik.touched.fromHour && formik.errors.fromHour}
                                    fullWidth
                                    sx={{
                                        // maxWidth: '220px',
                                        '& .MuiInputBase-input': {
                                            caretColor: 'transparent',
                                        },
                                    }}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Grid>

                <Grid
                    display={checkedAllDay ? 'none' : 'flex'}
                    lg={2.5}
                    md={Boolean(formik.touched.fromHour && formik.errors.fromHour) ? 3 : 2.5}
                    sm={4}
                    xs={6}
                    mt={{ xs: 1, sm: 0 }}
                    item
                >
                    <Stack
                        spacing={isMobileSM ? 0 : 2}
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                    >
                        <Typography display={isMobileSM ? 'none' : 'block'} variant="body1">
                            {t('reportPage:text.to')}
                        </Typography>
                        <TimePicker
                            ampm={false}
                            label={<LabelWithRequired label={t('permits:text.endTime')} />}
                            value={values.toHour}
                            minTime={moment(formik.values.fromHour).add(1, 'minutes').toDate()}
                            onChange={(newVal, textVal) => {
                                setFieldTouched('toHour');
                                // formik.submitForm();
                                if (newVal != null) {
                                    setFieldTouched('toHour');
                                    formik.setFieldValue('toHour', newVal);
                                }
                            }}
                            OpenPickerButtonProps={{ className: classes.timeStyle }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...getFieldProps('toHour')}
                                    error={formik.touched?.toHour && !!formik.errors?.toHour}
                                    helperText={formik.touched?.toHour && formik.errors?.toHour}
                                    fullWidth
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            caretColor: 'transparent',
                                        },
                                    }}
                                    onKeyDown={(e) => {
                                        setFieldTouched('toHour');
                                        e.preventDefault();
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Grid>
                <Grid
                    // mt={isMobileSM && formik.touched?.toHour && !!formik.errors?.toHour ? 1.5 : 0}
                    md={Boolean(formik.touched.fromHour && formik.errors.fromHour) ? 1.5 : 3}
                    sm={5}
                    lg={3}
                    xs={checkedAllDay ? 3.5 : 12}
                    item
                >
                    <Stack
                        // spacing={checkedAllDay ? 2 : 0}
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                    >
                        <Typography
                            display={
                                checkedAllDay || !Boolean(formik.values.toHour == null || formik.values.fromHour)
                                    ? 'none'
                                    : 'flex'
                            }
                            mr={formik.values.toHour == null || formik.values.fromHour == null ? 0 : 2}
                            variant="body1"
                            color={color.priText}
                        >
                            {formik.values.toHour == null || formik.values.fromHour == null ? '' : duration || ''}
                        </Typography>
                        <FormControlLabel
                            sx={{
                                '&.MuiFormControlLabel-labelPlacementEnd': {
                                    marginRight: '0px !important',
                                },
                            }}
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={checkedAllDay}
                                />
                            }
                            label={<Typography variant="body1">{t('permits:text.allDay')}</Typography>}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}
