import React from 'react';
import { Chart as ChartJS, registerables, ChartOptions, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { StatisticItem } from '../../models/StatisticItems';
import font from '../../config/Fonts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useMedia from '../../hooks/useMedia';
import { formatNumber } from '../../helpers/FormatCurrency';
ChartJS.register(...registerables);

type Props = {
    groupPCNByZone: StatisticItem[];
};

export default function BarChartHorizontal(props: Props) {
    const { isMobileSM, isMediumMD } = useMedia();
    const options: ChartOptions<'bar'> = {
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                // hover column to show data
                enabled: false,
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'right',
                font: {
                    family: font,
                    size: 10,
                },
                padding: {
                    right: 10,
                },
                //format style label
                formatter(value, context) {
                    return formatNumber.format(value);
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    // horizontal column border
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        family: font,
                        size: 10,
                    },
                    color: '#000000',
                    count: 5,
                    stepSize: 600,
                },
            },
            y: {
                grid: {
                    display: false,
                    // vertical column border
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        family: font,
                        size: 12,
                        weight: '500',
                    },
                    color: '#000000',
                },
                // set width for label
                afterFit: function (scaleInstance) {
                    scaleInstance.width = isMobileSM || isMediumMD ? 130 : 200;
                },
            },
        },
    };

    const data: ChartData<'bar', number[], string[]> = {
        labels:
            props.groupPCNByZone &&
            props.groupPCNByZone.map((item) => formatLabel(item.label, isMobileSM || isMediumMD ? 25 : 30)),
        datasets: [
            {
                data: props.groupPCNByZone.map((item) => item.amount),
                borderColor: 'white',
                backgroundColor: '#3EB6E4',
                // distance between column
                barPercentage: 1,
                // column width
                categoryPercentage: 0.9,
                barThickness: props.groupPCNByZone.length === 1 ? 60 : 'flex',
            },
        ],
    };

    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}

function formatLabel(str: string, maxwidth: number) {
    var sections: any[] = [];
    var words = str.split(' ');
    var temp = '';

    words.forEach(function (item, index) {
        if (temp.length > 0) {
            var concat = temp + ' ' + item;

            if (concat.length > maxwidth) {
                sections.push(temp);
                temp = '';
            } else {
                if (index == words.length - 1) {
                    sections.push(concat);
                    return;
                } else {
                    temp = concat;
                    return;
                }
            }
        }

        if (index == words.length - 1) {
            sections.push(item);
            return;
        }

        if (item.length < maxwidth) {
            temp = item;
        } else {
            sections.push(item);
        }
    });

    return sections;
}
