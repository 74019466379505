import { DailyVisitsStatistic } from '../models/DailyVisitsStatistic';
import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Account } from '../models/Account';
import { Filter } from '../models/Filter';
import { AnprMatchesDailyStats, AnprMatchesDailyStatsByHours } from '../models/AnprMatchesDailyStats';
import { CapacityAnalysis } from '../models/CapacityAnalysis';
import { RepeatVisit } from '../models/RepeatVisits';
import { AverageLengthStatistic } from '../models/AverageLengthStatistic';
import { generateRandom } from '../helpers';
import { GrossRevenue } from '../models/grossRevenue/GrossRevenue';

export class AnprController extends BaseHttpController<Account> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'AnprDashboard', client);
    }

    async getDailyVisits(filter: Filter): Promise<DailyVisitsStatistic> {
        const res = await this.doPost({ path: 'DailyVisits', body: filter, config: {} });
        const dailyVisitsCount: DailyVisitsStatistic = {
            dailyData: res.data.dailyVisits,
            summaryData: {
                averageVisits: res.data.averageVisits,
                totalVisits: res.data.totalVisits,
                mostVisitedDate: res.data.mostVisited,
                smallVisitedDate: res.data.smallestVisited,
                totalVisitEV: res.data.totalVisitEV,
                averageVisitEV: res.data.averageVisitEV,
                mostVisitedDateEV: res.data.mostVisitedEV,
                smallVisitedDateEV: res.data.smallestVisitedEV,
            },
        };
        // dailyVisitsCount.dailyData.forEach((item, index) => {
        //     const total = item.visitCount
        //     const randomA = generateRandom(1, total / 4)
        //     const randomB = generateRandom(1, total / 4)
        //     const randomC = (total - (randomA + randomB)) / 2
        //     item.qtyTotalOfDiesel = randomC
        //     item.qtyTotalOfPetrol = randomC
        //     item.qtyTotalOfEV = randomA
        //     item.qtyTotalOfOther = randomB
        // });

        return dailyVisitsCount;
    }

    async getAverageLengthOfVisits(filter: Filter): Promise<AverageLengthStatistic[]> {
        const res = await this.doPost({ path: 'AverageLengthOfVisits', body: filter, config: {} });
        let obj = res.data.averageLengthOfVisits[0];
        if (!res.data.averageLengthOfVisits[0]) {
            obj = {
                sum_0_1: 0,
                sum_1_2: 0,
                sum_2_3: 0,
                sum_3_4: 0,
                sum_4_5: 0,
                sum_5_6: 0,
                sum_over_6: 0
            };
        }

        var result = Object.keys(obj).map((key) => [key, obj[key]]);

        const list = [
            '0 - 1 hour',
            '1 - 2 hours',
            '2 - 3 hours',
            '3 - 4 hours',
            '4 - 5 hours',
            '5 - 6 hours',
            'More than 6 hours',
        ];
        let averageLengthStatistics: AverageLengthStatistic[] = [];
        let total = 0;

        result.map((item, index) => {
            if (index < 7) {
                total += item[1];
            }
        });

        list.map((item, index) => {
            averageLengthStatistics.push({
                hours: item,
                averageLengthOfStay: res.data.averageLengthOfStay,
                maxDuration: res.data.maxDuration,
                minDuration: res.data.minDuration,
                count: result[index][1],
                percentage: Number(((result[index][1] / total) * 100).toFixed(1)) || 0,
            });
        });

        return averageLengthStatistics;
    }

    async getCapacityAnalysis(filter: Filter): Promise<CapacityAnalysis> {
        const res = await this.doPost({ path: 'CapacityAnalysis', body: filter, config: {} });
        const capacityAnalysis: CapacityAnalysis = {
            dailyData: res.data.capacityAnalysis,
            sumTotalBays: res.data.sumTotalBays,
        };
        const dailyData: AnprMatchesDailyStatsByHours[] = capacityAnalysis.dailyData.map((item) => {
            return {
                ...item,
                sumStayDuration_0_8:
                    item.sumStayDuration_0_1! +
                    item.sumStayDuration_1_2! +
                    item.sumStayDuration_2_3! +
                    item.sumStayDuration_3_4! +
                    item.sumStayDuration_4_5! +
                    item.sumStayDuration_5_6! +
                    item.sumStayDuration_6_7! +
                    item.sumStayDuration_7_8!,
                sumStayDuration_8_16:
                    item.sumStayDuration_8_9! +
                    item.sumStayDuration_9_10! +
                    item.sumStayDuration_10_11! +
                    item.sumStayDuration_11_12! +
                    item.sumStayDuration_12_13! +
                    item.sumStayDuration_13_14! +
                    item.sumStayDuration_14_15! +
                    item.sumStayDuration_15_16!,
                sumStayDuration_16_24:
                    item.sumStayDuration_16_17! +
                    item.sumStayDuration_17_18! +
                    item.sumStayDuration_18_19! +
                    item.sumStayDuration_19_20! +
                    item.sumStayDuration_20_21! +
                    item.sumStayDuration_21_22! +
                    item.sumStayDuration_22_23! +
                    item.sumStayDuration_23_0!,
            };
        });
        capacityAnalysis.dailyData = dailyData

        return capacityAnalysis;
    }

    async getRepeatVisits(filter: Filter) {
        const res = await this.doPost({ path: 'RepeatVisit', body: filter, config: {} });

        let obj = res.data;
        if (!res.data) {
            obj = {
                sum_0_1: 0,
                sum_1_2: 0,
                sum_2_3: 0,
                sum_3_4: 0,
                sum_4_5: 0,
                sum_5_6: 0,
                sum_over_6: 0,
            };
        }

        var result = Object.keys(obj).map((key) => [key, obj[key]]);

        const list = [1, 2, 3, 4, 5, 6, 7];
        let repeatVisits: RepeatVisit[] = [];

        let total = 0;

        result.map((item) => {
            total += item[1];
        });

        list.map((item, index) => {
            repeatVisits.push({
                visitTime: item,
                vehicleCount: result[index][1],
                percentage: Number(((result[index][1] / total) * 100).toFixed(1)) || 0,
            });
        });

        return repeatVisits;
    }

    async getGrossRevenue(filter: Filter): Promise<GrossRevenue> {
        const res = await this.doPost({ path: 'GrossRevenue', body: filter, config: {} });
        return res.data
    }
}
