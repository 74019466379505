import { Avatar, Button, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import MyModal from '../../components/modal/MyModal';
import useModal from '../../components/modal/useModal';
import color from '../../config/Colors';
import { LanguageContext } from '../../contexts/useLanguage';
import { formatDate } from '../../helpers/moment';
import { AuthContext } from '../../hooks/useAuth';
import useScrollToTop from '../../hooks/useScrollToTop';
import { GridBorderRadius } from '../dashboard/styled';
import Footer from '../dashboardLayout/components/Footer';
import { DrawerContext } from '../dashboardLayout/useDrawer';
import ChangePasswordForm from './components/ChangePasswordForm';
import LineWithTwoItem from './components/LineWithTwoItem';
import UpdateForm from './components/UpdateForm';

export default function AccountPage() {
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);
    const modalUpdate = useModal();
    const modalChangePass = useModal();
    const { account, userAvatar } = useContext(AuthContext);
    useScrollToTop();
    useEffect(() => {
        setActiveItem(4);
    }, []);

    return (
        <>
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                <MyModal
                    key={1}
                    open={modalUpdate.open}
                    handleOpen={modalUpdate.handleOpen}
                    handleClose={modalUpdate.handleClose}
                    modalContent={<UpdateForm handleClose={modalUpdate.handleClose} />}
                />
                <MyModal
                    key={2}
                    open={modalChangePass.open}
                    handleOpen={modalChangePass.handleOpen}
                    handleClose={modalChangePass.handleClose}
                    modalContent={<ChangePasswordForm handleClose={modalChangePass.handleClose} />}
                />
                <Typography variant="h3" fontWeight={400}>
                    {t('accountPage:title.myAccount')}
                </Typography>
                <GridBorderRadius container sx={{ mt: 3 }} justifyContent={'center'}>
                    <Grid item sm={8} md={5} container justifyContent={'center'} gap={2} padding={'30px 0 30px 0'}>
                        <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                            <Avatar
                                sx={{ width: '100px', height: '100px', border: `8px solid ${color.lightPrimary}` }}
                                alt={account?.firstName?.toUpperCase() || 'A'}
                                src={userAvatar}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant="body1" align="center" component={'div'}>
                                <Box
                                    sx={{
                                        width: '8px',
                                        height: '8px',
                                        borderRadius: '50%',
                                        backgroundColor: color.primary,
                                        display: 'inline-block',
                                        mr: 1,
                                    }}
                                ></Box>
                                {t('accountPage:title.active')}
                            </Typography>
                        </Grid> */}

                        <LineWithTwoItem firstItem={t('accountPage:text.firstName')} lastItem={account?.firstName} />

                        <LineWithTwoItem firstItem={t('accountPage:text.lastName')} lastItem={account?.lastName} />

                        <LineWithTwoItem firstItem={t('accountPage:text.email')} lastItem={account?.email} />

                        <LineWithTwoItem
                            firstItem={t('accountPage:text.phoneNumber')}
                            lastItem={account?.phoneNumber}
                        />

                        <LineWithTwoItem
                            firstItem={t('accountPage:text.lastLogin')}
                            lastItem={formatDate.getLocalDate(account?.lastLogin)}
                        />

                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} mt={2}>
                            <Button variant="outlined" onClick={modalChangePass.handleOpen}>
                                {t('accountPage:button.resetPassword')}
                            </Button>
                            <Button variant="contained" onClick={modalUpdate.handleOpen}>
                                {t('accountPage:button.updateAccount')}
                            </Button>
                        </Stack>
                    </Grid>
                </GridBorderRadius>
            </Container>
            <Footer />
        </>
    );
}
