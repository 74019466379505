import { Button, Grid, IconButton, InputAdornment, Pagination, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import useModal from '../../../../../components/modal/useModal';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { TenantWithAllocate, AllocatedPermit } from '../../../../../models/tenant/Tenant';
import MyTable, { TableHeadContent } from '../../../../MyTable';
import IconSearch from '../../../components/svg/IconSearch';
import TenantModal from '../TenantModal';
import ModalAddTenant from './ModalAddTenant';
import TableRowTenantsInfo from './TableRowTenantsInfo';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { CompaniesContext } from '../../../../../hooks/useCompanies';
import { shopController } from '../../../../../controllers';
import LoadingCircular from '../../../../../components/LoadingCircular';
import toast from 'react-hot-toast';
import useMedia from '../../../../../hooks/useMedia';
import NoDataToDisplay from '../../../../../components/NoDataToDisplay';
import color from '../../../../../config/Colors';
import _ from 'lodash';
import useTenants, { TenantContext } from '../../../../../hooks/useTenants';

export default function TenantsTab() {
    const { t } = useContext(LanguageContext);
    const tenantData = useTenants();
    const { isLoadingDataTenant, tenantPagination, refreshDataTenant, setStateTenant, filter } = tenantData;
    const { refreshLocationDetail } = useContext(CompaniesContext);

    const { isExtraSmallXs, isMediumMD } = useMedia();
    const [tenantUpsert, setTenantUpsert] = useState<TenantWithAllocate>({} as any);
    const [idDelete, setIdDelete] = useState<number>(0);

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setStateTenant((prev) => ({ ...prev, filter: { ...prev.filter, page: value } }));
    };
    const { idLocationSelect, locationSelect, zonesSelect } = useContext(CompaniesContext);
    const modalTenant = useModal();
    const stateDialog = useModal();

    const handleDelete = (idClient: number) => {
        setIdDelete(idClient);
        stateDialog.handleOpen();
    };
    const handleOpenModel = () => {
        const tenant: TenantWithAllocate = {
            locationId: idLocationSelect,
            clientId: 0,
            tenantName: '',
            email: '',
            managerName: '',
            phoneNumber: '',
            allocatedPermits: [],
        };
        if (zonesSelect && zonesSelect.length > 0) {
            const list: AllocatedPermit[] = [];
            zonesSelect.map((item) => {
                list.push({
                    zoneId: item.id,
                    allocatedAmount: 0,
                    isValid: true,
                    shopLocationId: locationSelect?.id,
                    shopClientId: 0,
                });
            });
            tenant.allocatedPermits = list;
        }
        setTenantUpsert(tenant);
        modalTenant.handleOpen();
    };

    const handleEditModel = (tenant: TenantWithAllocate) => {
        setTenantUpsert(tenant);
        modalTenant.handleOpen();
    };

    const onDelete = () => {
        shopController.delete(idDelete.toString()).then((res) => {
            if (res.value && res.value == 'Client not found') {
                toast.error(t('notification:fail'));
            } else {
                //close popup
                stateDialog.handleClose();
                //refresh tenant
                refreshDataTenant();
                //refresh zone
                refreshLocationDetail();
                //popup success
                toast.success(t('notification:successfully'));
            }
        });
    };

    const handleSearch = (e: any) => {
        setStateTenant((prev) => ({
            ...prev,
            filter: { ...prev.filter, search: { ...prev.filter.search!, value: e.target.value }, page: 1 },
        }));
    };

    const largeHead: TableHeadContent[] = [
        {
            label: t('companies:tenants.tenantName'),
            alight: 'left',
        },
        {
            label: t('companies:tenants.managerName'),
            alight: 'center',
        },
        {
            label: t('companies:tenants.email'),
            alight: 'center',
        },
        {
            label: t('companies:tenants.zonesAndPerMits'),
            alight: 'center',
        },
        {
            label: '',
            alight: 'center',
        },
    ];
    const smallHead: TableHeadContent[] = [
        {
            label: t('companies:tenants.tenantName'),
            alight: 'left',
        },
        {
            label: t('companies:tenants.zonesAndPerMits'),
            alight: 'center',
        },
        {
            label: '',
            alight: 'center',
        },
    ];

    const totalAllocatedPermits = zonesSelect?.reduce((t, cv) => {
        return t + (cv.maxPermits - cv.allocated);
    }, 0);

    return (
        <TenantContext.Provider value={tenantData}>
            <Grid item container padding={'16px 16px 40px 16px'} gap={'16px'}>
                <TenantModal
                    key={1}
                    open={modalTenant.open}
                    handleOpen={handleOpenModel}
                    handleClose={modalTenant.handleClose}
                    modalContent={<ModalAddTenant tenant={tenantUpsert} handleClose={modalTenant.handleClose} />}
                />
                <ConfirmDialog
                    open={stateDialog.open}
                    title={t('action:confirm')}
                    content={t('companies:tenants.deleteTenantConfirm')}
                    onClose={stateDialog.handleClose}
                    onConfirm={onDelete}
                />
                <Grid
                    item
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems="center"
                    xs={12}
                    gap={2}
                >
                    <TextField
                        fullWidth
                        disabled={locationSelect && zonesSelect && zonesSelect.length <= 0}
                        placeholder={t('companies:input.searchByNameEmail')}
                        onChange={_.debounce((value) => handleSearch(value), 1000)}
                        inputProps={{
                            maxLength: 50,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <IconSearch />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{ minWidth: { xs: '120px', md: '200px' } }}
                        disabled={
                            (locationSelect && zonesSelect && zonesSelect.length <= 0) ||
                            !Boolean((totalAllocatedPermits || 0) > 0)
                        }
                        onClick={handleOpenModel}
                    >
                        {t('companies:tenants.addTenant')}
                    </Button>
                </Grid>

                {(totalAllocatedPermits || 0) <= 0 && locationSelect && zonesSelect && zonesSelect.length > 0 && (
                    <Typography
                        sx={{ width: '100%', padding: '8px', borderRadius: '5px', background: `${color.danger}10` }}
                        color={color.danger}
                        textAlign={'center'}
                    >
                        {t('companies:tenants.allPermitsHaveAlreadyBeenAllocated.')}
                    </Typography>
                )}

                {isLoadingDataTenant ? (
                    <LoadingCircular />
                ) : tenantPagination.data && tenantPagination.data.length > 0 ? (
                    <>
                        <Grid item xs={12} marginTop={'-8px'}>
                            <MyTable
                                tableHead={isExtraSmallXs || isMediumMD ? smallHead : largeHead}
                                tableRow={
                                    <TableRowTenantsInfo
                                        tenantWithAllocates={tenantPagination.data}
                                        editFunction={handleEditModel}
                                        deleteFunction={handleDelete}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item display={'flex'} justifyContent="center" xs={12}>
                            <Pagination
                                count={tenantPagination.totalPage}
                                page={tenantPagination.page}
                                onChange={handleChangePagination}
                                variant="outlined"
                                shape="rounded"
                                // sx={{ mt: 3 }}
                            />{' '}
                        </Grid>
                    </>
                ) : tenantPagination.data.length == 0 && filter.search?.value != '' && zonesSelect?.length != 0 ? (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                ) : tenantPagination.data.length == 0 && filter.search?.value == '' && zonesSelect?.length == 0 ? (
                    <NoDataToDisplay text={t('companies:tenants.youMustHaveAtLeastOneZoneToAddTenant')} />
                ) : (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                )}
            </Grid>
        </TenantContext.Provider>
    );
}
