/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { SelectItem } from '../../../components/MySelect';
import useAutoComplete from '../../../components/useAutoComplete';
import color from '../../../config/Colors';
import font from '../../../config/Fonts';
import { LanguageContext } from '../../../contexts/useLanguage';
import IconSearch from '../../companyLayout/components/svg/IconSearch';
import { initFilterPermit, StateFilter } from '../hooks/usePermitsFilter';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarIcon from '../../../assets/svg/CalendarIcon';
import { FilterEnum } from '../../../hooks/useFilter';
import useMultiSelectWithCheckBox from '../../../components/useMultiSelectWithCheckBox';
import { AuthContext } from '../../../hooks/useAuth';
import { locationController, regionController, shopController, zoneController } from '../../../controllers';
import { Filter } from '../../../models/Filter';
import { Region } from '../../../models/Region';
import { Location } from '../../../models/Location';
import { convertToSelectedItem } from '../../../helpers/ConvertFilterItem';
import useAutoCompleteRecurType from './useAutoCompleteRecurType';
import ToolbarTitleDatePicker from './ToolbarTitleDatePicker';

type Props = {
    state: StateFilter;
    setState: React.Dispatch<React.SetStateAction<StateFilter>>;
    handleClose: (isOpen: boolean) => void;
};

export default function FilterModalContent({ state, setState, handleClose }: Props) {
    const [modalState, setModalState] = useState<StateFilter>(state);
    const { isGoldRole, isBronzeRole } = useContext(AuthContext);
    const { t } = useContext(LanguageContext);
    const listStatus: SelectItem[] = [
        {
            value: 'all',
            label: t('permits:text.all'),
        },
        {
            value: 'active',
            label: t('action:active'),
        },
        {
            value: 'expired',
            label: t('action:expired'),
        },
        {
            value: 'pending',
            label: t('action:pending'),
        },
    ];

    const listRecurType: SelectItem[] = [
        {
            value: -1,
            label: t('permits:text.all'),
        },
        {
            value: 0,
            label: t('permits:text.oneDayPermit'),
        },
        {
            value: 1,
            label: t('permits:text.daily'),
        },
        {
            value: 2,
            label: t('permits:text.weekly'),
        },
        {
            value: 3,
            label: t('permits:text.monthly'),
        },
        {
            value: 5,
            label: t('permits:text.indefinite'),
        },
    ];

    const [editingField, setEditingField] = useState<FilterEnum>(FilterEnum.all);

    useEffect(() => {
        if (modalState.filter.filter!.companyId !== 0) {
            regionController
                .getAll({
                    pageSize: 10000,
                    filter: { companyId: modalState.filter.filter?.companyId || 0 },
                    search: {
                        fields: [],
                        value: '',
                    },
                })
                .then((res) => {
                    const all: Region = { id: 0, name: t('dashboard:text.allRegions') } as Region;
                    setModalState((prev) => ({
                        ...prev,
                        listRegion: [all].concat(res.data),
                    }));
                });
        }
        setModalState((prev) => ({
            ...prev,
            advancedFilter: { ...prev.advancedFilter, isDisableTenant: true, isShowTenant: false },
        }));
    }, [modalState.filter.filter!.companyId]);

    const defaultFilter: Filter = {
        companyId: modalState.filter.filter!.companyId,
        locationId: modalState.filter.filter!.locationId,
        regionId: modalState.filter.filter!.regionId,
        zoneIds: modalState.filter.filter!.zoneIds,
        from: new Date(),
        to: new Date(),
    };

    useEffect(() => {
        if (editingField === FilterEnum.locationId) return;

        locationController
            .getAll({
                pageSize: 10000,
                filter: {
                    companyId: modalState.filter.filter?.companyId || 0,
                    regionId: modalState.filter.filter?.regionId || 0,
                },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                const all: Location = { id: 0, name: t('dashboard:text.allLocation') } as Location;

                setModalState((prev) => ({
                    ...prev,
                    listLocation: [all].concat(res.data),
                }));
            });
    }, [modalState.filter.filter?.companyId, modalState.filter.filter?.regionId, editingField]);

    useEffect(() => {
        if (modalState.filter.filter!.locationId !== 0) {
            //api get tenant
            getTenantByLocation();
            //get list zone
            zoneController.getZones(defaultFilter.locationId!).then((res) => {
                setModalState((prev) => ({ ...prev, listZone: res }));
            });
        } else {
            //set disable advanced filter
            setStatusTenant(true);
        }
    }, [modalState.filter.filter!.locationId]);

    const setStatusTenant = (isDisable: boolean) => {
        setModalState((prev) => ({
            ...prev,
            advancedFilter: { ...prev.advancedFilter, isDisableTenant: isDisable },
            filter: { ...prev.filter, page: 1 },
        }));
    };

    useEffect(() => {
        if (modalState.filter.filter!.shopId !== 0) {
            const index = modalState.tenants.findIndex((item) => item.clientId === modalState.filter.filter!.shopId);
            if (index >= 0) {
                const zones = modalState.tenants[index].allocatedPermits;
                const listZone: SelectItem[] = [];
                if (zones!.length > 0) {
                    zones?.map((item) => listZone.push({ value: item.zone?.id, label: item.zone?.name as string }));
                }
                setModalState((prev) => ({
                    ...prev,
                    listZone: listZone,
                    filter: {
                        ...prev.filter,
                        filter: { ...prev.filter.filter!, zoneIds: listZone.map((i) => i.value) },
                        page: 1,
                    },
                }));
            } else {
                setModalState((prev) => ({
                    ...prev,
                    filter: { ...prev.filter, filter: { ...prev.filter.filter!, zoneIds: [] }, page: 1 },
                }));
            }
        }
    }, [modalState.filter.filter!.shopId]);

    const getListZone = (numbers: number[]) => {
        return modalState.listZone.filter((select) => select.value === numbers.find((num) => num === select.value));
    };

    const getTenantByLocation = () => {
        const listTenant: SelectItem[] = [];
        shopController
            .filter({
                page: 1,
                pageSize: 1000,
                search: {
                    fields: [],
                    value: '',
                },
                filter: {
                    locationId: Number(modalState.filter.filter!.locationId),
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    res.data.map((item) =>
                        listTenant.push({
                            value: item.clientId,
                            label: item.tenantName,
                            email: item.email,
                        })
                    );
                    listTenant.unshift({ value: 0, label: t('permits:text.allTenant') });
                    if (listTenant.length <= 2) {
                        setModalState((prev) => ({
                            ...prev,
                            listTenant: listTenant,
                            filter: {
                                ...prev.filter,
                                clientId: Number(listTenant[1].value),
                                tenants: res.data,
                                page: 1,
                            },
                        }));
                    } else {
                        setModalState((prev) => ({ ...prev, listTenant: listTenant, tenants: res.data }));
                    }
                }
            });
    };

    const onChangeFilter = (attribute: any, value: any) => {
        if (attribute === FilterEnum.companyId) {
            setModalState((prev) => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    filter: {
                        ...prev.filter.filter!,
                        [attribute]: value,
                        regionId: 0,
                        locationId: 0,
                        clientId: 0,
                        zoneIds: [],
                    },
                    page: 1,
                },
            }));
        } else if (attribute === FilterEnum.regionId) {
            setModalState((prev) => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    filter: { ...prev.filter.filter!, [attribute]: value, locationId: 0, clientId: 0, zoneIds: [] },
                    page: 1,
                },
            }));
        } else if (attribute === FilterEnum.locationId) {
            if (!value && modalState.filter.filter?.companyId) setEditingField(FilterEnum.all);
            else setEditingField(attribute);

            if (modalState.filter.filter?.companyId !== 0 && value == 0) {
                setModalState((prev) => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        filter: {
                            ...prev.filter.filter!,
                            [attribute]: value,
                            clientId: 0,
                            regionId: 0,
                            zoneIds: [],
                        },
                        page: 1,
                    },
                }));
            } else {
                const { regionId = 0, companyId = 0 } = modalState.listLocation.find((l) => l.id === value)!;

                setModalState((prev) => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        filter: {
                            ...prev.filter.filter!,
                            [attribute]: value,
                            regionId,
                            companyId,
                            clientId: 0,
                            zoneIds: [],
                        },
                        page: 1,
                    },
                }));
            }
        } else if (attribute === FilterEnum.shopId) {
            setModalState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, [attribute]: value }, page: 1 },
            }));
        } else if (attribute === FilterEnum.zoneIds) {
            setModalState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, [attribute]: value }, page: 1 },
            }));
        } else {
            setModalState((prev) => ({
                ...prev,
                filter: initFilterPermit,
                advancedFilter: {
                    isDisableTenant: true,
                    isShowFrom: false,
                    isShowPermitType: false,
                    isShowStatus: false,
                    isShowTenant: false,
                    isShowTo: false,
                    isShowRecurringType: false,
                },
            }));
            setEditingField(FilterEnum.all);
        }
    };

    const companySelect = useAutoComplete({
        listData: modalState.listCompany,
        label: t('dashboard:input.company'),
        value: modalState.listCompany.find((item) => item.value === modalState.filter.filter!.companyId),
        setFilter: (val) => {
            onChangeFilter(FilterEnum.companyId, val);
        },
        disabled: editingField === FilterEnum.locationId || isGoldRole() !== true,
    });

    const _listRegionMapped = modalState.listRegion.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const regionValue = modalState.listRegion.find((item) => item.id === modalState.filter.filter?.regionId);

    const regionSelect = useAutoComplete({
        listData: _listRegionMapped,
        label: t('dashboard:input.region'),
        value: regionValue ? convertToSelectedItem(regionValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            onChangeFilter(FilterEnum.regionId, val);
        },
        disabled: !modalState.filter.filter?.companyId || editingField === FilterEnum.locationId,
    });

    const _listLocationMapped = modalState.listLocation.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const locationValue = modalState.listLocation.find((item) => item.id === modalState.filter.filter?.locationId);

    const locationSelect = useAutoComplete({
        listData: _listLocationMapped,
        label: t('dashboard:input.location'),
        value: locationValue ? convertToSelectedItem(locationValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            onChangeFilter(FilterEnum.locationId, val);
        },
    });

    const tenantSelect = useAutoComplete({
        listData: modalState.listTenant,
        label: t('permits:text.tenant'),
        value: modalState.listTenant.find((item) => item.value === modalState.filter.filter!.shopId),
        setFilter: (val) => {
            onChangeFilter(FilterEnum.shopId, val);
        },
        disabled: modalState.listTenant.length === 0,
    });

    const zoneSelects = useMultiSelectWithCheckBox({
        listData: modalState.listZone,
        label: t('dashboard:input.zone'),
        value: getListZone(modalState.filter.filter!.zoneIds!),
        setFilter: (list) => {
            onChangeFilter(FilterEnum.zoneIds, list);
        },
        isDisable: modalState.filter.filter!.shopId == 0 && isBronzeRole() == false,
    });

    const statusSelect = useAutoComplete({
        listData: listStatus,
        label: t('action:status'),
        value: listStatus.find((item) => item.value === modalState.filter.filter!.status),
        setFilter: (val) => {
            setModalState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, status: val }, page: 1 },
            }));
        },
    });

    const permitTypeSelect = useAutoComplete({
        listData: modalState.listPermitType,
        label: t('permits:text.permitType'),
        value: modalState.listPermitType.find((item) => item.value === modalState.filter.filter!.permitType),
        setFilter: (val) => {
            setModalState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, permitType: val }, page: 1 },
            }));
        },
    });

    const recurTypeSelect = useAutoCompleteRecurType({
        listData: listRecurType,
        label: t('permits:text.recurringType'),
        value:
            modalState.filter.filter!.recurringType == null
                ? listRecurType[0]
                : listRecurType.find((item) => item.value === modalState.filter.filter!.recurringType),
        setFilter: (val) => {
            if (val === -1) {
                val = null;
            }
            setModalState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, recurringType: val }, page: 1 },
            }));
        },
    });

    const handleApplyFilter = () => {
        setState((prev) => ({ ...prev, filter: modalState.filter }));
        handleClose(false);
    };

    const changeFilter123 = () => {
        setModalState((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                filter: { ...prev.filter.filter!, validFrom: new Date(), validTo: undefined },
            },
        }));
    };

    return (
        <Grid container padding={'56px 24px 40px'} gap={3}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                <Typography variant="h3" fontFamily={font} fontSize={'20px'}>
                    {t('permits:title.setFilters')}
                </Typography>
                <Button
                    variant="errorOutlined"
                    sx={{ padding: '8px 32px' }}
                    onClick={() => {
                        setModalState((prev) => ({ ...prev, filter: initFilterPermit }));
                        setEditingField(FilterEnum.all);
                    }}
                >
                    {t('permits:button.reset')}
                </Button>
            </Stack>
            <Grid item container xs={12} spacing={1}>
                <Stack flexDirection={'row'} alignItems={'center'} flex={1} px={1}>
                    <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                    <Typography variant="h5" component={'span'} mx={0.5} color={color.primary}>
                        {t('permits:text.filterByPermit')}{' '}
                    </Typography>
                </Stack>
                <Grid item container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    height: 'auto',
                                },
                            }}
                            fullWidth
                            placeholder={t('permits:text.searchByVRN')}
                            onChange={(e) =>
                                setModalState((prev) => ({
                                    ...prev,
                                    filter: {
                                        ...prev.filter,
                                        search: { ...prev.filter.search!, value: e.target.value },
                                    },
                                }))
                            }
                            defaultValue={state.filter.search?.value}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <IconSearch />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {statusSelect.RenderSelect}
                    </Grid>
                </Grid>
                <Grid item xs={6} mt={1}>
                    {permitTypeSelect.RenderSelect}
                </Grid>
                <Grid item xs={6} mt={1}>
                    {recurTypeSelect.RenderSelect}
                </Grid>
                <Grid item container spacing={1} mt={0.5}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('permits:text.validFrom')}
                                value={modalState.filter.filter!.validFrom}
                                onChange={(newValue) => {
                                    setModalState((prev) => ({
                                        ...prev,
                                        filter: {
                                            ...prev.filter,
                                            filter: { ...prev.filter.filter!, validFrom: newValue, validTo: undefined },
                                        },
                                    }));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )}
                                // open
                                DialogProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                PopperProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                components={{
                                    OpenPickerIcon: CalendarIcon,
                                }}
                                toolbarTitle={
                                    <ToolbarTitleDatePicker
                                        setModalState={() => {
                                            setModalState((prev) => ({
                                                ...prev,
                                                filter: {
                                                    ...prev.filter,
                                                    filter: {
                                                        ...prev.filter.filter!,
                                                        validFrom: new Date(),
                                                        validTo: undefined,
                                                    },
                                                },
                                            }));
                                        }}
                                    />
                                }
                                inputFormat="dd/MM/yyyy"
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                minDate={modalState.filter.filter?.validFrom}
                                label={t('permits:text.validTo')}
                                value={
                                    modalState.filter.filter!.validTo == undefined
                                        ? null
                                        : modalState.filter.filter!.validTo
                                }
                                onChange={(newValue) => {
                                    setModalState((prev) => ({
                                        ...prev,
                                        filter: {
                                            ...prev.filter,
                                            filter: { ...prev.filter.filter!, validTo: newValue! },
                                        },
                                    }));
                                }}
                                DialogProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                PopperProps={{
                                    sx: { zIndex: 99999 },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent',
                                            },
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                )}
                                toolbarTitle={
                                    <ToolbarTitleDatePicker
                                        setModalState={() => {
                                            setModalState((prev) => ({
                                                ...prev,
                                                filter: {
                                                    ...prev.filter,
                                                    filter: {
                                                        ...prev.filter.filter!,
                                                        validTo: undefined,
                                                    },
                                                },
                                            }));
                                        }}
                                    />
                                }
                                components={{
                                    OpenPickerIcon: CalendarIcon,
                                }}
                                inputFormat="dd/MM/yyyy"
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Stack flexDirection={'row'} alignItems={'center'} flex={1} px={1} mt={1}>
                    <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                    <Typography variant="h5" component={'span'} mx={0.5} color={color.primary}>
                        {t('permits:text.filterByArea')}{' '}
                    </Typography>
                </Stack>
                <Grid item xs={12} mt={0.5}>
                    {locationSelect.RenderSelect}
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    {companySelect.RenderSelect}
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    {regionSelect.RenderSelect}
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    {tenantSelect.RenderSelect}
                </Grid>
                <Grid item xs={12} mt={0.5}>
                    {zoneSelects.RenderSelect}
                </Grid>
            </Grid>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                <Button
                    variant="cancel"
                    sx={{ padding: '8px 32px', width: '130px' }}
                    onClick={() => handleClose(false)}
                >
                    {t('action:cancel')}
                </Button>
                <Button variant="contained" sx={{ padding: '8px 16px', width: '130px' }} onClick={handleApplyFilter}>
                    {t('permits:button.applyFilter')}
                </Button>
            </Stack>
        </Grid>
    );
}
