import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { BsPlus } from 'react-icons/bs';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import MenuItemCheckbox from './MenuItemCheckbox';
import { AdvancedFilterOptions } from '../../../models/permits/FilterPermits';
import { AdvancedName } from '../hooks/usePermitsFilter';

type Props = {
    onChangeSearch?: (text: string) => void;
    buttonFunction?: () => void;
    isOpen?: null;
    advancedFilterItems: AdvancedFilterOptions;
    setShowingAdvancedFilter: (advancedFilter: AdvancedFilterOptions) => void;
};

export default function AdvancedFilterControl(props: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(props.isOpen || null);

    const [listAdvanced, setListAdvanced] = React.useState<AdvancedFilterOptions>(props.advancedFilterItems);
    React.useEffect(() => {
        setListAdvanced(props.advancedFilterItems);
    }, [props.advancedFilterItems]);

    const { t } = React.useContext(LanguageContext);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const onChangeSearch = (e: any) => {
    //     props.onChangeSearch && props.onChangeSearch(e.target.value);
    // };

    // const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     e.stopPropagation();
    // };
    // const inputRef = React.useRef();

    const onChecked = (val: boolean, name: AdvancedName) => {
        if (name == 'tenant') {
            setListAdvanced((prev) => ({ ...prev, isShowTenant: val }));
        } else if (name == 'permitType') {
            setListAdvanced((prev) => ({ ...prev, isShowPermitType: val }));
        } else if (name == 'status') {
            setListAdvanced((prev) => ({ ...prev, isShowStatus: val }));
        } else if (name == 'validFrom') {
            setListAdvanced((prev) => ({ ...prev, isShowFrom: val }));
        } else if (name == 'validTo') {
            setListAdvanced((prev) => ({ ...prev, isShowTo: val }));
        } else if (name == 'recurringType') {
            setListAdvanced((prev) => ({ ...prev, isShowRecurringType: val }));
        }
    };

    const onClickAddField = () => {
        props.setShowingAdvancedFilter(listAdvanced);
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Box
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    display: 'inline-block',
                }}
            >
                <Button variant="outlined" sx={{ padding: '5px', minWidth: '32px' }}>
                    <BsPlus fontSize={'20px'} />
                </Button>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: 1,
                        padding: 1,
                        border: `1px solid ${color.grey300}`,
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                background: 'none',
                            },
                        },
                    },
                }}
                MenuListProps={{
                    sx: {
                        padding: 0,
                        transition: 'none !important',
                        '&:focus': {
                            backgroundColor: '#FFF',
                        },
                    },
                    disabledItemsFocusable: true,
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {/* <MenuItem sx={{ padding: 0, mt: '2px' }} disableRipple={true} onKeyDown={(e: any) => onKeyDown(e)}>
                    <TextField
                        fullWidth
                        placeholder={t('companies:input.searchByName')}
                        autoFocus
                        onChange={onChangeSearch}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <IconSearch />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </MenuItem> */}
                <Box sx={{ maxHeight: '250px', minWidth: '200px', overflowY: 'auto', mt: '5px' }}>
                    {/* <MenuItemCheckbox
                        label="tenant"
                        isChecked={listAdvanced.isShowTenant}
                        isDisabled={listAdvanced.isDisableTenant}
                        onChecked={onChecked}
                    /> */}
                    <MenuItemCheckbox
                        label="permitType"
                        isChecked={listAdvanced.isShowPermitType}
                        isDisabled={false}
                        onChecked={onChecked}
                    />
                    <MenuItemCheckbox
                        label="status"
                        isChecked={listAdvanced.isShowStatus}
                        isDisabled={false}
                        onChecked={onChecked}
                    />
                    <MenuItemCheckbox
                        label="recurringType"
                        isChecked={listAdvanced.isShowRecurringType}
                        isDisabled={false}
                        onChecked={onChecked}
                    />
                    <MenuItemCheckbox
                        label="validFrom"
                        isChecked={listAdvanced.isShowFrom}
                        isDisabled={false}
                        onChecked={onChecked}
                    />
                    <MenuItemCheckbox
                        label="validTo"
                        isChecked={listAdvanced.isShowTo}
                        isDisabled={false}
                        onChecked={onChecked}
                    />
                </Box>

                <MenuItem sx={{ display: 'flex', justifyContent: 'right', padding: 0, mt: '5px' }} disableRipple={true}>
                    <Button variant="contained" size="small" onClick={onClickAddField}>
                        {t('permits:button.addFields')}
                    </Button>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
