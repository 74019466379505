import { BaseHttpController } from 'ale-base-model'
import { AxiosInstance } from 'axios';
import { SelectItem } from '../components/MySelect';
import { EditPermit } from '../models/tenant/EditPermit';
import { TenantWithAllocate } from '../models/tenant/Tenant';
import Zone from '../models/Zone';

export class ZoneController extends BaseHttpController<TenantWithAllocate> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Zone', client);
    }

    async editPermits(editPermit: EditPermit) {
        const res = await this.doPost({ path: 'editPermits', body: editPermit, config: {} })
        return res.data
    }

    async getZones(idLocation: number) {
        const res = await this.client.get(`${this.serviceURL}/Zone/myZones/${idLocation}`)
        const listData: SelectItem[] = [];
        res.data.map((item: { id: any; name: any, zone: any }) => {
            listData.push({
                value: item.id,
                label: item.name,
            });
        });
        return listData;
    }
    async getZonesDetail(idLocation: number) {
        const res = await this.client.get(`${this.serviceURL}/Zone/myZones/${idLocation}`)
        return res.data
    }

    async permitWithAllocated(zoneIds: number[], tenantId: number): Promise<{ maxAllocated: number, allocatedPermits: number }> {
        const res = await this.doPost({ path: 'permitWithAllocated', body: { zoneIds, tenantId }, config: {} })
        return res.data
    }


}

