import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { UpsertPermitContext } from '../hooks/useUpsertPermit';
import UpsertPermitItem from './UpsertPermitItem/UpsertPermitItem';

export default function UpsertPermitItems() {
    const { t, permits, isEdit } = useContext(UpsertPermitContext);

    return (
        <Grid xs={12} item container>
            {isEdit ? (
                <></>
            ) : (
                <Typography variant="body1" fontWeight={500}>
                    {t('permits:text.newPermits')} ({permits.length})
                </Typography>
            )}
            {permits.length > 0 ? (
                <Grid xs={12} item mt={1}>
                    {permits.map((item, index) => {
                        return <UpsertPermitItem key={item.id} permit={item} />;
                    })}
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
}
