import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import AutoCompletedDisable from '../../../../components/AutoCompletedDisable';
import { SelectItem } from '../../../../components/MySelect';
import useAutoComplete from '../../../../components/useAutoComplete';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { companiesController, shopController, zoneController } from '../../../../controllers';
import { AuthContext } from '../../../../hooks/useAuth';
import { Filter } from '../../../../models/Filter';
import { TenantWithAllocate } from '../../../../models/tenant/Tenant';
import { PermitFilterContext } from '../../hooks/usePermitsFilter';
import { UpsertPermitContext } from '../hooks/useUpsertPermit';

export default function UpsertPermitFilter() {
    const { setState, filter, filterData, isEdit } = useContext(UpsertPermitContext);
    const filterContext = useContext(PermitFilterContext);
    const { account, isGoldRole, isSilverRole, isBronzeRole } = useContext(AuthContext);
    const [tenants, setTenants] = useState<TenantWithAllocate[]>([]);
    const { t } = useContext(LanguageContext);

    useEffect(() => {
        if (isGoldRole()) {
            //drill down from companies
            getCompanies();
        } else if (isSilverRole()) {
            //drill down from location
            getInfoByClient();
        } else if (isBronzeRole()) {
            //drill down from zone
            getAllocatedZones();
        }
    }, []);

    useEffect(() => {
        if (isEdit === false) {
            if (filter.idCompany !== 0) {
                companiesController.GetCompanyDetailHaveTenant(defaultFilter).then((res) => {
                    //when location length >0
                    if (res.locations.length > 0) {
                        res.regions.unshift({ value: 0, label: t('dashboard:text.allRegions') });
                        res.locations.unshift({ value: 0, label: t('permits:input.selectLocation') });
                        setState((prev) => ({
                            ...prev,
                            filterData: { ...prev.filterData, listRegion: res.regions, listLocation: res.locations },
                        }));
                    } else {
                        toast.error(t('permits:text.filterWarningWhenHaveNoneTenant'));
                        setState((prev) => ({
                            ...prev,
                            filter: {
                                ...prev.filter,
                                idCompany: 0,
                                idLocation: 0,
                                idRegion: 0,
                                idZones: [],
                                idShop: 0,
                            },
                        }));
                    }
                });
            } else {
                setState((prev) => ({
                    ...prev,
                    filterData: { ...prev.filterData, listRegion: [], listLocation: [] },
                }));
            }
            // reset permit when change another company
            setState((prev) => ({ ...prev, permits: [], validations: [] }));
        }
    }, [filter.idCompany]);

    useEffect(() => {
        if (isEdit === false) {
            if (filter.idRegion !== 0) {
                companiesController.GetCompanyDetailHaveTenant(defaultFilter).then((res) => {
                    res.regions.unshift({ value: 0, label: t('dashboard:text.allRegions') });
                    res.locations.unshift({ value: 0, label: t('permits:input.selectLocation') });
                    setState((prev) => ({
                        ...prev,
                        filterData: { ...prev.filterData, listRegion: res.regions, listLocation: res.locations },
                        permits: [],
                    }));
                });
            }
            // reset permit when change another region
            setState((prev) => ({ ...prev, permits: [], validations: [] }));
        }
    }, [filter.idRegion]);

    useEffect(() => {
        if (isEdit === false) {
            if (filter.idLocation !== 0) {
                //api get tenant
                getTenantByLocation();
                //get list zone
                if (filterData.listShop.length > 0) {
                    zoneController.getZones(defaultFilter.locationId!).then((res) => {
                        setState((prev) => ({
                            ...prev,
                            filterData: { ...prev.filterData, listZone: res },
                            permits: [],
                        }));
                    });
                }
            } else {
                setState((prev) => ({
                    ...prev,
                    filterData: { ...prev.filterData, listZone: [], listShop: [] },
                }));
            }
            // reset permit when change another location
            setState((prev) => ({ ...prev, permits: [], validations: [] }));
        }
    }, [filter.idLocation]);

    useEffect(() => {
        if (isEdit === false) {
            if (filter.idShop !== 0 && isEdit === false) {
                //get index of shop
                const index = tenants.findIndex((item) => item.clientId === filter.idShop);
                if (index >= 0) {
                    const zones = tenants[index].allocatedPermits;
                    const listZone: SelectItem[] = [];
                    if (zones!.length > 0) {
                        zones?.map((item) => listZone.push({ value: item.zone?.id, label: item.zone?.name as string }));
                    }
                    if (listZone.length === 1) {
                        setState((prev) => ({
                            ...prev,
                            filterData: { ...prev.filterData, listZone: listZone },
                            filter: { ...prev.filter, idZones: [listZone[0].value] },
                            permits: [],
                        }));
                    } else {
                        setState((prev) => ({
                            ...prev,
                            filterData: { ...prev.filterData, listZone: listZone },
                            filter: {
                                ...prev.filter,
                                //get first zone of filter permit of current
                                idZones:
                                    filterContext.filter.filter!.zoneIds.length >= 1
                                        ? [filterContext.filter.filter!.zoneIds[0]]
                                        : [listZone[0].value],
                            },
                        }));
                    }
                }
            } else {
                setState((prev) => ({
                    ...prev,
                    filterData: { ...prev.filterData, listZone: [] },
                    filter: { ...prev.filter, idZones: [] },
                }));
            }
            setState((prev) => ({ ...prev, permits: [], validations: [] }));
        }
    }, [filter.idShop, tenants]);

    useEffect(() => {
        if (isEdit === false) {
            if (filter.idZones.length > 0) {
                //get idShop
                const idShop = isBronzeRole() ? account?.id : filter.idShop;

                zoneController.permitWithAllocated(filter.idZones, idShop!).then((res) => {
                    setState((prev) => ({
                        ...prev,
                        filterData: { ...prev.filterData, zonesInfo: res },
                    }));
                });
            } else {
                setState((prev) => ({
                    ...prev,
                    filterData: { ...prev.filterData, zonesInfo: {} as any },
                }));
            }
            setState((prev) => ({ ...prev, permits: [], validations: [] }));
        }
    }, [filter.idZones]);

    const getCompanies = () => {
        companiesController.getMyCompanyHaveTenant().then((res) => {
            res.unshift({ value: 0, label: t('permits:input.selectCompany') });
            setState((prev) => ({ ...prev, filterData: { ...prev.filterData, listCompany: res } }));
        });
    };

    const getInfoByClient = () => {
        companiesController.GetCompanyDetailHaveTenant(defaultFilter).then((res) => {
            if (res.locations.length > 0) {
                res.locations.unshift({ value: 0, label: t('permits:input.selectLocation') });
                setState((prev) => ({ ...prev, filterData: { ...prev.filterData, listLocation: res.locations } }));
            }
        });
    };

    const getAllocatedZones = () => {
        shopController.getAllocatedZones().then((res) => {
            const zones: SelectItem[] = [];
            if (res.length > 0) {
                res.map((item: any) => zones.push({ value: item.id, label: item.name }));
            }

            if (zones.length > 0) {
                setState((prev) => ({
                    ...prev,
                    filterData: { ...prev.filterData, listZone: zones },
                    filter: { ...prev.filter, idZones: [zones[0].value], idShop: account?.id! },
                }));
            }
        });
    };

    const companySelect = useAutoComplete({
        listData: filterData.listCompany,
        disabled: isEdit,
        label: t('dashboard:input.company'),
        value: filterData.listCompany.find((item) => item.value === filter.idCompany),
        setFilter: (val) => {
            if (isEdit === false) {
                setState((prev) => ({
                    ...prev,
                    filter: { ...prev.filter, idCompany: val, idRegion: 0, idLocation: 0, idShop: 0, idZones: [] },
                }));
            }
        },
    });

    const locationSelect = useAutoComplete({
        disabled: isEdit,
        listData: filterData.listLocation,
        label: t('dashboard:input.location'),
        value: filterData.listLocation.find((item) => item.value === filter.idLocation),
        setFilter: (val) => {
            if (isEdit === false) {
                setState((prev) => ({ ...prev, filter: { ...prev.filter, idLocation: val, idShop: 0, idZones: [] } }));
            }
        },
    });

    const tenantSelect = useAutoComplete({
        disabled: isEdit,
        listData: filterData.listShop,
        label: t('permits:text.tenant'),
        value: filterData.listShop.find((item) => item.value === filter.idShop),
        setFilter: (val) => {
            if (isEdit === false) {
                setState((prev) => ({ ...prev, filter: { ...prev.filter, idShop: val, idZones: [] } }));
            }
        },
    });
    const zoneSelect = useAutoComplete({
        disabled: isEdit,
        listData: filterData.listZone,
        label: t('dashboard:input.zone'),
        value: filterData.listZone.find((item) => item.value === filter.idZones[0]),
        setFilter: (val) => {
            if (isEdit === false) {
                setState((prev) => ({ ...prev, filter: { ...prev.filter, idZones: [val] } }));
            }
        },
    });

    const defaultFilter: Filter = {
        companyId: filter.idCompany,
        locationId: filter.idLocation,
        regionId: filter.idRegion,
        zoneIds: filter.idZones,
        from: new Date(),
        to: new Date(),
    };

    const getTenantByLocation = () => {
        const listTenant: SelectItem[] = [];
        shopController
            .filter({
                page: 1,
                pageSize: 1000,
                search: {
                    fields: [],
                    value: '',
                },
                filter: {
                    locationId: Number(filter.idLocation),
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    res.data.map((item) =>
                        listTenant.push({
                            value: item.clientId,
                            label: item.tenantName,
                            email: item.email,
                        })
                    );
                    listTenant.unshift({ value: 0, label: t('permits:input.selectTenant') });
                    if (listTenant.length <= 2) {
                        setState((prev) => ({
                            ...prev,
                            filterData: { ...prev.filterData, listShop: listTenant },
                            filter: { ...prev.filter, idShop: Number(listTenant[1].value) },
                        }));
                    } else {
                        setState((prev) => ({
                            ...prev,
                            filterData: { ...prev.filterData, listShop: listTenant },
                        }));
                    }
                    setTenants(res.data);
                } else {
                    setState((prev) => ({
                        ...prev,
                        filterData: { ...prev.filterData, listShop: [] },
                    }));
                }
            });
    };

    return (
        <Grid item container spacing={2}>
            <Grid item xs={6} display={isBronzeRole() ? 'none' : 'block'}>
                {filterData.listCompany.length === 0 ? (
                    <AutoCompletedDisable
                        label={t('dashboard:input.company')}
                        value={t('permits:input.selectCompany')}
                    />
                ) : (
                    companySelect.RenderSelect
                )}
            </Grid>
            <Grid item xs={6} display={isBronzeRole() ? 'none' : 'block'}>
                {filterData.listLocation.length !== 0 || filter.idCompany !== 0 || isSilverRole() === true ? (
                    filterData.listLocation.length !== 0 ? (
                        locationSelect.RenderSelect
                    ) : (
                        <AutoCompletedDisable
                            label={t('dashboard:input.location')}
                            value={t('permits:input.selectLocation')}
                        />
                    )
                ) : (
                    <AutoCompletedDisable
                        label={t('dashboard:input.location')}
                        value={t('permits:input.selectLocation')}
                    />
                )}
            </Grid>
            <Grid item xs={6} display={isBronzeRole() ? 'none' : 'block'}>
                {filterData.listShop.length === 0 || filter.idLocation === 0 ? (
                    <AutoCompletedDisable label={t('permits:text.tenant')} value={t('permits:input.selectTenant')} />
                ) : (
                    tenantSelect.RenderSelect
                )}
            </Grid>
            <Grid item xs={isBronzeRole() ? 12 : 6}>
                {filterData.listZone.length !== 0 || filter.idShop !== 0 || isBronzeRole() == true ? (
                    filterData.listZone.length !== 0 ? (
                        zoneSelect.RenderSelect
                    ) : (
                        <AutoCompletedDisable label={t('dashboard:input.zone')} value={t('permits:input.SelectZone')} />
                    )
                ) : (
                    <AutoCompletedDisable label={t('dashboard:input.zone')} value={t('permits:input.SelectZone')} />
                )}
            </Grid>
        </Grid>
    );
}
