/* eslint-disable eqeqeq */
import _ from 'lodash';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { permitController } from '../../../../controllers';
import UploadBorderStep from '../components/UploadBorderStep';
import UploadContent from '../components/UploadContent';
import UploadTableConfirm from '../components/UploadTableConfirm';
import * as XLSX from 'xlsx';
import useUpsertPermit from '../../addEditPermit/hooks/useUpsertPermit';
import { convertHour } from '../../../../helpers/ConvertHour';
import { RecurringType } from '../../../../models/permits/PermitRecurring';
import { ExcelRawPermit, RawUploadPermitStatus } from '../../../../models/permits/ExcelRawPermit';
import { Permit, ValidateToType } from '../../../../models/permits/Permit';
import moment from 'moment';
import { PermitFilterContext } from '../../hooks/usePermitsFilter';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface State {
    step: number;
    fileName: string;
    listVRNChecking: ExcelRawPermit[];
    progressPercent: number;
    fileUpload: File | null;
    isLoading: boolean;
    isLoadingDownload: boolean;
}

export default function useUploadPermit() {
    const upsertPermitData = useUpsertPermit();
    const { filter, filterData } = upsertPermitData;
    const { reloadPermitsData } = useContext(PermitFilterContext);
    const { t } = useContext(LanguageContext);
    const [state, setState] = useState<State>({
        step: 1,
        fileName: '',
        listVRNChecking: [],
        progressPercent: 0,
        fileUpload: null,
        isLoading: false,
        isLoadingDownload: false,
    });
    const uploadInputRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate();

    const setProgress = (percent: number) => {
        setState((p) => ({ ...p, progressPercent: percent }));
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setState((p) => ({ ...p, isLoading: true }));
        setProgress(0);
        if (!e.target.files) {
            return;
        }
        setProgress(10);
        const file = e.target.files[0];
        setState((p) => ({ ...p, fileName: file.name, fileUpload: file }));

        //read data from file
        var reader = new FileReader();
        reader.onload = function (e) {
            setProgress(30);
            var data = e.target!.result;
            let readerData = XLSX.read(data, { type: 'binary' });
            const wsName = readerData.SheetNames[0];
            const ws = readerData.Sheets[wsName];
            /* Convert array to json*/
            const startRows = 3;
            const dataParse: any[] = XLSX.utils.sheet_to_json(ws, {
                header: 1,
                range: startRows,
                raw: false,
            });

            const rawPermitData: ExcelRawPermit[] = [];
            setProgress(40);
            if (dataParse.length > 0) {
                dataParse.map((item, index) => {
                    const rowData: any[] = item;
                    if (rowData[0] || rowData[1] || rowData[2] || rowData[3] || rowData[4]) {
                        const permitDataItem: ExcelRawPermit = {
                            zoneId: filter.idZones[0],
                            vrn: rowData[0],
                            firstName: rowData[1],
                            lastName: rowData[2],
                            recurringType: getRecurType(rowData[3]),
                            permitType: rowData[4],
                            startDate: rowData[5],
                            startTime: rowData[6],
                            endTime: rowData[7],
                            bayNumber: rowData[8],
                            comment: rowData[9],
                            tenantId: filter.idShop,
                            recurringEvery: 1,
                        };
                        rawPermitData.push(permitDataItem);
                    }
                });
            }
            setProgress(50);
            // //check
            if (rawPermitData.length > 0) {
                setProgress(75);
                permitController
                    .checkVRN(rawPermitData.slice())
                    .then((res) => {
                        setState((p) => ({ ...p, listVRNChecking: res, isLoading: false })); //
                        setProgress(100);
                    })
                    .catch((res) => {
                        toast.error(t('permits:upload.invalidFileFormat'));
                        handleCancelUpload();
                        setState((p) => ({ ...p, isLoading: false }));
                    });
            }
        };
        reader.readAsBinaryString(file);

        //clear input file
        (e.target as HTMLInputElement).value = '';
    };

    const getRecurType = (string: string) => {
        if (string == 'One day permit' || string == 'Tages-Genehmigung') {
            return RecurringType.once;
        }
        if (string == 'Indefinite' || string == 'Unbefristet') {
            return RecurringType.indefinite;
        }
        return null;
    };

    //clear content when change filter
    useEffect(() => {
        setState({
            step: 1,
            fileName: '',
            listVRNChecking: [],
            progressPercent: 0,
            fileUpload: null,
            isLoading: false,
            isLoadingDownload: false,
        });
    }, [filter]);

    const handleCancelUpload = () => {
        setState((p) => ({
            ...p,
            fileName: '',
            listVRNChecking: [],
            progressPercent: 0,
            fileUpload: null,
        }));
    };

    const setIsLoadingProgress = (val: boolean) => {
        setState((p) => ({ ...p, isLoadingDownload: val }));
    };

    const handleNextStep = () => {
        if (state.progressPercent === 100) {
            const step = _.cloneDeep(state.step);
            //save permit
            if (step === 2) {
                handleSavePermits();
            } else {
                setState((p) => ({ ...p, step: step + 1 }));
            }
        }
    };

    const handleSavePermits = () => {
        //set loading button
        setState((p) => ({ ...p, isLoading: true }));
        const permits: Permit[] = [];
        //remove duplicate Permit
        const listVRNChecking: ExcelRawPermit[] = Array.from(
            new Set(_.cloneDeep(state.listVRNChecking).map((el) => JSON.stringify(el)))
        ).map((el) => JSON.parse(el));

        //create list permit from valid item
        if (listVRNChecking.length > 0) {
            listVRNChecking.map((item) => {
                if (item.status == RawUploadPermitStatus.Valid) {
                    permits.push(handleCreatePermit(item));
                }
            });

            //call api add permit
            setState((p) => ({ ...p, permitReq: permits }));
            if (permits.length > 0) {
                permitController
                    .Upsert(permits)
                    .then((res) => {
                        //action when add permit done
                        toast.success(t('permits:upload.uploadDone'));
                        //load data permit
                        reloadPermitsData();
                        //back to permits page
                        navigate(`/permits`);
                        //set loading button
                        setState((p) => ({ ...p, isLoading: false, permitRes: res }));
                    })
                    .catch((res) => {
                        toast.error(res.response.data.value);
                        //set loading button
                        setState((p) => ({ ...p, isLoading: false }));
                    });
            } else {
                toast.error(t('notification:YouHaveReachedTheMaximumNumberOfPermit'));
                setState((p) => ({ ...p, isLoading: false }));
            }
        }
    };

    const handleCreatePermit = (rawPermit: ExcelRawPermit) => {
        const isIndefinite = rawPermit.recurringType == RecurringType.indefinite;
        const permit: Permit = {
            id: 0,
            firstName: rawPermit.firstName!,
            lastName: rawPermit.lastName!,
            permitType: rawPermit.permitType!,
            vrnNumber: rawPermit.vrn!,
            validateFrom: isIndefinite ? new Date() : moment(rawPermit.startDate!, 'DD/MM/YYYY').toDate(),
            validateToType: isIndefinite ? ValidateToType.infinite : ValidateToType.absoluteAt,
            validateTo: isIndefinite ? undefined : moment(rawPermit.startDate!, 'DD/MM/YYYY').toDate(),
            tenantId: filter.idShop,
            permitScopes: [{ id: 0, scopeType: 'zone', scopeId: filter.idZones[0] }],
            permitRecurring: [
                {
                    id: 0,
                    fromHour: isIndefinite
                        ? convertHour.getSecondFromStartOfDay(moment().startOf('day').toDate())
                        : convertHour.getSecondFromHoursString(rawPermit.startTime!),
                    toHour: isIndefinite
                        ? convertHour.getSecondFromStartOfDay(moment().endOf('day').toDate())
                        : convertHour.getSecondFromHoursString(rawPermit.endTime!),
                    permitRecurringDays: [
                        {
                            id: 0,
                            firstExecuteAt: isIndefinite
                                ? new Date()
                                : moment(rawPermit.startDate!, 'DD/MM/YYYY').toDate(),
                            nextExecuteAt: isIndefinite
                                ? new Date()
                                : moment(rawPermit.startDate!, 'DD/MM/YYYY').toDate(),
                        },
                    ],
                    recurringEvery: 1,
                    recurringType: isIndefinite ? RecurringType.indefinite : RecurringType.once,
                },
            ],
            comment: rawPermit.comment,
            bayNumber: rawPermit.bayNumber ? rawPermit.bayNumber.toString() : undefined,
        };
        return permit;
    };

    const getStepComponent = () => {
        switch (state.step) {
            case 1: {
                return (
                    <UploadBorderStep
                        filterData={filterData}
                        filter={filter}
                        isLoadingDownload={state.isLoadingDownload}
                        setIsLoadingProgress={setIsLoadingProgress}
                        content={
                            <UploadContent
                                progressPercent={state.progressPercent}
                                handleFileUpload={handleFileUpload}
                                uploadInputRef={uploadInputRef}
                                fileName={state.fileName}
                                handleCancelUpload={handleCancelUpload}
                                filter={filter}
                                file={state.fileUpload}
                                filterData={filterData}
                                isLoading={state.isLoading}
                            />
                        }
                    />
                );
            }
            case 2: {
                return <UploadTableConfirm filterData={filterData} listVRNChecking={state.listVRNChecking} />;
            }
            default: {
                return <></>;
            }
        }
    };
    return {
        ...state,
        setState,
        t,
        handleNextStep,
        getStepComponent,
        upsertPermitData,
    };
}

export const UploadPermitsContext = React.createContext<ReturnType<typeof useUploadPermit>>({} as any);
