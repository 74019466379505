import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Pagination } from '../models/BaseFilter';
import { LocationWithDetail } from '../models/Location';
import { Region } from '../models/Region';
import { FilterRequest } from './../models/BaseFilter';

export class RegionController extends BaseHttpController<Region> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Region', client);
    }

    async getAll(filter: GetAllProps): Promise<Pagination<LocationWithDetail>> {
        const res = await this.doPost({ path: 'getAll', body: filter, config: {} })
        return res.data
    }
}

export interface GetAllProps extends FilterRequest<Region> {
    filter: {
        companyId: number;
    }
}
