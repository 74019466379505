/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import _, { cloneDeep } from 'lodash';
import { addDays, endOfDay, getDate, getMonth, isAfter, startOfDay } from 'date-fns';
import { addMonths } from 'date-fns/esm';
import { useFormik } from 'formik';
import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react';
import LabelWithRequired from '../../../../../../components/LabelWithRequired';
import { SelectItem } from '../../../../../../components/MySelect';
import useAutoComplete2 from '../../../../../../components/useAutoComplete2';
import { convertHour } from '../../../../../../helpers/ConvertHour';
import useYupSchema from '../../../../../../helpers/useYupSchema';
import { AuthContext } from '../../../../../../hooks/useAuth';
import { Permit, ValidateToType } from '../../../../../../models/permits/Permit';
import { PermitRecurring, RecurringType } from '../../../../../../models/permits/PermitRecurring';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';
import { ConvertToGMT0Time } from '../../../../../../helpers';

type Props = {
    permit: Permit;
};

interface State {
    permit: Permit;
    isValid: boolean;
    daysOfWeekRecur: Date[];
}

export default function useUpsertPermitItem(props: Props) {
    const { t, setState, permits, isAdd, validations, permitsAddSuccess, handleDuplicatePermit, isEdit } =
        useContext(UpsertPermitContext);

    const { isGoldRole } = useContext(AuthContext);

    const getDaysOfWeekRecur = (permit: Permit) => {
        const days: Date[] = [];
        if (permit.permitRecurring[0].permitRecurringDays.length > 0) {
            permit.permitRecurring[0].permitRecurringDays.map((item) => days.push(new Date(item.firstExecuteAt!)));
        } else {
            days.push(new Date());
        }
        return days;
    };

    const getValidateFrom = () => {
        if (isEdit) {
            if (isAfter(new Date(itemState.permit.validateFrom!), new Date())) {
                return new Date(itemState.permit.validateFrom!);
            } else {
                return null;
            }
        } else {
            return new Date(itemState.permit.validateFrom!);
        }
    };

    const getValidateTo = () => {
        if (isAfter(new Date(itemState.permit.validateTo!), new Date())) {
            return new Date(itemState.permit.validateTo!);
        }
        return null;
    };

    const [itemState, setItemState] = useState<State>({
        permit: props.permit,
        isValid: false,
        daysOfWeekRecur: getDaysOfWeekRecur(props.permit),
    });

    const initValue = {
        permitType: itemState.permit.permitType,
        vrnNumber: itemState.permit.vrnNumber,
        firstName: itemState.permit.firstName,
        lastName: itemState.permit.lastName,
        fromHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].fromHour),
        toHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].toHour),
        validateFrom: getValidateFrom(),
        validateTo: getValidateTo(),
        endStatus: ValidateToType.infinite as number,
        endAfterValue: itemState.permit.validateToAfterRecurringTime || 1,
        bayNumber: itemState.permit.bayNumber,
        comment: itemState.permit.comment,
    };

    const getCheckedAllDay = () => {
        if (
            itemState.permit.permitRecurring[0] &&
            itemState.permit.permitRecurring[0].fromHour == 0 &&
            itemState.permit.permitRecurring[0].toHour == 86340
        ) {
            return true;
        } else {
            return false;
        }
    };
    const [checkedAllDay, setCheckedAllDay] = useState(getCheckedAllDay());

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedAllDay(event.target.checked);
        if (event.target.checked) {
            setValues({
                ...values,
                fromHour: startOfDay(new Date()),
                toHour: endOfDay(new Date()),
            });
        } else {
            setValues({
                ...values,
                fromHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].fromHour),
                toHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].toHour),
            });
        }
    };
    const formik = useFormik({
        initialValues: initValue,
        validationSchema: useYupSchema().UpsertPermitSchema,
        onSubmit: (values) => {
            // console.log(values);
        },
    });

    const {
        errors,
        touched,
        getFieldProps,
        values,
        setValues,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setErrors,
        setFieldTouched,
    } = formik;

    //get and set recur every
    const getRecurEveryNumber = () => {
        return itemState.permit.permitRecurring[0].recurringEvery;
    };

    const setRecurEveryNumber = (num: number) => {
        const permitRecurs: PermitRecurring[] = _.cloneDeep(itemState.permit.permitRecurring);
        permitRecurs[0].recurringEvery = num;
        setItemState((prev) => ({ ...prev, permit: { ...prev.permit, permitRecurring: permitRecurs } }));
    };

    //get and set RecurType
    const getRecurType = () => {
        return itemState.permit.permitRecurring[0].recurringType!;
    };

    const setRecurType = (recurType: RecurringType) => {
        const permit = _.cloneDeep(itemState.permit);
        permit.permitRecurring[0].recurringType = recurType;
        setItemState((prev) => ({ ...prev, permit: permit }));
    };

    //reset val when change recurType
    useEffect(() => {
        handleResetValWhenChangeRecurType();
    }, [itemState.permit.permitRecurring[0].recurringType]);

    //add
    useEffect(() => {
        if (isAdd === true) {
            const permit = handleCreatePermitFromForm();
            setPermitToParent(permit);
        }
    }, [isAdd]);
    //add

    useEffect(() => {
        if (errors.validateFrom) {
            // formik.handleSubmit();
            setFieldTouched('validateFrom');
        }
    }, [errors]);
    //set Error status
    useEffect(() => {
        handleSetErrorStatusWhenOnchangeVal();
    }, [errors]);

    const handleResetValWhenChangeRecurType = () => {
        if (!isEdit) {
            setRecurEveryNumber(1);
            if (getRecurType() === RecurringType.indefinite) {
                formik.setValues((prev) => ({
                    ...prev,
                    fromHour: startOfDay(new Date()),
                    toHour: endOfDay(new Date()),
                    validateTo: new Date(2999, 1, 1),
                    // endStatus: itemState.permit.validateToType,
                }));
            } else if (getRecurType() === RecurringType.once) {
                formik.setValues((prev) => ({
                    ...prev,
                    fromHour: convertHour.getHoursAndMinutesFromTimeNumber(
                        itemState.permit.permitRecurring[0].fromHour
                    ),
                    toHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].toHour),
                    validateTo: values.validateFrom!,
                    endStatus: itemState.permit.validateToType,
                }));
                //When edit
            } else {
                if (getRecurType() === RecurringType.indefinite || getRecurType() === RecurringType.once) {
                    formik.setValues((prev) => ({
                        ...prev,
                        fromHour: convertHour.getHoursAndMinutesFromTimeNumber(
                            itemState.permit.permitRecurring[0].fromHour
                        ),
                        toHour: convertHour.getHoursAndMinutesFromTimeNumber(
                            itemState.permit.permitRecurring[0].toHour
                        ),
                        validateTo: getValidateTo(),
                        endStatus: itemState.permit.validateToType,
                    }));
                }
            }
        } else {
            //change recurring time
            if (itemState.permit.permitRecurring[0].recurringType! == props.permit.permitRecurring[0].recurringType) {
                setRecurEveryNumber(itemState.permit.permitRecurring[0].recurringEvery!);
            } else {
                setRecurEveryNumber(1);
            }

            //change data
            if (getRecurType() === RecurringType.indefinite) {
                formik.setValues((prev) => ({
                    ...prev,
                    fromHour: startOfDay(new Date()),
                    toHour: endOfDay(new Date()),
                    validateFrom: new Date(),
                    validateTo: new Date(2999, 1, 1),
                    endStatus: itemState.permit.validateToType,
                }));
            } else {
                formik.setValues((prev) => ({
                    ...prev,
                    fromHour: convertHour.getHoursAndMinutesFromTimeNumber(
                        itemState.permit.permitRecurring[0].fromHour
                    ),
                    toHour: convertHour.getHoursAndMinutesFromTimeNumber(itemState.permit.permitRecurring[0].toHour),
                    validateFrom: getValidateFrom(),
                    validateTo: getValidateTo(),
                    endStatus: itemState.permit.validateToType,
                }));
            }
        }
    };

    const handleCreatePermitFromForm = (isDuplicate?: boolean) => {
        const permit = _.cloneDeep(itemState.permit);
        permit.firstName = values.firstName;
        permit.lastName = values.lastName;
        permit.vrnNumber = values.vrnNumber;
        permit.permitType = values.permitType;
        permit.validateFrom = ConvertToGMT0Time(values.validateFrom!);
        permit.permitRecurring[0].fromHour = convertHour.getSecondFromStartOfDay(values.fromHour!);
        permit.permitRecurring[0].toHour = convertHour.getSecondFromStartOfDay(values.toHour!);
        const endAfterVal = values.endAfterValue!;
        permit.validateToType = values.endStatus as ValidateToType;
        permit.comment = values.comment;
        permit.validateToAfterRecurringTime = endAfterVal;

        const recurEvery = getRecurEveryNumber()!;
        permit.permitRecurring[0].permitRecurringDays = [];

        //if gold role set bay no
        if (isGoldRole()) {
            permit.bayNumber = values.bayNumber;
        }

        const convertGmt0ValidateFrom = ConvertToGMT0Time(startOfDay(values.validateFrom!));
        //
        const oldPermitRecurDay = props.permit.permitRecurring[0].permitRecurringDays;
        //Recur Type: once
        if (itemState.permit.permitRecurring[0].recurringType === RecurringType.once) {
            permit.validateTo = ConvertToGMT0Time(values.validateFrom!);
            permit.permitRecurring[0].recurringEvery = 1;

            permit.permitRecurring[0].permitRecurringDays.push({
                id: isEdit ? oldPermitRecurDay[0].id : 0,
                firstExecuteAt: convertGmt0ValidateFrom,
                nextExecuteAt: convertGmt0ValidateFrom,
            });
        }
        //Recur Type: day
        if (itemState.permit.permitRecurring[0].recurringType === RecurringType.day) {
            permit.permitRecurring[0].recurringEvery = recurEvery;
            permit.permitRecurring[0].permitRecurringDays.push({
                id: isEdit ? oldPermitRecurDay[0].id : 0,
                firstExecuteAt: convertGmt0ValidateFrom,
                nextExecuteAt: convertGmt0ValidateFrom,
            });
            if (values.endStatus === ValidateToType.absoluteAt) {
                permit.validateTo = ConvertToGMT0Time(startOfDay(values.validateTo!));
            }
            if (values.endStatus === ValidateToType.recurringTime) {
                permit.validateTo = ConvertToGMT0Time(
                    addDays(startOfDay(values.validateFrom!), recurEvery * (endAfterVal - 1))
                );
            }
            if (values.endStatus === ValidateToType.infinite) {
                permit.validateTo = undefined;
            }
        }
        //Recur type: indefinite
        if (itemState.permit.permitRecurring[0].recurringType === RecurringType.indefinite) {
            permit.permitRecurring[0].fromHour = convertHour.getSecondFromStartOfDay(startOfDay(new Date()));
            permit.permitRecurring[0].toHour = convertHour.getSecondFromStartOfDay(endOfDay(new Date()));
            //set end date
            permit.validateTo = undefined;
            permit.permitRecurring[0].recurringEvery = 1;
            //push recur days
            permit.permitRecurring[0].permitRecurringDays.push({
                id: isEdit ? oldPermitRecurDay[0].id : 0,
                firstExecuteAt: convertGmt0ValidateFrom,
                nextExecuteAt: convertGmt0ValidateFrom,
            });
        }
        //Recur type: week

        if (itemState.permit.permitRecurring[0].recurringType === RecurringType.week) {
            permit.permitRecurring[0].recurringEvery = recurEvery;
            const weekNumberArr = itemState.daysOfWeekRecur.map((item) => moment(item).isoWeekday());

            const dateArr = genDate(values.validateFrom!).filter((date) =>
                weekNumberArr.includes(moment(date).isoWeekday())
            );

            if (!isEdit) {
                dateArr.map((item) => {
                    permit.permitRecurring[0].permitRecurringDays.push({
                        id: 0,
                        firstExecuteAt: ConvertToGMT0Time(startOfDay(item)),
                        nextExecuteAt: ConvertToGMT0Time(startOfDay(item)),
                    });
                });
            } else {
                dateArr.map((item, index) => {
                    var temp = cloneDeep(item);
                    if (values.validateTo != null && moment(item).isAfter(values.validateTo)) {
                        temp = new Date('0001-01-01 00:00:00.000');
                    }
                    permit.permitRecurring[0].permitRecurringDays.push({
                        id: 0,
                        firstExecuteAt: ConvertToGMT0Time(startOfDay(item)),
                        nextExecuteAt: ConvertToGMT0Time(startOfDay(temp)),
                    });
                });
            }

            if (values.endStatus === ValidateToType.absoluteAt) {
                permit.validateTo = ConvertToGMT0Time(startOfDay(values.validateTo!));
            }
            if (values.endStatus === ValidateToType.recurringTime) {
                permit.validateTo = ConvertToGMT0Time(
                    startOfDay(addDays(dateArr[dateArr.length - 1], recurEvery * 7 * (endAfterVal - 1)))
                );
            }
            if (values.endStatus === ValidateToType.infinite) {
                permit.validateTo = undefined;
            }
        }

        //Recur type: month
        if (itemState.permit.permitRecurring[0].recurringType === RecurringType.month) {
            permit.permitRecurring[0].recurringEvery = recurEvery;
            const defaultDay = new Date(values.validateFrom!.getFullYear(), getMonth(values.validateFrom!), recurEvery);
            //check start date and no of day to calculating first execute day
            const firstExecuteAt = recurEvery < getDate(values.validateFrom!) ? addMonths(defaultDay, 1) : defaultDay;
            //set first recur day
            permit.permitRecurring[0].permitRecurringDays.push({
                id: isEdit ? oldPermitRecurDay[0].id : 0,
                firstExecuteAt: ConvertToGMT0Time(startOfDay(firstExecuteAt)),
                nextExecuteAt: ConvertToGMT0Time(startOfDay(firstExecuteAt)),
            });
            if (values.endStatus === ValidateToType.absoluteAt) {
                permit.validateTo = ConvertToGMT0Time(startOfDay(values.validateTo!));
            }
            if (values.endStatus === ValidateToType.recurringTime) {
                permit.validateTo = ConvertToGMT0Time(startOfDay(addMonths(firstExecuteAt, endAfterVal - 1)));
            }
            if (values.endStatus === ValidateToType.infinite) {
                permit.validateTo = undefined;
            }
        }

        return permit;
    };

    const handleSetErrorStatusWhenOnchangeVal = () => {
        const listErr = _.cloneDeep(validations);
        const index = listErr.findIndex((item) => item.id === props.permit.id);

        if (listErr.length > 0 && index >= 0) {
            if (Object.keys(errors).length > 0) {
                setItemState((prev) => ({ ...prev, isValid: false }));
                listErr[index].isValid = false;
            } else {
                setItemState((prev) => ({ ...prev, isValid: true }));
                listErr[index].isValid = true;
            }
            setState((prev) => ({ ...prev, validations: listErr }));
        }
    };

    const setPermitToParent = (permit: Permit) => {
        const per = _.cloneDeep(permit);
        const listPermit = [...permits];
        const arr = permitsAddSuccess;
        const index = listPermit.findIndex((item) => item.id === props.permit.id);
        if (index >= 0) {
            listPermit[index] = per;
        }
        arr.push(per);
        setState((prev) => ({ ...prev, permitsAddSuccess: arr }));
    };

    const onDuplicatePermit = () => {
        const permit = handleCreatePermitFromForm(true);
        handleDuplicatePermit(permit, false);
    };

    const listRecur: SelectItem[] = [
        {
            value: RecurringType.once,
            label: t('permits:text.oneDayPermit'),
            description: t('permits:text.schedulePermitsForOneDayOnly'),
        },
        {
            value: RecurringType.day,
            label: t('permits:text.daily'),
            description: t('permits:text.schedulePermitsToRecurEveryXNumberOfDays'),
        },
        {
            value: RecurringType.week,
            label: t('permits:text.weekly'),
            description: t('permits:text.schedulePermitsToRecurOnSpecifiedDaysOfTheWeek'),
        },
        {
            value: RecurringType.month,
            label: t('permits:text.monthly'),
            description: t('permits:text.schedulePermitsToRecurOnAParticularDateOfTheMonth'),
        },
        {
            value: RecurringType.indefinite,
            label: t('permits:text.indefinite'),
            description: t('permits:text.schedulePermitsToRecurIndefinitelyAtAllTimes'),
        },
    ];

    const getRecurTypeFromSelect = () => {
        return listRecur.find((item) => item.value === itemState.permit.permitRecurring[0].recurringType);
    };

    const genDate = (date: Date) => {
        const rs = Array.from(new Array(7)).map((a, index) => {
            return moment(date).add(index, 'day').toDate();
        });

        return rs;
    };

    const recurSelect = useAutoComplete2({
        key: props.permit.id,
        label: <LabelWithRequired label={t('permits:text.recurringType')} />,
        listData: listRecur,
        value: getRecurTypeFromSelect(),
        setFilter: (val) => setRecurType(val),
    });

    return {
        ...itemState,
        setItemState,
        recurSelect,
        itemState,
        t,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setSubmitting,
        formik,
        setErrors,
        values,
        setValues,
        getRecurEveryNumber,
        setRecurEveryNumber,
        getDaysOfWeekRecur,
        onDuplicatePermit,
        setFieldTouched,
        handleChange,
        checkedAllDay,
    };
}

export const UpsertPermitItemContext = createContext<ReturnType<typeof useUpsertPermitItem>>({} as any);
