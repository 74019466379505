import React from 'react';
import { Outlet } from 'react-router-dom';
import usePermitsFilter, { PermitFilterContext } from './hooks/usePermitsFilter';

export default function FilterPermitProvider() {
    const permitContext = usePermitsFilter();
    return (
        <PermitFilterContext.Provider value={permitContext}>
            <Outlet />
        </PermitFilterContext.Provider>
    );
}
