/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import ZoneItemModal from '../ZoneItemModal';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { TenantWithAllocate, AllocatedPermit } from '../../../../../models/tenant/Tenant';
import { CompaniesContext } from '../../../../../hooks/useCompanies';
import { LocationWithDetail, LocationWithZones } from '../../../../../models/Location';
import { shopController } from '../../../../../controllers';
import { LocationsContext } from '../../../../../hooks/useLocations';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import useMedia from '../../../../../hooks/useMedia';
import Zone from '../../../../../models/Zone';
import _ from 'lodash';
import LabelWithRequired from '../../../../../components/LabelWithRequired';
import { TenantContext } from '../../../../../hooks/useTenants';

type Props = {
    handleClose: () => void;
    tenant: TenantWithAllocate;
};
interface State {
    locationWithZone?: LocationWithDetail | undefined;
    zonesSelect?: Zone[];
    tenant: TenantWithAllocate;
    isEdit: boolean;
}

export interface ZoneValid {
    id: number;
    isValid: boolean;
}

export interface ItemChecked {
    id: number;
    checked: boolean;
}

export default function ModalAddTenant(props: Props) {
    const { locationSelect, refreshLocationDetail, zonesSelect } = useContext(CompaniesContext);
    const { refreshDataTenant, tenantPagination } = useContext(TenantContext);
    const [isValidation, setIsValidation] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { isExtraSmallXs, isMediumMD } = useMedia();
    const [valids, setValids] = useState<ZoneValid[]>([]);
    const [checks, setChecks] = useState<ItemChecked[]>([]);
    const [state, setState] = useState<State>({
        tenant: props.tenant,
        locationWithZone: locationSelect,
        zonesSelect: zonesSelect,
        isEdit: false,
    });
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        if (props.tenant.clientId !== 0) {
            setState((prev) => ({ ...prev, isEdit: true }));
        }
    }, [props.tenant]);

    const { t } = useContext(LanguageContext);

    const schema = Yup.object({
        tenantName: Yup.string().required(t('validation:tenantNameIsRequired')),
        managerName: Yup.string().required(t('validation:managerNameIsRequired')),
        email: Yup.string()
            .email(`${t('validation:invalidEmailFormat')}`)
            .required(`${t('validation:emailIsRequired')}`),
    });

    useEffect(() => {
        const listValid: ZoneValid[] = [];

        state.zonesSelect &&
            state.zonesSelect.length > 0 &&
            state.zonesSelect.map((item, index) => {
                if (props.tenant.clientId !== 0) {
                    listValid.push({ id: item.id, isValid: true });
                } else {
                    const info = getLastTenantZoneInfo(item);

                    listValid.push({
                        id: item.id,
                        isValid: info.maxZonePermit == 0 || info.remaining == 0 ? true : false,
                    });
                }
            });
        setValids(listValid);
    }, [props.tenant]);

    const getLastTenantZoneInfo = (zone: Zone) => {
        const list = tenantPagination.data;
        const tenantIndex = list.findIndex((item) => item.clientId == props.tenant.clientId);
        const remaining = zone.maxPermits - zone.allocated;
        let info = {
            remaining: remaining,
            maxZonePermit: zone.maxPermits,
            used: 0,
            allocatedAmount: 0,
        };

        if (tenantIndex >= 0) {
            const currentZone = list[tenantIndex].allocatedPermits?.find((tenant) => tenant.zone?.id == zone.id);
            if (currentZone) {
                info = {
                    remaining: remaining + currentZone.allocatedAmount,
                    maxZonePermit: zone.maxPermits,
                    used: currentZone.usedPermit!,
                    allocatedAmount: currentZone.allocatedAmount,
                };
            }
        }
        return info;
    };

    useEffect(() => {
        const itemCheck: ItemChecked[] = [];
        state.zonesSelect &&
            state.zonesSelect.length > 0 &&
            state.zonesSelect.map((item, index) => {
                itemCheck.push({ id: item.id, checked: true });
            });
        setChecks(itemCheck);
    }, [props.tenant]);

    const defaultVal = {
        tenantName: props.tenant.tenantName,
        managerName: props.tenant.managerName,
        email: props.tenant.email,
        phoneNumber: props.tenant.phoneNumber,
    };

    const {
        control,
        formState: { errors, isValid },
        setError,
        getValues,
    } = useForm({
        mode: 'all',
        defaultValues: defaultVal,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        let isValidAll = true;
        valids.length > 0 &&
            valids.map((item) => {
                if (item.isValid == false) {
                    isValidAll = false;
                }
            });
        isValidAll == true && isValid == true ? setIsValidation(true) : setIsValidation(false);
    }, [valids, isValid]);

    const handleChange = (zoneWithMaxPermits: AllocatedPermit) => {
        const listZonesWithPermit = _.cloneDeep(state.tenant.allocatedPermits!);

        listZonesWithPermit.map((item, index) => {
            if (item.zoneId == zoneWithMaxPermits.zoneId) {
                listZonesWithPermit[index] = zoneWithMaxPermits;
            }
        });

        setState((prev) => ({
            ...prev,
            tenant: { ...prev.tenant, allocatedPermits: listZonesWithPermit },
        }));
    };

    const handleSubmitForm = () => {
        const tenantCreate = _.cloneDeep(state.tenant);

        tenantCreate.allocatedPermits!.map((item) => {
            delete item.isValid;
            if (state.isEdit) {
                item.shopClientId = props.tenant.clientId;
            }
        });
        setLoading(true);
        shopController
            .upsert(tenantCreate)
            .then((res) => {
                //close popup
                props.handleClose();
                //refresh tenant
                refreshDataTenant();
                //refresh location detail
                refreshLocationDetail();
                //popup success
                toast.success(t('notification:successfully'));
                setLoading(false);
            })
            .catch((res) => {
                toast.error(t('notification:fail'));
                setError('email', { message: t('validation:emailIsAlreadyRegistered') });
                setLoading(false);
            });
    };

    useEffect(() => {
        const total = state.tenant.allocatedPermits!.reduce((t, c) => (t += c.allocatedAmount), 0);
        setTotal(total);
    }, [state.tenant.allocatedPermits]);

    return (
        <Box sx={{ padding: '40px 24px' }}>
            <Grid container gap={{ xs: 1, md: 3 }}>
                <Typography variant="h4" sx={{ textAlign: 'center', width: '100%' }}>
                    {state.isEdit ? t('companies:tenants.editTenant') : t('companies:tenants.addTenant')}
                </Typography>
                <Grid
                    item
                    container
                    xs={12}
                    mt={2}
                    sx={{
                        '& .MuiFormHelperText-root': {
                            position: 'absolute',
                            top: '40px',
                            left: '-10px',
                            fontSize: isExtraSmallXs || isMediumMD ? '10px' : '12px',
                        },
                    }}
                >
                    <Grid item container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'tenantName'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.tenantName?.message}
                                        helperText={errors.tenantName?.message as any}
                                        fullWidth
                                        label={<LabelWithRequired label={t('companies:tenants.tenantName')} />}
                                        placeholder={t('input:enterFullName')}
                                        onChange={(e) => {
                                            setState((prev) => ({
                                                ...prev,
                                                tenant: {
                                                    ...prev.tenant,
                                                    tenantName: e.target.value,
                                                },
                                            }));
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        value={state.tenant.tenantName}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'managerName'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.managerName?.message}
                                        helperText={errors.managerName?.message as any}
                                        fullWidth
                                        label={<LabelWithRequired label={t('companies:tenants.managerName')} />}
                                        placeholder={t('input:enterManagerName')}
                                        onChange={(e) => {
                                            setState((prev) => ({
                                                ...prev,
                                                tenant: {
                                                    ...prev.tenant,
                                                    managerName: e.target.value,
                                                },
                                            }));
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        value={state.tenant.managerName}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} my={2}>
                            <Controller
                                control={control}
                                name={'email'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.email?.message}
                                        helperText={errors.email?.message as any}
                                        fullWidth
                                        disabled={state.isEdit}
                                        label={<LabelWithRequired label={t('companies:tenants.emailOfTenant')} />}
                                        inputProps={{
                                            maxLength: 320,
                                        }}
                                        placeholder={t('input:enterEmailOfTenant')}
                                        onChange={(e) => {
                                            setState((prev) => ({
                                                ...prev,
                                                tenant: {
                                                    ...prev.tenant,
                                                    email: e.target.value,
                                                },
                                            }));
                                            field.onChange(e);
                                        }}
                                        value={state.tenant.email}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} my={2}>
                            <Controller
                                control={control}
                                name={'phoneNumber'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.phoneNumber?.message}
                                        helperText={errors.phoneNumber?.message as any}
                                        fullWidth
                                        label={t('companies:text.phoneNumber')}
                                        placeholder={t('input:enterContactNumber')}
                                        onChange={(e) => {
                                            setState((prev) => ({
                                                ...prev,
                                                tenant: {
                                                    ...prev.tenant,
                                                    phoneNumber: e.target.value,
                                                },
                                            }));
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 11,
                                        }}
                                        value={state.tenant.phoneNumber}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {t('companies:text.zones')}
                    </Typography>
                    <Grid
                        item
                        container
                        sx={{ mt: 1, background: color.grey100, borderRadius: '10px' }}
                        padding={2}
                        gap={{ xs: 1, md: 4 }}
                    >
                        {state.zonesSelect &&
                            state.zonesSelect.length > 0 &&
                            state.zonesSelect.map((item, index) => {
                                // setValids(listValid);
                                return (
                                    <ZoneItemModal
                                        index={index}
                                        valids={valids}
                                        setValids={setValids}
                                        isEdit={state.isEdit}
                                        tenant={props.tenant}
                                        zoneAndPermit={state.tenant.allocatedPermits![index]}
                                        handleChange={handleChange}
                                        zone={item}
                                        key={item.id}
                                        totalAllocated={total}
                                        checks={checks}
                                        setChecks={setChecks}
                                    />
                                );
                            })}
                    </Grid>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'row'} justifyContent="space-between">
                    <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={props.handleClose}>
                        {t('action:cancel')}
                    </Button>
                    {/* <Button
                        variant="contained"
                        disabled={!isValidation}
                        sx={{ minWidth: '150px' }}
                        onClick={handleSubmitForm}
                    >
                        {t('action:save')}
                    </Button> */}
                    <LoadingButton
                        loading={isLoading}
                        startIcon={<></>}
                        loadingPosition="start"
                        variant="contained"
                        disabled={!isValidation}
                        sx={{ minWidth: '150px' }}
                        type="submit"
                        onClick={handleSubmitForm}
                    >
                        {t('action:save')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
}
