import { useContext } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { LanguageContext } from '../contexts/useLanguage';

export default function useYupSchema() {
    const { t } = useContext(LanguageContext);

    const UpsertPermitSchema = Yup.object().shape({
        permitType: Yup.string().required(`${' '}`),
        vrnNumber: Yup.string().required(t('validation:thisFieldIsRequired')),
        firstName: Yup.string().required(t('validation:firstNameIsRequired')),
        lastName: Yup.string().required(t('validation:lastNameIsRequired')),
        endStatus: Yup.number(),
        validateFrom: Yup.date()
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        validateTo: Yup.date()
            .nullable()
            .when('endStatus', {
                is: 0,
                then: Yup.date()
                    .required(t('validation:thisFieldIsRequired'))
                    .min(Yup.ref('validateFrom'), t('validation:endDateMustBeGreaterThanOrEqualToTheStartDate'))
                    .typeError(t('validation:thisFieldIsRequired')),
            }),
        fromHour: Yup.date()
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
        toHour: Yup.date()
            .when('fromHour', (fromHour, schema) => {
                if (moment(fromHour, 'YYYY-MM-DD').isValid()) {
                    const fromTime = new Date(fromHour.getTime());
                    const validateTime = moment(fromTime).add(1, 'minutes').toDate();
                    return schema
                        .typeError(t('validation:thisFieldIsRequired'))
                        .min(validateTime, t('validation:endTimeMustBeGreaterThanStartTime'))
                        .required(t('validation:thisFieldIsRequired'));
                } else {
                    return schema;
                }
            })
            .typeError(t('validation:thisFieldIsRequired'))
            .required(t('validation:thisFieldIsRequired')),
    });
    return { UpsertPermitSchema };
}
