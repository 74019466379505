import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../contexts/useLanguage';
import { companiesController, locationController, zoneController } from '../controllers';
import { FilterCompany, Pagination } from '../models/BaseFilter';
import { Company, CompanyWithDetail } from '../models/Company';
import { Location, LocationWithDetail } from '../models/Location';
import Zone from '../models/Zone';

interface State {
    companiesPagination: Pagination<CompanyWithDetail>;
    filter: FilterCompany;
    locationSelect?: LocationWithDetail;
    zonesSelect?: Zone[];
    companyId?: number;
    companySelect?: Company;
    isLoading: boolean;
    isReloadLocationSelect: boolean;
    idLocationSelect: number;
    listLocation: Location[];
}

export default function useCompanies() {
    const [state, setState] = useState<State>({
        companiesPagination: {} as any,
        filter: {
            page: 1,
            pageSize: 6,
            search: {
                fields: ['displayName'],
                value: '',
            },
            filter: {
                locationId: 0,
            },
        },
        locationSelect: {} as any,
        isLoading: true,
        isReloadLocationSelect: false,
        idLocationSelect: 0,
        companySelect: {} as any,
        listLocation: [],
    });

    const { idCompany } = useParams();
    const { t } = useContext(LanguageContext);

    const GetCompanyDetail = (idCompany: number) => {
        companiesController.getCompaniesInfo(idCompany).then((res) => {
            setState((prev) => ({ ...prev, companySelect: res }));
        });
    };

    const setCompanyId = (idCompany: number) => {
        setState((prev) => ({ ...prev, companyId: idCompany }));
    };

    const getLocationDetail = (idLocation: number) => {
        if (idLocation !== 0) {
            locationController.getLocation(idLocation).then((res) => {
                zoneController.getZonesDetail(idLocation).then((resZone) => {
                    setState((prev) => ({
                        ...prev,
                        locationSelect: res,
                        idLocationSelect: idLocation,
                        zonesSelect: resZone,
                        isReloadLocationSelect: false,
                    }));
                });
            });
        }
    };

    useEffect(() => {
        if (state.isReloadLocationSelect === true) {
            getLocationDetail(state.idLocationSelect);
        }
    }, [state.idLocationSelect, state.isReloadLocationSelect]);

    useEffect(() => {
        setState((prev) => ({ ...prev, isLoading: true }));

        companiesController.filter(state.filter).then((res) => {
            setState((prev) => ({ ...prev, companiesPagination: res, isLoading: false }));
        });
    }, [state.filter]);

    useEffect(() => {
        if (idCompany) {
            GetCompanyDetail(Number(idCompany));
        }
    }, [idCompany]);

    const refreshLocationDetail = () => {
        setState((prev) => ({ ...prev, isReloadLocationSelect: true }));
    };

    return { ...state, setState, setCompanyId, getLocationDetail, refreshLocationDetail };
}

export const CompaniesContext = React.createContext<ReturnType<typeof useCompanies>>({} as any);
