import { Box, Grid, Pagination, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import MyTable from '../../../../MyTable';

import { ReportType } from '../../../../../models/ReportType';
import Zone from '../../../../../models/Zone';
import MyModal from '../../../../../components/modal/MyModal';
import useModal from '../../../../../components/modal/useModal';
import UpdatePermitsModal from '../UpdatePermitsModal';
import { CompaniesContext } from '../../../../../hooks/useCompanies';
import NoDataToDisplay from '../../../../../components/NoDataToDisplay';
import color from '../../../../../config/Colors';
import TableZones from './TableZones';

export default function ZonesTab() {
    const { t } = useContext(LanguageContext);
    const updatePermitModal = useModal();
    const [zone, setZone] = useState<Zone>({} as any);
    const { zonesSelect, refreshLocationDetail } = useContext(CompaniesContext);
    const handleOpenPermitModal = (zone: Zone) => {
        setZone(zone);
        updatePermitModal.handleOpen();
    };

    useEffect(() => {
        refreshLocationDetail();
    }, []);

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        // setState((prev) => ({ ...prev, filter: { ...prev.filter, page: value } }));
    };

    return (
        <Grid item container xs={12}>
            <MyModal
                key={1}
                open={updatePermitModal.open}
                handleOpen={updatePermitModal.handleOpen}
                handleClose={updatePermitModal.handleClose}
                modalContent={<UpdatePermitsModal zone={zone} handleClose={updatePermitModal.handleClose} />}
            />

            {zonesSelect && zonesSelect.length > 0 ? (
                <>
                    <MyTable
                        tableHead={[
                            {
                                label: t('companies:text.zoneName'),
                                alight: 'center',
                            },
                            {
                                label: t('companies:text.type'),
                                alight: 'center',
                            },
                            {
                                label: t('companies:text.serviceType'),
                                alight: 'center',
                            },
                            {
                                label: t('companies:text.totalBays'),
                                alight: 'center',
                            },
                            {
                                label: t('companies:tenants.allocatedPermits'),
                                alight: 'center',
                            },
                        ]}
                        reportType={ReportType.dailyVisit}
                        tableRow={<TableZones handleEdit={handleOpenPermitModal} />}
                    />
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                        <Pagination
                            count={1}
                            page={1}
                            onChange={handleChangePagination}
                            variant="outlined"
                            shape="rounded"
                            sx={{ mt: 3, mb: '40px' }}
                        />{' '}
                    </Stack>
                </>
            ) : (
                <Box padding={'16px'} width={'100%'}>
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                </Box>
            )}
        </Grid>
    );
}
