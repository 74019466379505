import { Box, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { SelectItem } from '../components/MySelect';
import color from '../config/Colors';

type Props = {
    list: SelectItem[];
    label?: string;
    setFilter: (val: number) => void;
};

export default function useSelection(props: Props) {
    const [index, setIndex] = React.useState(0);

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setIndex(event.target.value as number);
        props.setFilter(event.target.value as number);
    };

    const RenderSelect = props.list.length > 0 && (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
                <InputLabel
                    id="demo-multiple-checkbox-label"
                    sx={{
                        background: color.grey100,
                    }}
                >
                    {props?.label || 'Tag'}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleChange}
                    defaultValue={props.list[0].value}
                >
                    {props.list.map((item, index) => {
                        return [
                            <MenuItem value={item.value} key={item.value}>
                                {item.label}
                            </MenuItem>,
                            index === props.list.length - 1 ? (
                                ''
                            ) : (
                                <Divider
                                    sx={{
                                        my: '2px !important',
                                        borderColor: color.grey200,
                                        mx: '3px',
                                    }}
                                />
                            ),
                        ];
                    })}
                </Select>
            </FormControl>
        </Box>
    );

    return { RenderSelect, index };
}
