import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import apiAccount from '../config/Api';
import { Account } from '../models/Account';
import { ChangePassword, Login } from '../models/Login';

const fakeJwt =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxIiwibmJmIjoxNjU2OTgzOTkwLCJleHAiOjE2NTk1NzU5OTAsImlhdCI6MTY1Njk4Mzk5MH0.1r64Sdn_agh6PdOaL6sHvOhoYeQhnArM3kybOffigAI';
const fakeAccount: Account = {
    displayName: 'Starr',
    email: 'kienNguyen@gmail.com',
    firstName: "Kien",
    lastName: "Nguyen",
    lastLogin: '15/06/2022 13:15',
    password: '123',
    phoneNumber: '0963740256',
    accountId: 1
};

export class AccountController extends BaseHttpController<Account> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'product', client);
    }

    async login(login: Login): Promise<string> {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/Login`, login);
        return res.data.token
    }

    async sendLinkToMail(email: string) {
        const res = await this.client.post(
            `${this.serviceURL}${apiAccount.account}/GetLinkResetPassword?email=${email}`
        );
        return res;
    }

    async updateAccount(account: Account): Promise<Account> {
        const res = await this.client.post(
            `${this.serviceURL}${apiAccount.account}`, account
        );
        return res.data;
    }

    async checkTokenIsValid(token: string) {
        const res = await this.client.post(
            `${this.serviceURL}${apiAccount.account}/CheckTokenResetPassword?token=${token}`
        );
        return res.data;
    }

    async changePass(changePass: ChangePassword) {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/ChangePass`, changePass);
        return res.data;
    }

    async changePassAccount(changePass: ChangePassword) {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/ChangePassAccount`, changePass);
        return res.data;
    }

    async upsertAvatar(formData: FormData) {
        const res = await this.client.post(`${this.serviceURL}${apiAccount.account}/UploadAvatar`, formData);
        return res.data;
    }

}
