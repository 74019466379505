import React from 'react';
import useFilter from '../hooks/useFilter';

export const GROSS_REVENUE_CROSS_LOCATION_ID = Number(process.env.REACT_APP_LOCATION_ID);
export default function useFilterContext() {
    const {
        filter,
        FilterComponents,
        dailyVisitsStatistic,
        averageLengthStatistic,
        capacityAnalysis,
        repeatVisits,
        groupPCNByZone,
        groupPCNByReason,
        groupByPNCType,
        groupByPCNCancellations,
        listCompany,
        listLocation,
        listRegion,
        listZone,
        setReportState,
        reportState,
        grossRevenueStatistic,
        loadingRepeatVisit,
    } = useFilter();
    return {
        filter,
        FilterComponents,
        dailyVisitsStatistic,
        averageLengthStatistic,
        capacityAnalysis,
        repeatVisits,
        groupPCNByZone,
        groupPCNByReason,
        groupByPNCType,
        groupByPCNCancellations,
        setReportState,
        listCompany,
        listLocation,
        listRegion,
        listZone,
        reportState,
        grossRevenueStatistic,
        loadingRepeatVisit,
    };
}

export const FilterContext = React.createContext<ReturnType<typeof useFilterContext>>({} as any);
