import { Button, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { useFormik, Form, FormikProvider } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import ButtonHidePassword from '../../../components/ButtonHidePassword';
import { AuthContext } from '../../../hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import LabelWithRequired from '../../../components/LabelWithRequired';

type Props = {
    handleClose: () => void;
};
interface State {
    showCurrentPassword: boolean;
    showPassword: boolean;
    showRePassword: boolean;
}

export default function ChangePasswordForm({ handleClose }: Props) {
    const { account, changePassAccount } = useContext(AuthContext);
    const [state, setState] = useState<State>({
        showPassword: false,
        showRePassword: false,
        showCurrentPassword: false,
    });
    const { t } = useContext(LanguageContext);

    const RegisterSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required(t('validation:currentPasswordIsRequired'))
            .min(8, t('validation:passwordsMustHaveAtLeast8Characters')),
        password: Yup.string()
            .required(t('validation:passwordIsRequired'))
            .min(8, t('validation:passwordsMustHaveAtLeast8Characters')),
        repass: Yup.string()
            .required(t('validation:pleaseRetypeYourPass'))
            .min(8, t('validation:passwordsMustHaveAtLeast8Characters'))
            .oneOf([Yup.ref('password')], t('validation:theNewPasswordAndTheConfirmPasswordAreNotMatching')),
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            repass: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            if (account) {
                changePassAccount({
                    id: account.accountId.toString(),
                    password: values.password,
                    oldPassword: values.currentPassword,
                })
                    .then((res) => {
                        toast.success(t('notification:passwordChangedSuccessfully'));
                        handleClose();
                    })
                    .catch((res) => {
                        setErrors({ ...errors, currentPassword: t('validation:yourCurrentPasswordIsNotCorrect') });
                        setSubmitting(false);
                        // toast.error('Fail');
                    });
            }
        },
    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setSubmitting, setErrors } = formik;

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };
    const handleClickShowRePassword = () => {
        setState({
            ...state,
            showRePassword: !state.showRePassword,
        });
    };
    const handleClickShowCurrentPassword = () => {
        setState({
            ...state,
            showCurrentPassword: !state.showCurrentPassword,
        });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Box p={'40px 24px'}>
            <FormikProvider value={formik}>
                <Form noValidate onSubmit={handleSubmit}>
                    <Grid
                        container
                        sx={{
                            '& .MuiFormHelperText-root': {
                                mx: 0,
                                fontSize: '12px',
                            },
                        }}
                    >
                        <Grid item xs={12} gap={4} p={'10px'}>
                            <Grid item xs={12}>
                                <Typography align="center" variant="h3">
                                    {t('accountPage:title.changePassword')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mt={{ xs: 2, md: 3, xl: 4 }}>
                            <TextField
                                id="outlined-required1"
                                label={<LabelWithRequired label={t('accountPage:text.currentPassword')} />}
                                placeholder={t('input:enterPassword')}
                                sx={{}}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                fullWidth
                                size="small"
                                type={state.showCurrentPassword ? 'text' : 'password'}
                                {...getFieldProps('currentPassword')}
                                error={Boolean(touched.currentPassword && errors.currentPassword)}
                                helperText={touched.currentPassword && errors.currentPassword}
                                onChange={formik.handleChange}
                                value={formik.values.currentPassword}
                                InputProps={{
                                    endAdornment: (
                                        <ButtonHidePassword
                                            isShowPassword={state.showCurrentPassword}
                                            onClick={handleClickShowCurrentPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt={{ xs: 2, md: 3, xl: 4 }}>
                            <TextField
                                id="outlined-required2"
                                label={<LabelWithRequired label={t('accountPage:text.newPassword')} />}
                                placeholder={t('input:enterNewPassword')}
                                sx={{}}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                fullWidth
                                size="small"
                                type={state.showPassword ? 'text' : 'password'}
                                {...getFieldProps('password')}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                InputProps={{
                                    endAdornment: (
                                        <ButtonHidePassword
                                            isShowPassword={state.showPassword}
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt={{ xs: 2, md: 3, xl: 4 }}>
                            <TextField
                                id="outlined-required3"
                                label={<LabelWithRequired label={t('accountPage:text.confirmNewPassword')} />}
                                placeholder={t('input:enterNewPassword')}
                                sx={{}}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                fullWidth
                                size="small"
                                type={state.showRePassword ? 'text' : 'password'}
                                {...getFieldProps('repass')}
                                error={Boolean(touched.repass && errors.repass)}
                                helperText={touched.repass && errors.repass}
                                onChange={formik.handleChange}
                                value={formik.values.repass}
                                InputProps={{
                                    endAdornment: (
                                        <ButtonHidePassword
                                            isShowPassword={state.showRePassword}
                                            onClick={handleClickShowRePassword}
                                            onMouseDown={handleMouseDownPassword}
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            mt={{ xs: 2, md: 3, xl: 4 }}
                        >
                            <Button sx={{ minWidth: '150px' }} variant="cancel" onClick={handleClose}>
                                {t('action:cancel')}
                            </Button>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                sx={{ minWidth: '150px' }}
                                type="submit"
                            >
                                {t('action:save')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </Box>
    );
}
