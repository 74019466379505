import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import BarChartHorizontal from '../../../components/chart/BarChartHorizontal';
import NoDataToDisplay from '../../../components/NoDataToDisplay';
import { LanguageContext } from '../../../contexts/useLanguage';
import useMedia from '../../../hooks/useMedia';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { StatisticItem } from '../../../models/StatisticItems';
import { GridBorderRadius } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import ColorBoxCircle from './ColorBoxCircle';

type Props = {
    chartShowType?: ChartShowType;
    reportType?: ReportType;
    groupPCNByZone?: StatisticItem[];
};

export default function PCNByZones(props: Props) {
    const { t } = useContext(LanguageContext);
    const { isMobileSM, isMediumMD } = useMedia();

    return (
        <GridBorderRadius item container mt={{ xs: 2, sm: 3, md: 4 }}>
            <Grid item xs={12}>
                <ChartTitleContent
                    key={'2'}
                    title={
                        props.groupPCNByZone && props.groupPCNByZone.length <= 5
                            ? t('dashboard:title.PCNByZones')
                            : t('dashboard:title.top5SitesWithTheMostIssued')
                    }
                    desc={t('dashboard:text.numberOfParkingChargesRaisedBySiteBasedOnContraventionDateAndZones​')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    chartShowType={props.chartShowType || ChartShowType.dashboard}
                    reportType={ReportType.PCNByZones}
                />
            </Grid>

            {props.groupPCNByZone && props.groupPCNByZone.length <= 0 ? (
                <Grid item container mt={0}>
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                </Grid>
            ) : (
                <Grid item container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item container height={!isMobileSM ? 300 : 230} mt={1}>
                        <BarChartHorizontal groupPCNByZone={props.groupPCNByZone?.slice(0, 5) || []} />
                    </Grid>

                    <Grid item mt={3} fontSize={{ xs: '12px', sm: '14px', lg: '14px' }}>
                        <ColorBoxCircle color="#3EB6E4" /> {t('dashboard:text.chargesIssued')}
                    </Grid>
                </Grid>
            )}
        </GridBorderRadius>
    );
}
