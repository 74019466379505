import React from 'react';
import { Outlet } from 'react-router-dom';
import useLocations, { LocationsContext } from '../../../hooks/useLocations';

export default function LocationLayout() {
    const locationContextData = useLocations();
    return (
        <LocationsContext.Provider value={locationContextData}>
            <Outlet />
        </LocationsContext.Provider>
    );
}
