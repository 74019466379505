import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../contexts/useLanguage';
import AverageLengthOfVisits from './components/AverageLengthOfVisits';
import CapacityAnalysis from './components/CapacityAnalysisChart';
import DailyVisits from './components/DailyVisits';
import RepeatVisits from './components/RepeatVisits';
import { ReportType } from '../../models/ReportType';
import { FilterContext, GROSS_REVENUE_CROSS_LOCATION_ID } from '../../contexts/FilterContext';
import { DrawerContext } from '../dashboardLayout/useDrawer';
import Footer from '../dashboardLayout/components/Footer';
import useMedia from '../../hooks/useMedia';
import useScrollToTop from '../../hooks/useScrollToTop';
import PCNTypesChar from './components/PCNTypesChar';
import PCNCancellationsChart from './components/PCNCancellationsChart';
import PCNByZones from './components/PCNByZones';
import GroupPCNByReason from './components/GroupPCNByReason';
import { appConfig } from '../../config/AppConfig';
import EVDailyVisits from './components/EVDailyVisits';
import EVLengthOfVisits from './components/EVLengthOfVisits';
import GrossRevenueChart from './components/GrossRevenueChart';

export default function Dashboard() {
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);
    const filterData = useContext(FilterContext);
    const {
        FilterComponents,
        dailyVisitsStatistic,
        averageLengthStatistic,
        capacityAnalysis,
        repeatVisits,
        groupPCNByZone,
        groupPCNByReason,
        groupByPNCType,
        groupByPCNCancellations,
        grossRevenueStatistic,
        setReportState,
        filter,
    } = filterData;
    const { isMediumMD } = useMedia();
    useScrollToTop();

    useEffect(() => {
        setActiveItem(1);
        setReportState((prev) => ({ ...prev, showingPage: 'dashboard', report: ReportType.none }));
    }, []);

    return (
        <>
            <FilterContext.Provider value={filterData}>
                <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                    <Typography variant="h3" mb={2} fontWeight={400}>
                        {t('dashboard:title.dashboard')}
                    </Typography>

                    <Grid container mt={2}>
                        {FilterComponents}
                        <DailyVisits dailyVisitsStatistic={dailyVisitsStatistic} reportType={ReportType.dailyVisit} />
                        <Grid
                            container
                            item
                            direction={'row'}
                            justifyContent={'space-between'}
                            lg={12}
                            mt={{ xs: 2, sm: 3, md: 4 }}
                        >
                            <Grid item container mb={isMediumMD ? 2 : 0} lg={5.82}>
                                <AverageLengthOfVisits
                                    averageLengthStatistic={averageLengthStatistic}
                                    reportType={ReportType.averageLength}
                                />
                            </Grid>
                            <Grid item container lg={5.82} mt={{ md: 3, lg: 0 }}>
                                <RepeatVisits reportType={ReportType.repeatVisits} repeatVisits={repeatVisits} />
                            </Grid>
                        </Grid>

                        <CapacityAnalysis
                            capacityAnalysis={capacityAnalysis}
                            reportType={ReportType.capacityAnalysis}
                        />

                        {/* <Grid
                            container
                            item
                            direction={'row'}
                            justifyContent={'space-between'}
                            lg={12}
                            mt={{ xs: 2, sm: 3, md: 4 }}
                        >
                            <EVDailyVisits
                                dailyVisitsStatistic={dailyVisitsStatistic}
                                reportType={ReportType.evDailyVisit}
                            />
                        </Grid>

                        <EVLengthOfVisits capacityAnalysis={capacityAnalysis} reportType={ReportType.evAverageLength} /> */}

                        {appConfig.flagFeatures.isEnableReportPCNs === true ? (
                            <>
                                <Grid
                                    container
                                    item
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'start'}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    mt={{ xs: 2, sm: 3, md: 4 }}
                                >
                                    <Grid item container mb={isMediumMD ? 2 : 0} lg={5.82} md={5.82} xs={12}>
                                        <PCNTypesChar
                                            statisticItems={groupByPNCType}
                                            reportType={ReportType.groupByPCNTypes}
                                        />
                                    </Grid>
                                    <Grid container item lg={5.82} md={5.82} xs={12}>
                                        <PCNCancellationsChart
                                            key={'7'}
                                            statisticItems={groupByPCNCancellations}
                                            reportType={ReportType.groupByPCNCancellations}
                                        />
                                    </Grid>
                                </Grid>

                                <PCNByZones reportType={ReportType.PCNByZones} groupPCNByZone={groupPCNByZone} />

                                <GroupPCNByReason
                                    reportType={ReportType.PCNByReason}
                                    groupByReason={groupPCNByReason}
                                />
                            </>
                        ) : (
                            <></>
                        )}

                        {appConfig.flagFeatures.isEnableGrossRevenue &&
                            filter.locationId === GROSS_REVENUE_CROSS_LOCATION_ID && (
                                <GrossRevenueChart
                                    grossRevenueStatistic={grossRevenueStatistic}
                                    reportType={ReportType.grossRevenue}
                                />
                            )}
                    </Grid>
                </Container>
                <Footer />
            </FilterContext.Provider>
        </>
    );
}
