import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectItem } from './MySelect';
import { Box, Typography } from '@mui/material';
import color from '../config/Colors';
import { LanguageContext } from '../contexts/useLanguage';
import { options } from './chart/PieChart';
import { createFilterOptions } from '@mui/material/Autocomplete';

type Props = {
    listData: any[];
    label: string | JSX.Element;
    value?: any;
    disabled?: boolean;
    setFilter: (option: any) => void;
    key?: any;
};

export default function useAutoComplete(props: Props) {
    const { t } = useContext(LanguageContext);
    const handleChange = (event: any, value: any) => {
        if (value == null) {
            // props.setFilter(props.listData[0].value);
        } else {
            props.setFilter(value.value);
        }
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: any) => option.label + option.email,
    });

    const RenderSelect = (
        <Box
            sx={{
                '& #combo-box-demo-listbox': {
                    padding: '5px',
                },
                '& .MuiAutocomplete-listbox': {
                    padding: '5px',
                },
                '& .MuiAutocomplete-option': {
                    minHeight: 'auto !important',
                    mb: '2px',
                    borderBottom: `1px solid ${color.grey200}`,
                    borderRadius: '4px',
                },
                //css first item chosen
                '& .MuiAutocomplete-groupLabel': {
                    fontSize: '14px',
                    lineHeight: 'normal',
                    padding: '10px 20px',
                    color: 'black',
                    // fontWeight: 600,
                    background: '#E5F2F8',
                    my: '2px',
                },
                width: '100%',
            }}
        >
            <Autocomplete
                key={props.key}
                fullWidth
                disablePortal
                noOptionsText={t('dashboard:input.noOptions')}
                sx={{
                    '& #combo-box-demo-listbox': {
                        padding: '5px',
                    },
                }}
                // open
                disabled={props.disabled == undefined ? false : props.disabled}
                defaultValue={props.listData[0]}
                options={props.listData}
                filterOptions={filterOptions}
                value={props.value || null}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                onChange={(event, value) => handleChange(event, value)}
                renderInput={(params) => <TextField {...params} label={props.label} />}
                renderOption={(props, option: SelectItem, { selected }) => {
                    return (
                        <Box key={option.value}>
                            {option.value === 0 ? (
                                <li
                                    {...props}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'absolute',
                                        width: 'calc(100% - 15px)',
                                        background: selected ? color.lightPrimary : color.white,
                                        padding: '9px 16px',
                                        top: 0,
                                    }}
                                    key={option.value + 1}
                                >
                                    <Typography variant="body1" width={'100%'}>
                                        {option.label}{' '}
                                    </Typography>
                                    {option.email ? (
                                        <Typography variant="body2" sx={{ textAlign: 'left', width: '100%' }}>
                                            {option.email}
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                </li>
                            ) : (
                                ''
                            )}

                            <li
                                {...props}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: selected ? color.lightPrimary : color.white,
                                }}
                                key={option.value}
                            >
                                <Typography variant="body1" width={'100%'}>
                                    {option.label}{' '}
                                </Typography>
                                {option.email ? (
                                    <Typography variant="body2" sx={{ textAlign: 'left', width: '100%' }}>
                                        {option.email}
                                    </Typography>
                                ) : (
                                    ''
                                )}
                            </li>
                        </Box>
                    );
                }}
                size="small"
            />
        </Box>
    );
    return { RenderSelect };
}
