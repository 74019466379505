import { Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import color from '../../../../../../config/Colors';
import { RecurringType } from '../../../../../../models/permits/PermitRecurring';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';
import PlusIcon from '../../svg/PlusIcon';
import { UpsertPermitItemContext } from '../hooks/useUpsertPermitItem';

export default function UpsertPermitComment() {
    const [isOpenComment, setIsOpenComment] = useState<boolean>(false);
    const { isEdit, t } = useContext(UpsertPermitContext);
    const { getFieldProps, formik, permit } = useContext(UpsertPermitItemContext);
    return isEdit || formik.values.comment !== null ? (
        isOpenComment === false && formik.values.comment == null ? (
            <Typography
                variant="body1"
                color={color.priText}
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                    mt: permit.permitRecurring[0].recurringType === RecurringType.once ? 0 : 1,
                }}
                onClick={() => setIsOpenComment(!isOpenComment)}
            >
                <PlusIcon
                    style={{
                        width: '16px',
                        height: '16px',
                        fill: color.priText,
                        marginRight: '5px',
                    }}
                />{' '}
                {t('permits:text.addComment')}
            </Typography>
        ) : (
            <Grid
                item
                container
                spacing={2}
                mt={permit.permitRecurring[0].recurringType === RecurringType.once ? 0 : 1}
            >
                <Grid item container>
                    <TextField
                        label={t('permits:text.comment')}
                        {...getFieldProps('comment')}
                        onChange={formik.handleChange}
                        value={formik.values.comment}
                        placeholder={t('permits:text.enterComment')}
                        inputProps={{
                            maxLength: 500,
                        }}
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
        )
    ) : isOpenComment === false ? (
        <Typography
            variant="body1"
            color={color.priText}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                direction: 'row',
                alignItems: 'center',
                '&:hover': {
                    textDecoration: 'underline',
                },
                mt: 2,
            }}
            onClick={() => setIsOpenComment(!isOpenComment)}
        >
            <PlusIcon
                style={{
                    width: '16px',
                    height: '16px',
                    fill: color.priText,
                    marginRight: '5px',
                }}
            />{' '}
            {t('permits:text.addComment')}
        </Typography>
    ) : (
        <Grid item container spacing={2} mt={2}>
            <Grid item container>
                <TextField
                    label={t('permits:text.comment')}
                    {...getFieldProps('comment')}
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                    placeholder={t('permits:text.enterComment')}
                    inputProps={{
                        maxLength: 500,
                    }}
                    fullWidth
                    size="small"
                />
            </Grid>
        </Grid>
    );
}
