import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SelectItem } from '../../components/MySelect';
import { CompaniesContext } from '../../hooks/useCompanies';
import useRegions, { RegionContext } from '../../hooks/useRegions';

export default function CompanyDetailLayout() {
    const { idCompany } = useParams();
    const { setCompanyId } = useContext(CompaniesContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (idCompany) {
            setCompanyId(Number(idCompany));
        } else {
            navigate('/dashboard', { replace: true });
        }
    }, [idCompany]);

    const regionContext = useRegions({ idCompany: Number(idCompany) });

    return (
        <RegionContext.Provider value={regionContext}>
            <Outlet />
        </RegionContext.Provider>
    );
}
