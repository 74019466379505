import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LanguageContext } from '../../../../contexts/useLanguage';
import { CompaniesContext } from '../../../../hooks/useCompanies';
import { Employee } from '../../../../models/Employee';
import { employeeController, locationController, userController } from '../../../../controllers';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import useDialog from './EmpDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeContext } from '../../../../hooks/useEmployee';
import useMultiSelectWithCheckBox from '../../../../components/useMultiSelectWithCheckBox';
import { SelectItem } from '../../../../components/MySelect';
import LabelWithRequired from '../../../../components/LabelWithRequired';
import { convertToSelectedItem } from '../../../../helpers/ConvertFilterItem';

interface State {
    showPassword: boolean;
    showConfirmPassword: boolean;
    isEdit: boolean;
    clientId: number;
    listLocation: SelectItem[];
    locationIds: number[];
}
export default function useFormEmp() {
    const { t } = useContext(LanguageContext);
    const { companyId } = useContext(CompaniesContext);
    const [state, setState] = useState<State>({
        showPassword: false,
        showConfirmPassword: false,
        isEdit: false,
        clientId: 0,
        listLocation: [],
        locationIds: [],
    });
    const navigate = useNavigate();
    const { idEmp, idCompany } = useParams();
    const employeeContext = useContext(EmployeeContext);
    const [emp, setEmp] = useState<Employee>({} as Employee);
    const [isActiveButton, setIsActiveButton] = useState<boolean>(idEmp ? false : true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const schema = Yup.object({
        firstName: Yup.string().required(`${t('validation:firstNameIsRequired')}`),
        lastName: Yup.string().required(`${t('validation:lastNameIsRequired')}`),
        email: Yup.string()
            .email(`${t('validation:invalidEmailFormat')}`)
            .required(`${t('validation:emailIsRequired')}`),
        // phoneNumber: Yup.string().matches(
        //     /^(((\+44?\s?\d{4}|\(?0?\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
        //     `${t('companies:validate.numberPhoneFormat')}`
        // ),
    });

    const {
        control,
        formState: { errors, isValid },
        resetField,
        setError,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (idEmp) {
            setState((prev) => ({ ...prev, isEdit: true }));
            userController.get(idEmp.toString()).then((user) => {
                setEmp(user as any as Employee);
                setState((prev) => ({ ...prev, clientId: user.id || 0, locationIds: user.locationIds! }));
                resetField('firstName', { defaultValue: user.firstName });
                resetField('lastName', { defaultValue: user.lastName });
                resetField('email', { defaultValue: user.email });
                resetField('lastName', { defaultValue: user.lastName });
                resetField('phoneNumber', { defaultValue: user.phoneNumber });
            });
        }
    }, [idEmp, resetField]);

    useEffect(() => {
        if (idCompany) {
            locationController
                .getAll({
                    pageSize: 10000,
                    filter: { companyId: Number(idCompany), regionId: 0 },
                    search: {
                        fields: [],
                        value: '',
                    },
                })
                .then((res) => {
                    setState((prev) => ({
                        ...prev,
                        listLocation: res.data.map((item) => convertToSelectedItem(item, 'id', 'name')),
                    }));
                });
        }
    }, [idCompany]);

    useEffect(() => {
        if (state.locationIds.length > 0) {
            const isValidation = isValid && state.locationIds.length > 0;
            setIsActiveButton(!isValidation);
        } else {
            setIsActiveButton(true);
        }
    }, [isValid, state.locationIds]);

    const getListLocation = (numbers: number[]) => {
        return state.listLocation.filter((select) => select.value === numbers.find((num) => num === select.value));
    };

    const zoneSelects = useMultiSelectWithCheckBox({
        listData: state.listLocation,
        label: <LabelWithRequired label={t('companies:text.locations')} />,
        value: getListLocation(state.locationIds),
        setFilter: (list) => {
            setState({ ...state, locationIds: list });
        },
        isDisable: state.listLocation.length === 0,
    });

    const empDialog = useDialog({
        handleAgree: () => {
            employeeController.delete(state.clientId.toString()).then((res) => {
                navigate(`/companies/${companyId}/employees`);
                employeeContext.setState((prev) => ({ ...prev, setRefreshData: 1 }));
                toast.success(t('notification:successfully'));
            });
        },
    });

    const onAddEmp = () => {
        setIsLoading(true);
        const newEmp = { ...emp };
        //client Id
        newEmp.id = state.clientId == 0 ? 0 : state.clientId;
        newEmp.id = Number(idEmp) || 0;
        newEmp.companyId = companyId;
        newEmp.label = 'client';
        newEmp.locationIds = state.locationIds;

        employeeController
            .upsert(newEmp)
            .then((res) => {
                employeeContext.setState((prev) => ({ ...prev, setRefreshData: 1 }));
                setIsLoading(false);
                navigate(`/companies/${companyId}/employees`);
                toast.success(t('notification:successfully'));
            })
            .catch((res) => {
                setIsLoading(false);
                if (res.response.status == 409) {
                    setError('email', { type: 'custom', message: t('validation:emailIsAlreadyRegistered') });
                }
                toast.error(res.response.data.value);
            });
    };

    const checkPhoneNumber = (event: any) => {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) && charCode != 187) {
            event.preventDefault();
        }
    };

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };
    const handleClickShowConfirmPassword = () => {
        setState({
            ...state,
            showConfirmPassword: !state.showConfirmPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const deleteEmp = () => {
        empDialog.handleClickOpen();
    };

    return {
        handleMouseDownPassword,
        handleClickShowConfirmPassword,
        handleClickShowPassword,
        state,
        setState,
        checkPhoneNumber,
        deleteEmp,
        setEmp,
        emp,
        onAddEmp,
        control,
        errors,
        isValid,
        zoneSelects,
        isActiveButton,
        isLoading,
        ...empDialog,
    };
}
