import { Chip, MenuItem, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ChipDeleteIcon from '../../../assets/images/ChipDeleteIcon';
import { SelectItem } from '../../../components/MySelect';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { AdvancedName } from '../hooks/usePermitsFilter';
import AdvancedFilter from './AdvancedFilter';

type Props = {
    value: string;
    permitTypes: SelectItem[];
    setValue: (val: string) => void;
    handleRemove: (advancedName: AdvancedName) => void;
};

export default function AdvancedPermitType(props: Props) {
    const { t } = useContext(LanguageContext);
    const [name, setName] = useState<string>(props.value == '' ? t('permits:text.all') : props.value!);
    const [permitTypes, setPermitTypes] = useState<SelectItem[]>([...props.permitTypes]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [stringSearch, setStringSearch] = useState<string>('');

    useEffect(() => {
        const list = _.cloneDeep(props.permitTypes);
        list.unshift({ value: '', label: t('permits:text.all') });
        setPermitTypes(props.permitTypes);
    }, [props.permitTypes, t]);

    useEffect(() => {
        if (props.value === '') {
            setName(t('permits:text.all'));
        } else {
            setName(props.value!);
        }
    }, [props.value, t]);

    const onClickSetName = (item: SelectItem) => {
        if (item.value === '') {
            setName(t('permits:text.all'));
        } else {
            setName(item.value);
        }
        props.setValue(item.value);
        setAnchorEl(null);
    };

    const handleSearch = (string: string) => {
        setStringSearch(string);
        if (string === '') {
            setPermitTypes(_.cloneDeep(props.permitTypes));
        } else {
            setPermitTypes(
                _.cloneDeep(props.permitTypes).filter((item) => item.value.toLowerCase().includes(string.toLowerCase()))
            );
        }
    };

    return (
        <AdvancedFilter
            isShowButton={false}
            openElement={
                <Chip
                    label={t('permits:text.permitType') + ': ' + name}
                    sx={[
                        anchorEl !== null
                            ? {
                                  background: 'black',
                                  '& .svg-icon-stroke': {
                                      stroke: 'white',
                                  },
                                  color: 'white',
                              }
                            : {},
                        { '& svg': { mr: '5px' }, minWidth: '160px' },
                    ]}
                    variant="outlined"
                    deleteIcon={<ChipDeleteIcon />}
                    onClick={() => {}}
                    onDelete={() => {
                        props.handleRemove('permitType');
                    }}
                />
            }
            isSearch={true}
            anchorEl={anchorEl}
            searchValue={stringSearch}
            setAnchor={setAnchorEl}
            onChangeSearch={(e) => handleSearch(e)}
            MenuItems={
                permitTypes.length > 0 ? (
                    permitTypes.map((item, index) => {
                        return <MenuItemTenant handleClick={onClickSetName} PermitTypesSearch={item} key={index} />;
                    })
                ) : (
                    <Typography padding={'5px'}>{t('permits:text.noResults')}</Typography>
                )
            }
        />
    );
}

type PropsChild = {
    PermitTypesSearch: SelectItem;
    handleClick: (item: SelectItem) => void;
};

export function MenuItemTenant(props: PropsChild) {
    return (
        <MenuItem
            // disableRipple={true}
            sx={{
                padding: '5px 5px',
                borderBottom: `1px solid ${color.grey200}`,
                borderRadius: '5px',
                '&:hover': {
                    background: color.primary,
                },
                '& .MuiTouchRipple-child': {
                    backgroundColor: color.primary,
                },
                flexDirection: 'column',
            }}
            onClick={() => props.handleClick(props.PermitTypesSearch)}
        >
            <Typography variant="body1" component={'h6'} width={'100%'}>
                {props.PermitTypesSearch.label}
            </Typography>
        </MenuItem>
    );
}
