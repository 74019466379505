import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import LoadingCircular from '../../../components/LoadingCircular';
import { LanguageContext } from '../../../contexts/useLanguage';
import { ChartShowType } from '../../../models/ChartShowType';
import { ReportType } from '../../../models/ReportType';
import { GridBorderRadius, GridWithGray100 } from '../styled';
import ChartTitleContent from './ChartTitleContent';
import ScrollContainer from 'react-indiana-drag-scroll';
import color from '../../../config/Colors';
import useMedia from '../../../hooks/useMedia';
import { COLOR_DAILY_VISITS } from '../../../constants';
import BarChartGrossRevenue from '../../../components/chart/BarChartGrossRevenue';
import { GrossRevenue } from '../../../models/grossRevenue/GrossRevenue';
import { formatNumber } from '../../../helpers/FormatCurrency';

type Props = {
    chartShowType?: ChartShowType;
    minHeightChart?: string;
    grossRevenueStatistic: GrossRevenue;
    reportType?: ReportType;
};

export default function GrossRevenueChart(props: Props) {
    const { t } = useContext(LanguageContext);
    const { isMobileSM } = useMedia();
    const dataLength =
        (props.grossRevenueStatistic &&
            props.grossRevenueStatistic.dailyGrossRevenues &&
            props.grossRevenueStatistic.dailyGrossRevenues.length) ||
        0;

    return props.grossRevenueStatistic.dailyGrossRevenues ? (
        <GridWithGray100 container item md={12} sm={12} xs={12} borderRadius={'10px'} mt={{ xs: 2, sm: 3, md: 4 }}>
            <GridBorderRadius container item xs={12}>
                <ChartTitleContent
                    key={'1'}
                    title={t('dashboard:title.grossRevenue')}
                    desc={t('dashboard:text.grossRevenueGeneratedByPaidParking')}
                    buttonLabel={t('dashboard:button.viewInsight')}
                    reportType={props.reportType}
                    chartShowType={props.chartShowType}
                />

                <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    height={{ xs: '260px', sm: '300px', md: '325px' }}
                    sx={{
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '5px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: color.primary,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: color.darkPrimary,
                            },
                            '&::-webkit-scrollbar:vertical': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <Box position={'relative'} width={'100%'} height={'100%'}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{ height: '100%', width: '100%', paddingBottom: '5px', position: 'relative' }}
                            hideScrollbars={false}
                        >
                            <Box
                                width={{
                                    lg: dataLength > 15 ? `${dataLength * 70}px` : '100%',
                                    md: dataLength > 10 ? `${dataLength * 70}px` : '100%',
                                    sm: dataLength > 7 ? `${dataLength * 70}px` : '100%',
                                    xs: dataLength > 4 ? `${dataLength * 70}px` : '100%',
                                }}
                                height={'100%'}
                                pb={{ xs: 9, md: 7 }}
                                position="relative"
                            >
                                <BarChartGrossRevenue
                                    dailyGrossRevenueStatistic={props.grossRevenueStatistic.dailyGrossRevenues}
                                />
                            </Box>
                        </ScrollContainer>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 10,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                // flexDirection: 'row',
                                flexDirection: { xs: 'column', md: 'row' },
                            }}
                        >
                            <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} justifyContent={'center'}>
                                <Stack direction={'row'} justifyContent={'center'}>
                                    <Typography
                                        variant="body1"
                                        component={'div'}
                                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                                        fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                                        mx={1}
                                    >
                                        <Box
                                            sx={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                background: COLOR_DAILY_VISITS.diesel,
                                                mr: isMobileSM ? '3px' : '5px',
                                            }}
                                        ></Box>
                                        {t('dashboard:text.kiosk')}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component={'div'}
                                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                                        fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                                        mx={1}
                                    >
                                        <Box
                                            sx={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                background: COLOR_DAILY_VISITS.petrol,
                                                mr: isMobileSM ? '3px' : '5px',
                                            }}
                                        ></Box>
                                        {t('dashboard:text.webKiosk')}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'center'}>
                                    <Typography
                                        variant="body1"
                                        component={'div'}
                                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                                        fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                                        mx={1}
                                    >
                                        <Box
                                            sx={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                background: COLOR_DAILY_VISITS.ev,
                                                mr: isMobileSM ? '3px' : '5px',
                                            }}
                                        ></Box>
                                        {t('dashboard:text.payByPhone')}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component={'div'}
                                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}
                                        fontSize={{ xs: '11px', sm: '14px', lg: '14px' }}
                                        mx={1}
                                    >
                                        <Box
                                            sx={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                background: COLOR_DAILY_VISITS.other,
                                                mr: isMobileSM ? '3px' : '5px',
                                            }}
                                        ></Box>
                                        {t('dashboard:text.payByWeb')}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack
                                padding={'8px 24px'}
                                sx={{ background: 'rgba(204, 0, 102, 0.1)' }}
                                borderRadius={'3px'}
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                gap={1}
                            >
                                <Typography sx={{ color: '#CC0066' }} component="span">
                                    Total gross revenue:{' '}
                                </Typography>
                                <Typography fontSize={'20px'} sx={{ color: '#CC0066' }} component="span">
                                    £{formatNumber.formatEURO(props.grossRevenueStatistic.total)}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
            </GridBorderRadius>
        </GridWithGray100>
    ) : (
        <LoadingCircular />
    );
}
