import { BaseModel } from '../BaseModel';
import { PermitStatus } from './FilterPermits';
import { PermitRecurring } from './PermitRecurring';
import { PermitScope } from './PermitScope';

export interface Permit extends BaseModel {
    permitType: string;
    vrnNumber: string;
    validateFrom: Date | undefined;
    validateTo?: Date | undefined;
    validateToAfterRecurringTime?: number;
    validateToType: ValidateToType;
    firstName: string;
    lastName: string;
    tenantId?: number;
    permitScopes: PermitScope[];
    permitRecurring: PermitRecurring[];
    bayNumber?: string;
    comment?: string
}

export enum ValidateToType {
    absoluteAt = 0,
    recurringTime = 1,
    infinite = 2,
}

export interface PermitListItem extends Permit {
    locationName?: string;
    zoneName?: string;
    status?: PermitStatus;
}
