import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatDate } from '../../../helpers/moment';
import { AnprMatchesDailyStats } from '../../../models/AnprMatchesDailyStats';
import { CapacityAnalysis } from '../../../models/CapacityAnalysis';
import { DailyVisitsStatistic } from '../../../models/DailyVisitsStatistic';

type Props = {
    capacityAnalysis: CapacityAnalysis;
};

export default function TableRowsCapacityAnalysis(props: Props) {
    const getPercent = (val: number) => {
        return Number(((val / ((props.capacityAnalysis.sumTotalBays * 60 * 60) / 3)) * 100).toFixed(1)) || 0;
    };

    return (
        <TableBody>
            {props.capacityAnalysis.dailyData &&
                props.capacityAnalysis.dailyData.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" align="center" scope="row">
                                {formatDate.getLocalDay(row.inDate)}
                            </TableCell>
                            <TableCell align="center">{getPercent(row.sumStayDuration_0_8!)}%</TableCell>
                            <TableCell align="center">{getPercent(row.sumStayDuration_8_16!)}%</TableCell>
                            <TableCell align="center">{getPercent(row.sumStayDuration_16_24!)}%</TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
