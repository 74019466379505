import { BaseHttpController } from 'ale-base-model'
import { AxiosInstance } from 'axios';
import { FilterEmployee } from '../models/BaseFilter';
import { Employee } from '../models/Employee';

export class EmployeeController extends BaseHttpController<Employee> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Employee', client);
    }

    async filter(filter: FilterEmployee) {
        const res = await this.doPost({ path: 'filter', body: filter, config: {} })
        return res.data
    }

    async delete(id: string){
        const res = await this.client.delete(`${this.serviceURL}/${this.basePath}/${id}`)
        return res.data
    }
}

