import { replace } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationTab } from '.';
import AddTenantIcon from '../../../assets/images/AddTenantIcon';
import GeneralInfoIcon from '../../../assets/images/GeneralInfoIcon';
import ZoneIcon from '../../../assets/images/ZoneIcon';
import { LanguageContext } from '../../../contexts/useLanguage';
import { CompaniesContext } from '../../../hooks/useCompanies';
import GeneralInformation from './components/Tab/GeneralInformationTab';
import TenantsTab from './components/Tab/TenantsTab';
import Zones from './components/Tab/ZonesTab';

interface State {
    defaultTab: LocationTab;
    indexTab: string;
}

export default function useLocationDetail() {
    const [state, setState] = useState<State>({
        defaultTab: LocationTab.GeneralInformation,
        indexTab: LocationTab.GeneralInformation,
    });
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            params.locationTab == LocationTab.GeneralInformation ||
            params.locationTab == LocationTab.Tenant ||
            params.locationTab == LocationTab.Zones
        ) {
            navigate(`/companies/${params.idCompany}/locations/${params.locationId}/${params.locationTab}`, {
                replace: true,
            });
            setState((prev) => ({ ...prev, indexTab: params.locationTab as LocationTab }));
        } else {
            navigate('/', { replace: true });
        }
    }, [params.locationTab]);

    // useEffect(() => {
    //     navigate(`/companies/${params.idCompany}/locations/${params.locationId}/${state.indexTab}`, {
    //         replace: true,
    //     });
    // }, [state.indexTab]);

    const listTab = [
        {
            index: 0,
            label: LocationTab.GeneralInformation,
            icon: <GeneralInfoIcon />,
            panel: <GeneralInformation />,
        },
        {
            index: 1,
            label: LocationTab.Zones,
            icon: <ZoneIcon />,
            panel: <Zones />,
        },
        {
            index: 2,
            label: LocationTab.Tenant,
            icon: <AddTenantIcon />,
            panel: <TenantsTab />,
        },
    ];
    const { t } = useContext(LanguageContext);
    return { setState, listTab, ...state };
}

export const LocationDetailContext = React.createContext<ReturnType<typeof useLocationDetail>>({} as any);
