/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { SelectItem } from '../../../components/MySelect';
import { LanguageContext } from '../../../contexts/useLanguage';
import {
    companiesController,
    locationController,
    permitController,
    regionController,
    shopController,
    zoneController,
} from '../../../controllers';
import useAutoComplete from '../../../components/useAutoComplete';
import useMultiSelectWithCheckBox from '../../../components/useMultiSelectWithCheckBox';
import color from '../../../config/Colors';
import IconSearch from '../../companyLayout/components/svg/IconSearch';
import { AdvancedFilterOptions, FilterPermits } from '../../../models/permits/FilterPermits';
import { Pagination } from '../../../models/BaseFilter';
import { Permit } from '../../../models/permits/Permit';
import { AuthContext } from '../../../hooks/useAuth';
import AdvancedFilterLarge from '../components/AdvancedFilterLarge';
import useMedia from '../../../hooks/useMedia';
import FilterMobileIcon from '../../../assets/svg/FilterMobileIcon';
import useModal from '../../../components/modal/useModal';
import MyModal from '../../../components/modal/MyModal';
import FilterModalContent from '../components/FilterModalContent';
import { TenantWithAllocate } from '../../../models/tenant/Tenant';
import { Filter } from '../../../models/Filter';
import { FilterEnum } from '../../../hooks/useFilter';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { Location } from '../../../models/Location';
import { convertToSelectedItem } from '../../../helpers/ConvertFilterItem';
import { Region } from '../../../models/Region';
import _ from 'lodash';
import useDebounce from '../../../hooks/useDebounce';
import ModalFilterPermits from '../components/ModalFilterPermits';

export interface StateFilter {
    filter: FilterPermits;
    listCompany: SelectItem[];
    listRegion: Region[];
    listLocation: Location[];
    listZone: SelectItem[];
    listTenant: SelectItem[];
    listPermitType: SelectItem[];
    tenants: TenantWithAllocate[];
    permitPagination: Pagination<Permit>;
    isLoading: boolean;
    advancedFilter: AdvancedFilterOptions;
    isReloadPermits: boolean;
}

export const initFilterPermit: FilterPermits = {
    page: 1,
    pageSize: 6,
    search: { fields: ['vrnNumber'], value: '' },
    filter: {
        companyId: 0,
        regionId: 0,
        locationId: 0,
        shopId: 0,
        zoneIds: [],
        permitType: '',
        status: 'all',
        validFrom: startOfDay(subDays(new Date(), 30)),
        validTo: undefined,
        recurringType: null,
    },
};
export interface AdvancedFilterItem {
    id: number;
    name: string;
    value: string | number | Date | null;
    isDisable: boolean;
    isShowing: boolean;
}
export type AdvancedName = 'tenant' | 'permitType' | 'status' | 'validFrom' | 'validTo' | 'recurringType';

export default function usePermitsFilter() {
    const { t } = useContext(LanguageContext);
    const { account, isGoldRole, isSilverRole, isBronzeRole } = useContext(AuthContext);
    const { isMobileSM } = useMedia();
    const stateModalFilter = useModal();

    const [state, setState] = useState<StateFilter>({
        filter: initFilterPermit,
        listCompany: [],
        listRegion: [],
        listLocation: [],
        listZone: [],
        listTenant: [],
        listPermitType: [],
        permitPagination: {} as any,
        isLoading: true,
        advancedFilter: {
            isDisableTenant: true,
            isShowFrom: false,
            isShowPermitType: false,
            isShowStatus: false,
            isShowTenant: false,
            isShowTo: false,
            isShowRecurringType: false,
        },
        isReloadPermits: false,
        tenants: [],
    });

    const [editingField, setEditingField] = useState<FilterEnum>(FilterEnum.all);

    useEffect(() => {
        getPermitTypes();
        if (isGoldRole()) {
            //drill down from companies
            getCompanies();
        } else if (isSilverRole()) {
            //drill down from location
            getInfoByClient();
        } else if (isBronzeRole()) {
            //drill down from zone
            getAllocatedZones();
        }
    }, []);

    const getCompanies = () => {
        companiesController.getMyCompany().then((res) => {
            res.unshift({ value: 0, label: t('dashboard:text.allCompany') });
            setState((prev) => ({ ...prev, listCompany: res }));
        });
    };

    const getPermitTypes = () => {
        const permitTypes: SelectItem[] = [];
        permitTypes.unshift({ value: '', label: t('permits:text.all') });
        permitController.getPermitTypes().then((res) => {
            if (res.data.length > 0) {
                res.data.map((item: any) => permitTypes.push({ value: item, label: item }));
                setState((prev) => ({ ...prev, listPermitType: permitTypes }));
            }
        });
    };

    const getAllocatedZones = () => {
        shopController.getAllocatedZones().then((res) => {
            const zones: SelectItem[] = [];
            if (res.length > 0) {
                res.map((item: any) => zones.push({ value: item.id, label: item.name }));
            }
            setState((prev) => ({
                ...prev,
                listZone: zones,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, shopId: account?.id! } },
            }));
        });
    };

    const getInfoByClient = () => {
        locationController
            .getAll({
                pageSize: 10000,
                filter: { companyId: 0, regionId: 0 },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                const all: Location = { id: 0, name: t('dashboard:text.allLocation') } as Location;

                setState((prev) => ({
                    ...prev,
                    listLocation: [all].concat(res.data),
                }));
            });
    };

    useEffect(() => {
        if (state.filter.filter!.companyId !== 0) {
            regionController
                .getAll({
                    pageSize: 10000,
                    filter: { companyId: state.filter.filter?.companyId || 0 },
                    search: {
                        fields: [],
                        value: '',
                    },
                })
                .then((res) => {
                    const all: Region = { id: 0, name: t('dashboard:text.allRegions') } as Region;

                    setState((prev) => ({
                        ...prev,
                        listRegion: [all].concat(res.data),
                    }));
                });
        }
        setState((prev) => ({
            ...prev,
            advancedFilter: { ...prev.advancedFilter, isDisableTenant: true, isShowTenant: false },
        }));
    }, [state.filter.filter!.companyId]);

    useEffect(() => {
        // console.log('useEffect ~ editingField', editingField, state.filter.filter?.companyId);
        if (editingField === FilterEnum.locationId) return;

        locationController
            .getAll({
                pageSize: 10000,
                filter: {
                    companyId: state.filter.filter?.companyId || 0,
                    regionId: state.filter.filter?.regionId || 0,
                },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                const all: Location = { id: 0, name: t('dashboard:text.allLocation') } as Location;

                setState((prev) => ({
                    ...prev,
                    listLocation: [all].concat(res.data),
                }));
            });
    }, [state.filter.filter?.companyId, state.filter.filter?.regionId, editingField]);

    useEffect(() => {
        if (state.filter.filter!.locationId !== 0) {
            //api get tenant
            getTenantByLocation();
            //get list zone
            zoneController.getZones(defaultFilter.locationId!).then((res) => {
                setState((prev) => ({ ...prev, listZone: res }));
            });
        } else {
            //set disable advanced filter
            setState((prev) => ({
                ...prev,
                listZone: [],
                listTenant: [],
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, shopId: 0 } },
            }));
            setStatusTenant(true);
        }
    }, [state.filter.filter!.locationId]);

    useEffect(() => {
        if (state.filter.filter!.shopId !== 0) {
            const index = state.tenants.findIndex((item) => item.clientId === state.filter.filter!.shopId);
            if (index >= 0) {
                const zones = state.tenants[index].allocatedPermits;
                const listZone: SelectItem[] = [];
                if (zones!.length > 0) {
                    zones?.map((item) => listZone.push({ value: item.zoneId, label: item.zone!.name as string }));
                }
                setState((prev) => ({ ...prev, listZone: listZone }));
            }
        } else {
            setState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, zoneIds: [] } },
            }));
        }
    }, [state.filter.filter!.shopId]);

    const defaultFilter: Filter = {
        companyId: state.filter.filter!.companyId,
        locationId: state.filter.filter!.locationId,
        regionId: state.filter.filter!.regionId,
        zoneIds: state.filter.filter!.zoneIds,
        from: new Date(),
        to: new Date(),
    };

    useEffect(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        if (isBronzeRole()) {
            if (state.filter.filter?.shopId !== 0) {
                permitController.getList(state.filter).then((res) => {
                    setState((prev) => ({ ...prev, permitPagination: res, isLoading: false }));
                });
            }
        } else {
            permitController.getList(state.filter).then((res) => {
                setState((prev) => ({ ...prev, permitPagination: res, isLoading: false }));
            });
        }
    }, [JSON.stringify(state.filter)]);

    useEffect(() => {
        if (state.isReloadPermits !== false) {
            setState((prev) => ({ ...prev, isLoading: true }));
            setTimeout(() => {
                permitController.getList({ ...state.filter, page: 1 }).then((res) => {
                    setState((prev) => ({ ...prev, permitPagination: res, isLoading: false, isReloadPermits: false }));
                });
            }, 100);
        }
    }, [state.isReloadPermits]);

    const reloadPermitsData = () => {
        setState((prev) => ({ ...prev, isReloadPermits: true }));
    };

    const getTenantByLocation = () => {
        const listTenant: SelectItem[] = [];
        shopController
            .filter({
                page: 1,
                pageSize: 1000,
                search: {
                    fields: [],
                    value: '',
                },
                filter: {
                    locationId: Number(state.filter.filter!.locationId),
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    res.data.map((item) =>
                        listTenant.push({
                            value: item.clientId,
                            label: item.tenantName,
                            email: item.email,
                        })
                    );
                    listTenant.unshift({ value: 0, label: t('permits:text.allTenant') });
                    if (listTenant.length <= 2) {
                        setState((prev) => ({
                            ...prev,
                            listTenant: listTenant,
                            filter: { ...prev.filter, clientId: Number(listTenant[1].value), tenants: res.data },
                        }));
                    } else {
                        setState((prev) => ({ ...prev, listTenant: listTenant, tenants: res.data }));
                    }
                } else {
                    setState((prev) => ({
                        ...prev,
                        listTenant: [],
                        listZone: [],
                        filter: { ...prev.filter, filter: { ...prev.filter.filter!, shopId: 0 } },
                    }));
                }
            });
    };

    const setStatusTenant = (isDisable: boolean) => {
        setState((prev) => ({
            ...prev,
            advancedFilter: { ...prev.advancedFilter, isDisableTenant: isDisable },
        }));
    };
    const onChangeFilter = (attribute: any, value: any) => {
        if (attribute === FilterEnum.companyId) {
            setState((prev) => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    filter: {
                        ...prev.filter.filter!,
                        [attribute]: value,
                        regionId: 0,
                        locationId: 0,
                        clientId: 0,
                        zoneIds: [],
                    },
                    page: 1,
                },
            }));
        } else if (attribute === FilterEnum.regionId) {
            setState((prev) => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    filter: { ...prev.filter.filter!, [attribute]: value, locationId: 0, clientId: 0, zoneIds: [] },
                    page: 1,
                },
            }));
        } else if (attribute === FilterEnum.locationId) {
            if (!value && state.filter.filter?.companyId) setEditingField(FilterEnum.all);
            else setEditingField(attribute);

            if (state.filter.filter?.companyId !== 0 && value === 0) {
                setState((prev) => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        filter: {
                            ...prev.filter.filter!,
                            [attribute]: value,
                            clientId: 0,
                            regionId: 0,
                            zoneIds: [],
                        },
                        page: 1,
                    },
                }));
            } else {
                const { regionId = 0, companyId = 0 } = state.listLocation.find((l) => l.id === value)!;
                setState((prev) => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        filter: {
                            ...prev.filter.filter!,
                            [attribute]: value,
                            regionId,
                            companyId,
                            clientId: 0,
                            zoneIds: [],
                        },
                        page: 1,
                    },
                }));
            }
        } else if (attribute === FilterEnum.shopId) {
            setState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, [attribute]: value }, page: 1 },
            }));
        } else if (attribute === FilterEnum.zoneIds) {
            setState((prev) => ({
                ...prev,
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, [attribute]: value }, page: 1 },
            }));
        } else {
            setState((prev) => ({
                ...prev,
                filter: initFilterPermit,
                advancedFilter: {
                    isDisableTenant: true,
                    isShowFrom: false,
                    isShowPermitType: false,
                    isShowStatus: false,
                    isShowTenant: false,
                    isShowTo: false,
                    isShowRecurringType: false,
                },
            }));
            setEditingField(FilterEnum.all);
            setVal('');
        }
    };

    const getListZone = (numbers: number[]) => {
        return state.listZone.filter((select) => select.value === numbers.find((num) => num === select.value));
    };

    const companySelect = useAutoComplete({
        listData: state.listCompany,
        label: t('dashboard:input.company'),
        value: state.listCompany.find((item) => item.value === state.filter.filter!.companyId),
        setFilter: (val) => {
            onChangeFilter(FilterEnum.companyId, val);
        },
        disabled: editingField === FilterEnum.locationId || isGoldRole() !== true,
    });

    const _listRegionMapped = state.listRegion.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const regionValue = state.listRegion.find((item) => item.id === state.filter.filter?.regionId);

    const regionSelect = useAutoComplete({
        listData: _listRegionMapped,
        label: t('dashboard:input.region'),
        value: regionValue ? convertToSelectedItem(regionValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            onChangeFilter(FilterEnum.regionId, val);
        },
        disabled: !state.filter.filter?.companyId || editingField === FilterEnum.locationId,
    });

    const _listLocationMapped = state.listLocation.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const locationValue = state.listLocation.find((item) => item.id === state.filter.filter?.locationId);
    const locationSelect = useAutoComplete({
        listData: _listLocationMapped,
        label: t('dashboard:input.location'),
        value: locationValue ? convertToSelectedItem(locationValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            onChangeFilter(FilterEnum.locationId, val);
        },
    });

    const tenantSelect = useAutoComplete({
        listData: state.listTenant,
        label: t('permits:text.tenant'),
        value: state.listTenant.find((item) => item.value === state.filter.filter!.shopId),
        setFilter: (val) => {
            onChangeFilter(FilterEnum.shopId, val);
        },
        disabled: state.listTenant.length == 0,
    });

    const zoneSelects = useMultiSelectWithCheckBox({
        listData: state.listZone,
        label: t('dashboard:input.zone'),
        value: getListZone(state.filter.filter!.zoneIds!),
        setFilter: (list) => {
            onChangeFilter(FilterEnum.zoneIds, list);
        },
        isDisable: state.filter.filter!.shopId == 0 && isBronzeRole() == false,
    });

    const handleResetFilter = () => {
        onChangeFilter(FilterEnum.all, '');
    };

    const handleChangePage = (val: number) => {
        setState((prev) => ({ ...prev, filter: { ...prev.filter, page: val } }));
    };

    const setShowingAdvancedFilter = (advancedFilter: AdvancedFilterOptions) => {
        setState((prev) => ({ ...prev, advancedFilter: advancedFilter }));
    };

    // console.log(state);

    const handleRemoveAdvancedFilter = (name: AdvancedName) => {
        if (name === 'permitType') {
            // console.log('type exit');

            setState((prev) => ({
                ...prev,
                advancedFilter: { ...prev.advancedFilter, isShowPermitType: false },
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, permitType: '' }, page: 1 },
            }));
        } else if (name === 'status') {
            setState((prev) => ({
                ...prev,
                advancedFilter: { ...prev.advancedFilter, isShowStatus: false },
                filter: { ...prev.filter, filter: { ...prev.filter.filter!, status: 'all' }, page: 1 },
            }));
        } else if (name === 'validFrom') {
            setState((prev) => ({
                ...prev,
                advancedFilter: { ...prev.advancedFilter, isShowFrom: false },
                filter: {
                    ...prev.filter,
                    filter: { ...prev.filter.filter!, validFrom: startOfDay(subDays(new Date(), 30)) },
                    page: 1,
                },
            }));
        } else if (name === 'validTo') {
            setState((prev) => ({
                ...prev,
                advancedFilter: { ...prev.advancedFilter, isShowTo: false },
                filter: {
                    ...prev.filter,
                    filter: { ...prev.filter.filter!, validTo: undefined },
                    page: 1,
                },
            }));
        } else if (name === 'recurringType') {
            setState((prev) => ({
                ...prev,
                advancedFilter: { ...prev.advancedFilter, isShowRecurringType: false },
                filter: {
                    ...prev.filter,
                    filter: { ...prev.filter.filter!, recurringType: null },
                    page: 1,
                },
            }));
        }
    };

    const [val, setVal] = useState<string>('');
    const debouncedValue = useDebounce<string>(val, 500);
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            filter: { ...prev.filter, search: { ...prev.filter.search!, value: debouncedValue }, page: 1 },
        }));
    }, [debouncedValue]);

    const FilterComponents = (
        <Grid
            item
            container
            xs={12}
            p={2}
            sx={{ background: color.grey100 }}
            borderRadius={'10px'}
            justifyContent={'space-between'}
        >
            {isMobileSM ? (
                <ModalFilterPermits
                    open={stateModalFilter.open}
                    handleOpen={stateModalFilter.handleOpen}
                    handleClose={stateModalFilter.handleClose}
                    modalContent={
                        <FilterModalContent
                            handleClose={stateModalFilter.handleClose}
                            setState={setState}
                            state={state}
                        />
                    }
                />
            ) : (
                ''
            )}
            <Grid
                item
                container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    // transition: 'all 0.5s',
                }}
                justifyContent="space-between"
            >
                <Grid item xs={isMobileSM ? 8 : 6} display="flex" flexDirection={'row'} alignItems={'center'} gap={0.5}>
                    <BsFilter style={{ color: color.grey600, fontSize: '18px' }} />
                    <Typography variant="body1" component={'span'} mx={0.5}>
                        {t('dashboard:button.filter')}{' '}
                    </Typography>
                    <Button variant="cancelSmall" onClick={handleResetFilter}>
                        {t('dashboard:button.resetFilter')}
                    </Button>
                </Grid>
                {isMobileSM ? (
                    <FilterMobileIcon style={{ cursor: 'pointer' }} onClick={stateModalFilter.handleOpen} />
                ) : (
                    <>
                        <Grid item xs={4}>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        height: 'auto',
                                    },
                                }}
                                fullWidth
                                placeholder={t('permits:text.searchByVRN')}
                                value={val}
                                onChange={(e) => setVal(e.target.value)}
                                inputProps={{
                                    maxLength: 20,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <IconSearch />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item container>
                            <Grid container item xs={12} mt={1} display={isBronzeRole() ? 'none' : 'block'}>
                                {locationSelect.RenderSelect}
                            </Grid>

                            <Grid item container spacing={1.5} mt={0.5} xs={12}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    mt={{ xs: 2, sm: 0, md: 0 }}
                                    display={isGoldRole() ? 'block' : 'none'}
                                >
                                    {companySelect.RenderSelect}
                                </Grid>

                                <Grid item xs={4} sm={6} md={6} display={isGoldRole() ? 'block' : 'none'}>
                                    {regionSelect.RenderSelect}
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    mt={{ xs: 2, sm: 0, md: 0 }}
                                    display={isBronzeRole() ? 'none' : 'block'}
                                >
                                    {tenantSelect.RenderSelect}
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={isSilverRole() ? 6 : 12}
                                    md={isBronzeRole() ? 12 : isSilverRole() ? 6 : 6}
                                    mt={{ xs: 0, sm: 0, md: 0 }}
                                >
                                    {zoneSelects.RenderSelect}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {isMobileSM ? (
                    ''
                ) : (
                    <AdvancedFilterLarge
                        state={state}
                        setState={setState}
                        handleRemoveAdvancedFilter={handleRemoveAdvancedFilter}
                        setShowingAdvancedFilter={setShowingAdvancedFilter}
                    />
                )}
            </Grid>
        </Grid>
    );

    return { ...state, FilterComponents, handleChangePage, reloadPermitsData };
}

export const PermitFilterContext = React.createContext<ReturnType<typeof usePermitsFilter>>({} as any);

export interface TenantSearch {
    id: number;
    name: string;
    email: string;
}
