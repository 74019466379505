import { Box, Chip, IconButton, TableBody, TableCell, TableRow, Tooltip, Typography, Stack } from '@mui/material';
import React, { useContext } from 'react';

import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { formatNumber } from '../../../../../helpers/FormatCurrency';
import { AuthContext } from '../../../../../hooks/useAuth';
import { CompaniesContext } from '../../../../../hooks/useCompanies';
import Zone from '../../../../../models/Zone';
import IconEdit from '../../../employees/components/IconEdit';

type Props = {
    handleEdit: (zone: Zone) => void;
};

export default function TableZones(props: Props) {
    const { zonesSelect } = useContext(CompaniesContext);
    const { isGoldRole, account } = useContext(AuthContext);
    const { t } = useContext(LanguageContext);

    const SERVICE_TYPES = [
        {
            id: 0,
            name: 'ANPR',
            color: color.lightPrimary,
        },
        {
            id: 1,
            name: t('companies:text.wardenPatrol'),
            color: '#FFD9D9',
        },
        {
            id: 2,
            name: t('companies:text.selfTicket'),
            color: '#ABAD88',
        },
        {
            id: 3,
            name: 'iTicket',
            color: '#FFDC83',
        },
        {
            id: 4,
            name: t('companies:text.contraventionManagement'),
            color: '#F09453',
        },
        {
            id: 5,
            name: t('companies:text.other'),
            color: '#007BFF',
        },
    ];
    const buildNameServiceType = (number: Number[]) => {
        if (number != undefined) {
            const res = SERVICE_TYPES.filter((el) => number.includes(el.id));
            const listName = res
                .slice(2, 10)
                .map((title) => title.name)
                .join(', ');
            return res.slice(0, 2).map((item, index) => {
                return (
                    <Stack key={index} alignItems={'center'} direction={'row'}>
                        <Chip sx={{ background: item.color, borderRadius: '40px' }} key={index} label={item.name} />
                        <Tooltip
                            placement="top"
                            title={
                                <Typography variant="body2" color={'white'}>
                                    {listName}
                                </Typography>
                            }
                        >
                            {res.length - 2 < 1 ? (
                                <Box display={index == 1 ? 'block' : 'none'} width={23} />
                            ) : (
                                <Typography
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: color.secondary,
                                            textDecoration: 'underline',
                                        },
                                    }}
                                    ml={1}
                                    display={index == 1 ? 'block' : 'none'}
                                >
                                    + {res.length - 2}
                                </Typography>
                            )}
                        </Tooltip>
                    </Stack>
                );
            });
        } else {
            return <Typography>...</Typography>;
        }
    };

    return (
        <TableBody>
            {zonesSelect &&
                zonesSelect.length > 0 &&
                zonesSelect.map((zone, index) => {
                    const isLast = index == zonesSelect.length - 1;

                    return (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                borderBottom: isLast ? `1px solid #ddd` : '',
                            }}
                        >
                            <TableCell style={{ maxWidth: 200 }} align="center" component="th" scope="row">
                                <Typography noWrap>{zone.name}</Typography>
                            </TableCell>
                            <TableCell style={{ minWidth: 80 }} align="center">
                                {zone.zoneType.name}
                            </TableCell>
                            <TableCell align="center">
                                <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={1}>
                                    {buildNameServiceType(zone.services?.map((item) => item.serviceType))}
                                </Stack>
                            </TableCell>
                            <TableCell style={{ minWidth: 80 }} align="center">
                                {formatNumber.format(zone.totalBays)}
                            </TableCell>
                            <TableCell style={{ minWidth: 120 }} align="center">
                                <Tooltip
                                    title={
                                        <Box>
                                            <Typography variant="body2" color={'white'}>
                                                {t('companies:tenants.allocatedPermits')}:{' '}
                                                {formatNumber.format(zone.allocated)} -{' '}
                                                {t('companies:tenants.maxPermits')}:{' '}
                                                {formatNumber.format(zone.maxPermits)}
                                            </Typography>
                                        </Box>
                                    }
                                    placement="top"
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: color.secondary,
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        {formatNumber.format(zone.allocated)}/{formatNumber.format(zone.maxPermits)}
                                    </Typography>
                                </Tooltip>
                            </TableCell>
                            {account?.label == 'Account manager' ? (
                                <TableCell align="center">
                                    <Tooltip title={t('action:edit')} placement="top">
                                        <IconButton
                                            onClick={() => props.handleEdit(zone)}
                                            aria-label="fingerprint"
                                            color="success"
                                            sx={{
                                                backgroundColor: color.grey200,
                                                '&:hover': {
                                                    backgroundColor: color.grey200,
                                                },
                                                border: `5px solid ${color.grey100}`,
                                                width: '40px',
                                                height: '40px',
                                            }}
                                        >
                                            <IconEdit />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            ) : (
                                <></>
                            )}
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
