import { BaseModel } from "../BaseModel";

export interface PermitRecurring extends BaseModel {
    fromHour: number | null,
    toHour: number | null,
    recurringType: RecurringType,
    recurringEvery: number | null,
    permitRecurringDays: PermitRecurringDay[]
}

// export type RecurringType = 'once' | 'day' | 'week' | 'month' | 'year' | "indefinite"

export enum RecurringType {
    once = 0,
    day = 1,
    week = 2,
    month = 3,
    year = 4,
    indefinite = 5
}

export interface PermitRecurringDay extends BaseModel {
    firstExecuteAt: Date | null,
    nextExecuteAt?: Date | null
}