import { Checkbox, Chip, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import { GridCard } from '../../../components/GridCard';
import color from '../../../config/Colors';
import CheckBoxIcon from '../../../assets/images/CheckBoxIcon';
import CheckedIcon from '../../../assets/images/CheckedIcon';
import IconEdit from '../../companyLayout/employees/components/IconEdit';
import { DangerTooltip } from '../../companyLayout/locationDetail/components/Tab/TableRowTenantsInfo';
import RemoveIcon from '../../../assets/images/RemoveIcon';
import { PermitListItem } from '../../../models/permits/Permit';
import { PermitContext } from '../hooks/usePermits';
import { useNavigate } from 'react-router-dom';
import useMedia from '../../../hooks/useMedia';

type Props = {
    permit: PermitListItem;
};
export default function PermitItem({ permit }: Props) {
    const { t } = useContext(LanguageContext);
    const { handleChangeChecked, checkPermitId, handleOpenConfirmDelete } = useContext(PermitContext);
    const navigate = useNavigate();
    const { isMobileSM } = useMedia();

    const handleEdit = () => {
        navigate(`/permits/edit-permit/${permit.id}`);
    };

    return (
        <GridCard item container xs={12}>
            <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={8} display={'flex'} alignItems="center">
                    <Checkbox
                        value={permit.id}
                        sx={{ padding: '0px', mr: '5px' }}
                        size="small"
                        icon={<CheckBoxIcon style={{ width: '22px', height: '22px' }} />}
                        checkedIcon={<CheckedIcon style={{ width: '22px', height: '22px' }} />}
                        onChange={(e) => handleChangeChecked(e)}
                        checked={checkPermitId(Number(permit.id))}
                    />
                    <Typography
                        sx={{
                            '&:hover': {
                                color: color.primary,
                                textDecorationLine: 'underline',
                                transition: '0.3s',
                                cursor: 'pointer',
                            },
                            textTransform: 'uppercase',
                            fontWeight: 400,
                        }}
                        onClick={handleEdit}
                        variant="h5"
                        component={'span'}
                    >
                        {permit.vrnNumber}
                    </Typography>

                    <Chip
                        label={
                            <Typography
                                variant="body2"
                                textTransform={'capitalize'}
                                color={
                                    permit.status === 'expired'
                                        ? color.danger
                                        : permit.status === 'pending'
                                        ? '#FFB600'
                                        : color.success
                                }
                            >
                                {permit.status === 'expired'
                                    ? t('action:expired')
                                    : permit.status === 'pending'
                                    ? t('action:pending')
                                    : t('action:active')}
                            </Typography>
                        }
                        sx={{
                            background:
                                permit.status === 'expired'
                                    ? 'rgba(224, 27, 0, 0.1)'
                                    : permit.status === 'pending'
                                    ? 'rgba(251, 182, 1, 0.1)'
                                    : 'rgba(32, 157, 79, 0.1)',
                            // opacity: 0.1,
                            ml: 2,
                            maxHeight: '28px',
                        }}
                    />
                </Grid>

                <Grid
                    item
                    container
                    display={'flex'}
                    justifyContent={'right'}
                    sx={{
                        backgroundColor: `${color.white} !important`,
                    }}
                    xs={4}
                >
                    <Grid
                        item
                        sx={{
                            backgroundColor: `${color.white} !important`,
                        }}
                    >
                        <Tooltip title={t('action:edit')} placement="top">
                            <IconButton
                                onClick={handleEdit}
                                aria-label="fingerprint"
                                color="success"
                                sx={{
                                    backgroundColor: color.grey200,
                                    '&:hover': {
                                        backgroundColor: color.grey200,
                                    },
                                    padding: 0,
                                    border: `5px solid ${color.grey100}`,
                                    width: '32px',
                                    height: '32px',
                                }}
                            >
                                <IconEdit width={'26px'} height={'26px'} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <DangerTooltip title={t('action:delete')} placement="top">
                            <IconButton
                                onClick={() => handleOpenConfirmDelete(permit.id)}
                                aria-label="fingerprint"
                                color="success"
                                sx={{
                                    backgroundColor: color.grey200,
                                    '& .svg-icon-stroke': {
                                        stroke: color.danger,
                                    },
                                    '&:hover': {
                                        backgroundColor: color.grey200,
                                    },
                                    border: `5px solid ${color.grey100}`,
                                    padding: 0,
                                    width: '32px',
                                    height: '32px',
                                }}
                            >
                                <RemoveIcon width={'26px'} height={'26px'} />
                            </IconButton>
                        </DangerTooltip>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                pt={1}
                item
                container
                direction={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={1}
                // sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
                <Typography variant="body1">
                    {t('dashboard:input.location')}: {permit.locationName}
                </Typography>

                <Stack
                    direction={isMobileSM ? 'column' : 'row'}
                    justifyContent={'flex-start'}
                    alignItems={isMobileSM ? 'start' : 'center'}
                    spacing={1}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: isMobileSM ? '300px' : '400px',
                        }}
                    >
                        {t('dashboard:input.zone')}: {permit.zoneName}
                    </Typography>

                    {isMobileSM ? (
                        <></>
                    ) : (
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            sx={{ borderRight: `1px solid ${color.grey600}` }}
                        />
                    )}
                    <Typography variant="body1" textTransform={'capitalize'}>
                        {t('permits:text.permitType')}: {permit.permitType}
                    </Typography>
                </Stack>

                {/* <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
                    <Grid item>
                        <Typography variant="body1">{t('companies:text.serviceTypes')}:</Typography>
                    </Grid>
                </Grid> */}
            </Grid>
        </GridCard>
    );
}
