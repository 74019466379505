import React, { useContext, useEffect, useState } from 'react';
import { SelectItem } from '../components/MySelect';
import { LanguageContext } from '../contexts/useLanguage';
import { companiesController } from '../controllers';

type Props = {
    idCompany: number;
};

export default function useRegions({ idCompany }: Props) {
    const [regions, setRegions] = useState<SelectItem[]>([]);
    const { t } = useContext(LanguageContext);

    useEffect(() => {
        companiesController
            .GetCompanyDetail({
                companyId: Number(idCompany),
                locationId: 0,
                regionId: 0,
                zoneIds: [],
                from: new Date(),
                to: new Date(),
            })
            .then((res) => {
                res.regions.unshift({ value: 0, label: t('dashboard:text.allRegions') });
                setRegions(res.regions);
            });
    }, [idCompany]);

    return { regions };
}

export const RegionContext = React.createContext<ReturnType<typeof useRegions>>({} as any);
