import React, { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import color from '../../../../config/Colors';

type Props = {
    modalContent: ReactNode;
    open: boolean;
    handleOpen: (isOpen: boolean) => void;
    handleClose: (isOpen: boolean) => void;
};

export default function TenantModal({ modalContent, open, handleOpen, handleClose }: Props) {
    return (
        <Box sx={{}}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 9999, top: -0.1, left: -0.1 }}
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: { xs: '50%', md: '53%' },
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: '100%',
                            sm: 500,
                            md: 700,
                        },
                        height: { xs: '100%', sm: 'auto' },

                        bgcolor: color.white,
                        gap: 4,
                        borderRadius: { xs: 'none', sm: '10px' },
                        outline: 'none',
                    }}
                >
                    {modalContent}
                </Box>
            </Modal>
        </Box>
    );
}
