import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import ChartIcon from '../../assets/images/ChartIcon';
import Companies from '../../assets/images/Companies';
import LogoutIcon from '../../assets/images/LogoutIcon';
import MailIcon from '../../assets/images/MailIcon';
import PermitsIcon from '../../assets/images/PermitsIcon';
import Reports from '../../assets/images/Report';
import UserIcon from '../../assets/images/UserIcon';
import { appConfig } from '../../config/AppConfig';
import { LanguageContext } from '../../contexts/useLanguage';
import { AuthContext } from '../../hooks/useAuth';
import useMedia from '../../hooks/useMedia';
import { DrawerItem } from '../../models/DrawerItem';
import { DEFAULT_REPORT_TYPE, DEFAULT_TAB_INDEX } from '../reports/hooks/useReport';

export default function useDrawer() {
    const { t } = useContext(LanguageContext);
    const { logout, account } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState<number>(1);
    const [openDrawerSmall, setOpenDrawerSmall] = useState(false);
    const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);
    const { isMobileSM, isMediumMD } = useMedia();

    useEffect(() => {
        isMobileSM ? setIsShowDrawer(false) : setIsShowDrawer(true);
    }, [isMobileSM]);

    const handleDrawerClose = () => {
        if (isMediumMD) {
            setOpenDrawerSmall(true);
            setIsShowDrawer(true);
        } else {
            setOpen(!open);
        }
    };

    const handleDrawerOpen = () => {
        if (isMediumMD) {
            setOpenDrawerSmall(true);
            setIsShowDrawer(false);
        } else {
            setOpen(!open);
        }
    };

    const changeIsShowDrawer = () => {
        setIsShowDrawer(!isShowDrawer);
    };

    const fullMenu = [
        {
            id: 1,
            label: t('drawer:dashboard'),
            icon: <ChartIcon className="icon-svg-fill" />,
            path: '',
        },
        {
            id: 2,
            label: t('drawer:companies'),
            icon: <Companies className="svg-item-Menu" />,
            path: 'companies',
        },
        {
            id: 3,
            label: t('drawer:insights'),
            icon: <Reports className="svg-item-Menu" />,
            path: `reports`,
        },
        {
            id: 4,
            label: t('drawer:user'),
            icon: <UserIcon className="svg-item-Menu" />,
            path: 'user',
        },
        {
            id: 5,
            label: t('drawer:permits'),
            icon: <PermitsIcon className="svg-item-Menu" />,
            path: 'permits',
        },
        {
            id: 6,
            label: t('drawer:support'),
            icon: <MailIcon className="svg-item-Menu" />,
            path: 'support',
        },
    ];

    const listUKPC = [
        {
            id: 1,
            label: t('drawer:dashboard'),
            icon: <ChartIcon className="icon-svg-fill" />,
            path: '',
        },
        {
            id: 3,
            label: t('drawer:insights'),
            icon: <Reports className="svg-item-Menu" />,
            path: `reports`,
        },
        {
            id: 4,
            label: t('drawer:user'),
            icon: <UserIcon className="svg-item-Menu" />,
            path: 'user',
        },
        {
            id: 6,
            label: t('drawer:support'),
            icon: <MailIcon className="svg-item-Menu" />,
            path: 'support',
        },
    ];

    let ListDrawItem: DrawerItem[] = fullMenu;

    if (appConfig.site == 'intelliPark') {
        ListDrawItem = fullMenu;
        if (appConfig.flagFeatures.isEnableManageCompany === false) {
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 2);
        }
        if (appConfig.flagFeatures.isEnableManagePermits === false) {
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 5);
        }
        if (account?.label == 'Tenant') {
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 1);
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 3);
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 2);
        }
    } else {
        ListDrawItem = listUKPC;
        if (appConfig.flagFeatures.isEnableManageCompany === false) {
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 2);
        }
        if (appConfig.flagFeatures.isEnableManagePermits === false) {
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 5);
        }
        if (account?.label == 'Tenant') {
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 1);
            ListDrawItem = ListDrawItem.filter((item) => item.id !== 3);
            // ListDrawItem = ListDrawItem.filter((item) => item.id !== 2);
        }
    }

    return {
        ListDrawItem,
        open,
        setOpen,
        handleDrawerClose,
        logout,
        activeItem,
        setActiveItem,
        openDrawerSmall,
        setOpenDrawerSmall,
        isShowDrawer,
        changeIsShowDrawer,
        isMobileSM,
        isMediumMD,
        setIsShowDrawer,
        handleDrawerOpen,
    };
}

export const DrawerContext = React.createContext<ReturnType<typeof useDrawer>>({} as any);
