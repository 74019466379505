import { TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/FormatCurrency';
import { formatDate } from '../../../helpers/moment';
import { GrossRevenue } from '../../../models/grossRevenue/GrossRevenue';

type Props = {
    grossRevenueStatistic: GrossRevenue;
};

export default function TableRowsGrossRevenue(props: Props) {
    return (
        <TableBody>
            {props.grossRevenueStatistic.dailyGrossRevenues &&
                props.grossRevenueStatistic.dailyGrossRevenues.map((row, index) => {
                    return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell key={index} align="center" component="th" scope="row">
                                {formatDate.getLocalDay(row.inDate)}
                            </TableCell>
                            <TableCell align="center">£{formatNumber.formatEURO(row.qtyTotalOfKiosk!)}</TableCell>
                            <TableCell align="center">£{formatNumber.formatEURO(row.qtyTotalOfWebKiosk!)}</TableCell>
                            <TableCell align="center">£{formatNumber.formatEURO(row.qtyTotalOfPayByPhone!)}</TableCell>
                            <TableCell align="center">£{formatNumber.formatEURO(row.qtyTotalOfPayByWeb!)}</TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}
