/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from 'react';
import {
    IconButton,
    Stack,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';
import React from 'react';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { formatNumber } from '../../../../../helpers/FormatCurrency';
import { TenantWithAllocate } from '../../../../../models/tenant/Tenant';
import { styled } from '@mui/styles';
import color from '../../../../../config/Colors';
import IconEdit from '../../../employees/components/IconEdit';
import RemoveIcon from '../../../../../assets/images/RemoveIcon';
import useMedia from '../../../../../hooks/useMedia';
import _ from 'lodash';

type Props = {
    tenantWithAllocates: TenantWithAllocate[];
    editFunction: (tenant: TenantWithAllocate) => void;
    deleteFunction: (id: number) => void;
};

export default function TableRowTenantsInfo(props: Props) {
    const { isExtraSmallXs, isMediumMD } = useMedia();
    const { t } = useContext(LanguageContext);
    const [tenantWithAllocate, setTenantWithAllocate] = useState<TenantWithAllocate[]>([]);

    //filter zone 0 permit
    useEffect(() => {
        if (props.tenantWithAllocates && props.tenantWithAllocates.length > 0) {
            const list: TenantWithAllocate[] = [];
            props.tenantWithAllocates.map((item, index) => {
                const tenant = _.cloneDeep(item);
                const allocatedPermits = tenant.allocatedPermits?.filter((item) => item.allocatedAmount !== 0);
                tenant.allocatedPermits = allocatedPermits;
                list.push(tenant);
            });
            setTenantWithAllocate(list);
        }
    }, [props.tenantWithAllocates]);

    const getIndex = (tenantId: number, zoneId: number) => {
        const indexTenant = tenantWithAllocate.findIndex((item) => item.clientId == tenantId);
        if (indexTenant >= 0) {
            const index = tenantWithAllocate[indexTenant].allocatedPermits?.findIndex((item) => item.zoneId == zoneId);
            return index!;
        } else {
            return -1;
        }
    };

    return (
        <TableBody>
            {props.tenantWithAllocates &&
                props.tenantWithAllocates.length > 0 &&
                props.tenantWithAllocates.map((item, index) => {
                    const isLast = index == props.tenantWithAllocates.length - 1;
                    return (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                borderBottom: isLast ? `1px solid #ddd` : '',
                            }}
                        >
                            <TableCell component="th" align="left" scope="row">
                                {item.tenantName.length > 15
                                    ? item.tenantName.slice(0, 15).concat('...')
                                    : item.tenantName}
                            </TableCell>
                            {isExtraSmallXs || isMediumMD ? (
                                ''
                            ) : (
                                <TableCell component="th" align="center" scope="row">
                                    {item.managerName.length > 15
                                        ? item.managerName.slice(0, 15).concat('...')
                                        : item.managerName}
                                </TableCell>
                            )}

                            {isExtraSmallXs || isMediumMD ? (
                                ''
                            ) : (
                                <TableCell align="center">
                                    <Tooltip
                                        title={
                                            <Typography variant="body2" color={'white'}>
                                                {item.email}
                                            </Typography>
                                        }
                                        placement="top"
                                    >
                                        <Typography variant="body1">
                                            {item.email.length > 25
                                                ? item.email.slice(0, 25).concat('...')
                                                : item.email}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                            )}
                            <TableCell
                                align={isExtraSmallXs || isMediumMD ? 'left' : 'left'}
                                width={isExtraSmallXs || isMediumMD ? '60%' : '40%'}
                            >
                                <Stack>
                                    {item.allocatedPermits!.map((allocate, index) => {
                                        if (allocate.allocatedAmount !== 0) {
                                            return (
                                                <Tooltip
                                                    title={
                                                        <Stack>
                                                            <Typography variant="body2" color="white">
                                                                {t('companies:tenants.zoneName')}: {allocate.zone?.name}
                                                            </Typography>
                                                            <Typography variant="body2" color="white">
                                                                {t('companies:tenants.usedPermits')}:{' '}
                                                                {formatNumber.format(allocate.usedPermit!)}
                                                            </Typography>
                                                            <Typography variant="body2" color="white">
                                                                {t('companies:tenants.allocated')}:{' '}
                                                                {formatNumber.format(allocate.allocatedAmount)}
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                    placement="top"
                                                    key={index}
                                                    sx={{ background: 'black' }}
                                                >
                                                    <Typography
                                                        key={index}
                                                        sx={{
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                color: color.secondary,
                                                                textDecoration: 'underline',
                                                            },
                                                        }}
                                                    >
                                                        {getIndex(item.clientId!, allocate.zoneId!) + 1}.{' '}
                                                        {allocate.zone?.name && allocate.zone?.name.length > 30
                                                            ? allocate.zone?.name.slice(0, 30).concat('...')
                                                            : allocate.zone?.name}
                                                        : {formatNumber.format(allocate.usedPermit!)}(
                                                        {formatNumber.format(allocate.allocatedAmount)})
                                                    </Typography>
                                                </Tooltip>
                                            );
                                        }
                                    })}
                                </Stack>
                            </TableCell>
                            <TableCell align="center" width={isExtraSmallXs || isMediumMD ? '10%' : '10%'}>
                                <Stack
                                    flexDirection={isExtraSmallXs || isMediumMD ? 'column' : 'row'}
                                    justifyContent="center"
                                    gap={0}
                                >
                                    <Tooltip title={t('action:edit')} placement="top">
                                        <IconButton
                                            onClick={() => props.editFunction(item)}
                                            aria-label="fingerprint"
                                            color="success"
                                            sx={{
                                                backgroundColor: color.grey200,
                                                '&:hover': {
                                                    backgroundColor: color.grey200,
                                                },
                                                border: `5px solid ${color.grey100}`,
                                                width: '40px',
                                                height: '40px',
                                                marginLeft: isExtraSmallXs || isMediumMD ? 'auto' : '',
                                            }}
                                        >
                                            <IconEdit />
                                        </IconButton>
                                    </Tooltip>
                                    <DangerTooltip title={t('action:delete')} placement="top">
                                        <IconButton
                                            onClick={() => props.deleteFunction(item.clientId!)}
                                            aria-label="fingerprint"
                                            color="success"
                                            sx={{
                                                backgroundColor: color.grey200,
                                                '& .svg-icon-stroke': {
                                                    stroke: color.danger,
                                                },
                                                '&:hover': {
                                                    backgroundColor: color.grey200,
                                                },
                                                border: `5px solid ${color.grey100}`,
                                                width: '40px',
                                                height: '40px',
                                                marginLeft: isExtraSmallXs || isMediumMD ? 'auto' : '',
                                            }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </DangerTooltip>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody>
    );
}

export const DangerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color.danger,
        color: color.white,
    },
}));
