import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import color from '../../../../config/Colors';
import { PERMITS_LIMIT, UpsertPermitContext } from '../hooks/useUpsertPermit';
import PlusIcon from './svg/PlusIcon';

export default function UpsertPermitActions() {
    const { t, handleAddEmptyPermit, setState, isAdd, isValid, isLoading, isEdit, filter, permits } =
        useContext(UpsertPermitContext);
    const navigate = useNavigate();

    //get allocated left
    const handleAdd = () => {
        if (permits.length < PERMITS_LIMIT) {
            handleAddEmptyPermit();
        }
    };
    return (
        <>
            {isEdit ? (
                <></>
            ) : (
                <Grid item mt={2} container>
                    <Typography
                        variant="body1"
                        color={
                            filter.idZones.length > 0 || permits.length < PERMITS_LIMIT ? color.priText : color.grey600
                        }
                        sx={{
                            cursor: filter.idZones.length > 0 ? 'pointer' : 'auto',
                            display: 'flex',
                            direction: 'row',
                            alignItems: 'center',
                        }}
                        onClick={handleAdd}
                    >
                        <PlusIcon
                            style={{
                                width: '16px',
                                height: '16px',
                                fill:
                                    filter.idZones.length > 0 || permits.length < PERMITS_LIMIT
                                        ? color.priText
                                        : color.grey600,
                                marginRight: '5px',
                            }}
                        />{' '}
                        {t('permits:button.addPermit')}
                    </Typography>
                </Grid>
            )}
            <Grid item container flexDirection={'row'} justifyContent="space-between" mt={2}>
                <Button
                    variant="cancel"
                    sx={{ minWidth: '150px' }}
                    onClick={() => {
                        navigate(`/permits`);
                    }}
                >
                    {t('action:cancel')}
                </Button>
                <LoadingButton
                    loading={isLoading}
                    startIcon={<></>}
                    loadingPosition="start"
                    variant="contained"
                    disabled={isValid}
                    sx={{ minWidth: '150px' }}
                    type="submit"
                    onClick={() => {
                        setState((prev) => ({ ...prev, isAdd: !isAdd }));
                    }}
                >
                    {t('action:save')}
                </LoadingButton>
            </Grid>
        </>
    );
}
