import { Box, Button, Container, Grid, InputAdornment, Pagination, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../../contexts/useLanguage';
import IconSearch from '../components/svg/IconSearch';
import IconButton from '@mui/material/IconButton';
import CompanyItem from './components/CompanyItem';
import { CompaniesContext } from '../../../hooks/useCompanies';
import LoadingCircular from '../../../components/LoadingCircular';
import Footer from '../../dashboardLayout/components/Footer';
import NoDataToDisplay from '../../../components/NoDataToDisplay';
import _ from 'lodash';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { locationController } from '../../../controllers';
import { Location } from '../../../models/Location';
import { convertToSelectedItem } from '../../../helpers/ConvertFilterItem';
import useAutoComplete from '../../../components/useAutoComplete';
import { IoCloseOutline } from 'react-icons/io5';

export default function CompanyPage() {
    const { t } = useContext(LanguageContext);

    const { companiesPagination, filter, setState, isLoading, listLocation } = useContext(CompaniesContext);
    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setState((prev) => ({ ...prev, filter: { ...prev.filter, page: value } }));
    };
    useScrollToTop();
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        locationController
            .getAll({
                pageSize: 10000,
                filter: { companyId: 0, regionId: 0 },
                search: {
                    fields: [],
                    value: '',
                },
            })
            .then((res) => {
                const all: Location = { id: 0, name: t('support:text.selectLocation') } as Location;

                setState((prev) => ({
                    ...prev,
                    listLocation: [all].concat(res.data),
                }));
            });
    }, []);

    const _listLocationMapped = listLocation.map((l) => convertToSelectedItem(l, 'id', 'name'));
    const locationValue = listLocation.find((item) => item.id === filter.filter.locationId);

    const locationSelect = useAutoComplete({
        listData: _listLocationMapped,
        label: t('dashboard:input.location'),
        value: locationValue ? convertToSelectedItem(locationValue, 'id', 'name') : undefined,
        setFilter: (val) => {
            setState((p) => ({
                ...p,
                filter: {
                    ...p.filter,
                    filter: { ...p.filter.filter, locationId: val },
                    search: { ...p.filter.search!, value: '' },
                    page: 1,
                },
            }));
        },
    });

    const onChangeSearch = (e: any) => {
        setState((prev) => ({
            ...prev,
            filter: {
                ...prev.filter,
                search: { ...prev.filter.search!, value: e.target.value },
                filter: { ...prev.filter.filter, locationId: 0 },
                page: 1,
            },
        }));
    };

    const onClickClearSearch = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            setState((p) => ({
                ...p,
                filter: {
                    ...p.filter,
                    filter: { ...p.filter.filter, locationId: 0 },
                    search: { ...p.filter.search!, value: '' },
                    page: 1,
                },
            }));
        }
    };

    return (
        <>
            <Container sx={{ pt: 12, minHeight: 'calc(100vh - 102px)' }}>
                <Box>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="h3" fontWeight={400}>
                            {t('companies:title.companies')}
                        </Typography>
                        <Button
                            variant="cancelSmall"
                            sx={{ ml: 'px' }}
                            onClick={() => {
                                setState((p) => ({
                                    ...p,
                                    filter: {
                                        ...p.filter,
                                        filter: { ...p.filter.filter, locationId: 0 },
                                        search: { ...p.filter.search!, value: '' },
                                        page: 1,
                                    },
                                }));
                                if (inputRef.current) {
                                    inputRef.current.value = '';
                                }
                            }}
                        >
                            {t('dashboard:button.resetFilter')}
                        </Button>
                    </Stack>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={inputRef}
                                fullWidth
                                placeholder={t('companies:input.searchByName')}
                                onChange={_.debounce((value) => onChangeSearch(value), 1000)}
                                inputProps={{
                                    maxLength: 75,
                                }}
                                defaultValue={filter.search?.value}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                '& .clear-content:hover': {
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <IoCloseOutline
                                                className="clear-content"
                                                fontSize={'20px'}
                                                onClick={onClickClearSearch}
                                                style={{ display: inputRef.current?.value !== '' ? 'block' : 'none' }}
                                            />

                                            <IconButton>
                                                <IconSearch />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ '& .MuiOutlinedInput-root': { height: '37px' } }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {locationSelect.RenderSelect}
                        </Grid>
                    </Grid>
                </Box>
                {isLoading ? (
                    <LoadingCircular />
                ) : companiesPagination.data && companiesPagination.data.length > 0 ? (
                    <>
                        <Grid xs={12} item direction={'column'} container mt={4}>
                            {companiesPagination.data.map((item, index) => {
                                return (
                                    <Grid mb={2} key={index} item xs={12}>
                                        <CompanyItem companyWithDetail={item} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <Pagination
                                count={companiesPagination.totalPage}
                                page={companiesPagination.page}
                                onChange={handleChangePagination}
                                variant="outlined"
                                shape="rounded"
                                sx={{ mt: 3 }}
                            />{' '}
                        </Stack>
                    </>
                ) : (
                    <NoDataToDisplay text={t('companies:text.noDataToDisplay')} />
                )}
            </Container>
            <Footer />
        </>
    );
}
