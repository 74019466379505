/* eslint-disable react-hooks/exhaustive-deps */
import { AccordionSummary, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import RemoveIcon from '../../../../../../assets/images/RemoveIcon';
import color from '../../../../../../config/Colors';
import { Permit } from '../../../../../../models/permits/Permit';
import { UpsertPermitContext } from '../../../hooks/useUpsertPermit';
import { UpsertPermitItemContext } from '../hooks/useUpsertPermitItem';
import CopyIcon from './../../svg/CopyIcon';
import ExplantedIcon from './../../svg/ExplantedIcon';

type Props = {
    permit: Permit;
    onCollapse: () => void;
};
export default function UpsertPermitItemTitle(props: Props) {
    const getIndex = () => {
        const indexPermit = permits.findIndex((item) => item.id === permit.id);
        if (indexPermit >= 0) {
            return indexPermit + 1;
        }
        return 0;
    };
    const { t, handleRemovePermit, permits } = useContext(UpsertPermitContext);
    const { isValid, onDuplicatePermit, permit } = useContext(UpsertPermitItemContext);
    const [index, setIndex] = useState<number>(getIndex());

    useEffect(() => {
        setIndex(getIndex());
    }, [permits.length]);

    return (
        <AccordionSummary
            sx={{
                '&.MuiAccordionSummary-root': {
                    flexDirection: 'row-reverse',
                },
                '&.MuiAccordionSummary-root:hover': {
                    cursor: 'default',
                },
            }}
            expandIcon={
                <IconButton onClick={props.onCollapse} sx={{ p: '10px' }}>
                    <ExplantedIcon />
                </IconButton>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Stack ml={1} flex={1} direction="row" justifyContent={'space-between'}>
                <Stack direction={'row'}>
                    <Typography variant="body1" fontWeight={500} component="span" color={color.priText}>
                        {t('permits:text.permit')} {index}
                    </Typography>
                    {isValid ? (
                        <Typography
                            variant="body1"
                            fontWeight={400}
                            component="span"
                            color={color.success}
                            sx={{ ml: 1 }}
                        >
                            ({t('permits:text.Completed')})
                        </Typography>
                    ) : (
                        <Typography
                            variant="body1"
                            fontWeight={400}
                            component="span"
                            color={color.danger}
                            sx={{ ml: 1 }}
                        >
                            ({t('permits:text.notCompleted')})
                        </Typography>
                    )}
                </Stack>

                <Stack direction="row" gap={1}>
                    <Tooltip
                        title={
                            <Typography variant={'body2'} color={color.white}>
                                {t('permits:text.duplicate')}
                            </Typography>
                        }
                        placement="top"
                    >
                        <IconButton
                            aria-label="copy"
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // if (isValid) {
                                onDuplicatePermit();
                                // }
                            }}
                            sx={{ padding: 0 }}
                        >
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={
                            <Typography variant={'body2'} color={color.white}>
                                {t('permits:text.remove')}
                            </Typography>
                        }
                        placement="top"
                    >
                        <IconButton
                            aria-label="delete"
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRemovePermit(props.permit.id);
                            }}
                            sx={{ padding: 0 }}
                        >
                            <RemoveIcon style={{ stroke: color.danger }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </AccordionSummary>
    );
}
