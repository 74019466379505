import { AzureMediaController } from './azureMediaController';
import { AccountController } from './AccountController';
import { AnprController } from './AnprController';
import { axiosCreator } from './axiosConfig';
import { appConfig } from '../config/AppConfig';
import { CompaniesController } from './CompaniesController';
import { PermitController } from './permitsController/permitsController';
import { LocationController } from './LocationController';
import { ShopController } from './ShopController';
import { EmployeeController } from './EmployeeController';
import { UserController } from './UserController';
import { ZoneController } from './ZoneController';
import { StatisticPCNController } from './StatisticPCNController';
import { RegionController } from './RegionController';
import { AnprDashboardDownload } from './AnprDashboardDownload';
import { PCNDashboardDownloadController } from './PCNDashboardDownloadController';

const apiUrl = appConfig.gateway.clientPortalAPI;
const azureMediaUrl = appConfig.gateway.apiAzureMedia;

export const axiosClient = axiosCreator({
    // url: apiUrl.authen,
    url: apiUrl,
    onError: (err: any) => {
        // if (err.status == 401) {
        //     window.location.href = '/login';
        // }
        // if (err.status == 404) {
        //     window.location.href = '/';
        // }
        // if (err.status == 403) {
        //     window.location.href = '/';
        // }
    },
});

export const accountController = new AccountController(apiUrl, axiosClient);
export const anprController = new AnprController(apiUrl, axiosClient);
export const companiesController = new CompaniesController(apiUrl, axiosClient);
export const locationController = new LocationController(apiUrl, axiosClient);
export const regionController = new RegionController(apiUrl, axiosClient);
export const shopController = new ShopController(apiUrl, axiosClient);
export const employeeController = new EmployeeController(apiUrl, axiosClient);
export const userController = new UserController(apiUrl, axiosClient);
export const zoneController = new ZoneController(apiUrl, axiosClient);
export const azureMediaController = new AzureMediaController(azureMediaUrl, axiosClient);
export const permitController = new PermitController(apiUrl, axiosClient);
export const statisticPCNController = new StatisticPCNController(apiUrl, axiosClient);
export const anprDashboardDownload = new AnprDashboardDownload(apiUrl, axiosClient);
export const pcnDashboardDownloadController = new PCNDashboardDownloadController(apiUrl, axiosClient);