import React, { useContext } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import { DrawerContext } from '../useDrawer';
import color from '../../../config/Colors';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Avatar, Grid, Stack } from '@mui/material';
import LanguageSelect from '../../../components/LanguageSelect';
import ItemDrawer from './DrawerItem';
import LogoutIcon from '../../../assets/images/LogoutIcon';
import { LanguageContext } from '../../../contexts/useLanguage';

const drawerWidth = 200;

export default function DrawerLarge() {
    let navigate = useNavigate();
    const { t } = useContext(LanguageContext);
    const { ListDrawItem, open, handleDrawerClose, logout, activeItem, setActiveItem, isShowDrawer, handleDrawerOpen } =
        useContext(DrawerContext);

    return isShowDrawer == true ? (
        <Box
            sx={{
                display: 'flex',
                pt: 10,
            }}
        >
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    pt: 10,
                    '& .MuiPaper-root': {
                        // border: 'none',
                        borderRight: open ? `1px solid ${color.grey100}` : '',
                        background: color.grey100,
                        pt: 12,
                        px: 1,
                        overflow: 'visible',
                    },
                }}
            >
                <DrawerHeader sx={{ padding: 0, justifyContent: 'center' }} className="drawer-header">
                    <Stack direction={'row'}>
                        <Stack sx={{ display: open ? 'block' : 'none', transition: 'all 1s' }}>
                            <LanguageSelect isShowLabel={true} />
                        </Stack>

                        <Avatar
                            sx={[
                                {
                                    background: color.grey100,
                                    color: color.grey600,
                                },
                                open
                                    ? {
                                          display: 'flex',
                                          width: '30px',
                                          height: '30px',
                                          position: 'absolute',
                                          boxShadow: '0 0 3px 2px rgba(0, 0, 0, 0.2) !important',
                                          zIndex: 9,
                                          right: '-15px',
                                      }
                                    : {},
                            ]}
                        >
                            {open ? (
                                <FiArrowLeft style={{ opacity: 1, cursor: 'pointer' }} onClick={handleDrawerClose} />
                            ) : (
                                <Box onClick={handleDrawerOpen}>
                                    <MenuIcon style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                                </Box>
                            )}
                        </Avatar>
                    </Stack>
                </DrawerHeader>

                <List
                    sx={{
                        height: '100%',
                    }}
                >
                    <Grid container height={'100%'} justifyContent={'space-between'}>
                        <Grid item xs={12}>
                            {ListDrawItem.map((item, index) => {
                                const isActive = item.id === activeItem;

                                return (
                                    <ItemDrawer
                                        key={index}
                                        icon={item.icon}
                                        isActive={isActive}
                                        label={item.label}
                                        open={open}
                                        onClick={() => {
                                            setActiveItem(item.id);
                                            navigate(item.path);
                                        }}
                                    />
                                );
                            })}
                        </Grid>
                        <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                            <Box></Box>
                            <ItemDrawer
                                key={99}
                                icon={<LogoutIcon className="svg-item-Menu" />}
                                isActive={false}
                                label={t('drawer:logout')}
                                open={open}
                                onClick={() => logout()}
                            />
                        </Grid>
                    </Grid>
                </List>
            </Drawer>
        </Box>
    ) : (
        <Box width={0}></Box>
    );
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
