import { Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { appConfig } from '../../../config/AppConfig';
import color from '../../../config/Colors';
import { LanguageContext } from '../../../contexts/useLanguage';
import { ReportType } from '../../../models/ReportType';
import Footer from '../../dashboardLayout/components/Footer';
import { DrawerContext } from '../../dashboardLayout/useDrawer';
import { DEFAULT_TAB_INDEX } from '../hooks/useReport';
import BarChartIcon from './components/BarChartIcon';
import EVChartIcon from './components/EVChartIcon';
import LineChartIcon from './components/LineChartIcon';
import PieChartIcon from './components/PieChartIcon';
import ReportDashboardItem from './ReportDashboardItem';

export interface ReportItemDashboard {
    id: number;
    title: string;
    desc: string;
    url: string;
    icon: JSX.Element;
}

export default function ReportDashboard() {
    const { t } = useContext(LanguageContext);
    const { setActiveItem } = useContext(DrawerContext);
    useEffect(() => {
        setActiveItem(3);
    }, []);

    const visitAnalysis: ReportItemDashboard[] = [
        {
            id: 0,
            title: t('dashboard:title.dailyVisit'),
            desc: t('dashboard:text.numberOfVisitsPerDay'),
            url: `${ReportType.dailyVisit}/${DEFAULT_TAB_INDEX}`,
            icon: <BarChartIcon />,
        },
        {
            id: 1,
            title: t('dashboard:title.capacityAnalysis'),
            desc: t('dashboard:text.averageCapacityAtYourLocationOrZone'),
            url: `${ReportType.capacityAnalysis}/${DEFAULT_TAB_INDEX}`,
            icon: <BarChartIcon />,
        },
        {
            id: 2,
            title: t('dashboard:title.averageLengthOfVisits'),
            desc: t('dashboard:text.dwellTimesOfTheNumberOfVisits'),
            url: `${ReportType.averageLength}/${DEFAULT_TAB_INDEX}`,
            icon: <PieChartIcon />,
        },
        {
            id: 3,
            title: t('dashboard:title.repeatVisits'),
            desc: t('dashboard:text.numberOfReturnsByTheSameVehicle'),
            url: `${ReportType.repeatVisits}/${DEFAULT_TAB_INDEX}`,
            icon: <PieChartIcon />,
        },
    ];

    const pcnAnalysis: ReportItemDashboard[] = [
        {
            id: 0,
            title: t('dashboard:title.groupByPCNTypes'),
            desc: t('dashboard:text.numberOfParkingChargesRaisedBySiteBasedOnContraventionDate​'),
            url: `${ReportType.groupByPCNTypes}/${DEFAULT_TAB_INDEX}`,
            icon: <BarChartIcon />,
        },
        {
            id: 1,
            title: t('dashboard:title.groupByPCNCancellations'),
            desc: t('dashboard:text.numberOfParkingChargesCancelledByEitherTheClient​'),
            url: `${ReportType.groupByPCNCancellations}/${DEFAULT_TAB_INDEX}`,
            icon: <PieChartIcon />,
        },
        {
            id: 2,
            title: t('dashboard:title.contravention'),
            desc: t('dashboard:text.numberOfParkingChargesRaisedByContravention​'),
            url: `${ReportType.PCNByReason}/${DEFAULT_TAB_INDEX}`,
            icon: <PieChartIcon />,
        },
        {
            id: 3,
            title: t('dashboard:title.PCNByZones'),
            desc: t('dashboard:text.numberOfParkingChargesRaisedBySiteBasedOnContraventionDateAndZones​'),
            url: `${ReportType.PCNByZones}/${DEFAULT_TAB_INDEX}`,
            icon: <BarChartIcon />,
        },
    ];

    const EVAnalysis: ReportItemDashboard[] = [
        {
            id: 0,
            title: t('dashboard:title.dailyVisit'),
            desc: t('dashboard:text.numberOfVisitsPerDay'),
            url: `${ReportType.evDailyVisit}/${DEFAULT_TAB_INDEX}`,
            icon: <EVChartIcon />,
        },
        {
            id: 1,
            title: t('dashboard:title.averageLengthOfEVVisits'),
            desc: t('dashboard:text.averageLengthOfEVVisitsDesc'),
            url: `${ReportType.evAverageLength}/${DEFAULT_TAB_INDEX}`,
            icon: <EVChartIcon />,
        },
    ];

    return (
        <>
            <Container sx={{ pt: { xs: 9, sm: 10, md: 12 }, minHeight: 'calc(100vh - 102px)' }}>
                <Stack flexDirection={{ sm: 'column', md: 'row' }}>
                    <Grid item mr={'auto'}>
                        <Typography textAlign={'left'} variant="h3" fontWeight={400}>
                            {t('drawer:insights')}
                        </Typography>
                    </Grid>
                </Stack>

                <Grid container mt={3}>
                    <Typography width={'100%'} variant="h4" mb={2} color={color.priText} fontWeight={400}>
                        {t('dashboard:title.visitsAnalysis')}
                    </Typography>

                    <Grid item container spacing={3}>
                        {visitAnalysis.map((item, index) => {
                            return <ReportDashboardItem reportItemDashboard={item} key={index} />;
                        })}
                    </Grid>
                </Grid>

                <Grid container mt={3}>
                    <Typography width={'100%'} variant="h4" mb={2} color={color.priText} fontWeight={400}>
                        {t('dashboard:title.EVStats')}
                    </Typography>

                    <Grid item container spacing={3}>
                        {EVAnalysis.map((item, index) => {
                            return <ReportDashboardItem reportItemDashboard={item} key={index} />;
                        })}
                    </Grid>
                </Grid>

                {appConfig.flagFeatures.isEnableReportPCNs == true ? (
                    <Grid container mt={3}>
                        <Typography width={'100%'} variant="h4" mb={2} fontWeight={400} color={color.priText}>
                            {t('dashboard:title.parkingChargeAnalysis​')}
                        </Typography>

                        <Grid item container spacing={3}>
                            {pcnAnalysis.map((item, index) => {
                                return <ReportDashboardItem reportItemDashboard={item} key={index} />;
                            })}
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Container>
            <Footer />
        </>
    );
}
